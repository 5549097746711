import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../styles/owl.carousel.css";

function ProcessInfosModal(props) {
  // Extract merchant from props
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>{" "}
        <Modal.Body>
          <h3 className="mb-2">Plugins to Collecting Reviews</h3>

          <div class="d-lg-flex justify-content-around">
            <div class="cms-link mx-auto mb-3 mx-lg-3">
              <div class="card">
                <img
                  src={
                    "https://dev.veritatrust.com/assets/img/cms/wordpress.png"
                  }
                  class="card-img-top p-3 img-fluid"
                  alt="Wordpress"
                />
                <div class="card-body">
                  <h5 class="card-title">Wordpress</h5>
                  <p class="card-text mb-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a
                    href="https://b2b.veritatrust.com/api/download-woo-veritatrust-plugin"
                    class="btn btn-primary"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>

            <div class="cms-link mx-auto mb-3 mx-lg-3">
              <div class="card">
                <img
                  src={
                    "https://dev.veritatrust.com/assets/img/cms/prestashop.png"
                  }
                  class="card-img-top p-3 img-fluid"
                  alt="Prestashop"
                  width={"10px"}
                />
                <div class="card-body">
                  <h5 class="card-title">Prestashop</h5>
                  <p class="card-text mb-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a
                    href="https://b2b.veritatrust.com/api/download-presta-veritatrust-plugin"
                    class="btn btn-primary"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="cms-link mx-auto mb-3 mx-lg-3">
              <div class="card">
                <img
                  src={"https://dev.veritatrust.com/assets/img/cms/shopify.png"}
                  class="card-img-top p-3 img-fluid"
                  alt="Shopify"
                />
                <div class="card-body">
                  <h5 class="card-title">Shopify</h5>
                  <p class="card-text mb-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  {/* <a href="#" class="btn btn-primary">
                          Download
                        </a> */}
                  <button type="button" className="btn btn-warning" disabled>
                    shortly
                  </button>
                </div>
              </div>
            </div>
            <div class="cms-link mx-auto mb-3 mx-lg-3">
              <div class="card">
                <img
                  src={
                    "https://dev.veritatrust.com/assets/img/cms/adobecommerce.png"
                  }
                  class="card-img-top p-3 img-fluid"
                  alt="Adobe commerce"
                />
                <div class="card-body">
                  <h5 class="card-title">Adobe Commerce</h5>
                  <p class="card-text mb-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  {/* <a href="#" class="btn btn-primary text-end">
                          Download
                        </a> */}
                  <button type="button" className="btn btn-warning" disabled>
                    shortly
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProcessInfosModal;
