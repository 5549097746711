import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FaqUpgrade from "../../components/FaqUpgrade";
import NavBar from "../../components/NavBar";
import PackItems from "../../components/PackItems";
import PackItems2 from "../../components/PackItems2";
import SideBar from "../../components/SideBar";
import { ACCESS_TOKEN, MERCHAND_ID } from "../../config";
import { useAxios } from "../../utils/hook/useAxios";

function UpgradePage() {
  const axios = useAxios();
  const location = useLocation();
  const [isActive, setActive] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(null);
  function handleClick() {
    setActive(!isActive);
  }

  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token ? window.localStorage.token : ACCESS_TOKEN
  );
  const [SubscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    axios
      .get("/subscriptions/" + MerchantID, AccessToken)

      .then((response) => {
        setSubscriptionData(response.data);
        const expiryDate = new Date(response.data.expirationDate);
        const currentDate = new Date();

        const timeDifference = expiryDate - currentDate;
        const daysDifference = Math.ceil(
          timeDifference / (1000 * 60 * 60 * 24)
        );

        setDaysRemaining(daysDifference);
      })

      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Upgrade page"}
            handleClick={handleClick}
          ></NavBar>
          <div className="recent-reviews">
            <h2>
              Passez au pack supérieur pour accéder à plus de fonctionnalités
            </h2>
            <div className="callout callout-info">
              Pensez à annuler le pack actuel avant de passser à un autre pack!
            </div>

            {daysRemaining !== null && daysRemaining < 30 && (
              <div className="callout callout-error">
                Votre abonnement arrive à terme dans {daysRemaining} jours.
                Pensez à le renouveler!
              </div>
            )}
            <div className="reviews">
              <div className="container mb-3">
                <div className="row align-items-center">
                  <div className="col-lg order-lg-2 d-flex align-items-center my-3">
                    <img
                      className="w-100 img-fluid object-fit-none rounded-4"
                      src="https://businessapp.b2b.trustpilot.com/upgrade/0069a11a32c4121b514e.png"
                      alt="Veritatrust"
                    />
                  </div>
                  <div className="col-lg order-lg-1">
                    <p className="h1 mb-3">
                      Choisissez le pack idéal pour booster votre entreprise
                    </p>
                    <p className="mb-3">
                      <strong>
                        89 % des consommateurs lisent des avis en ligne avant de
                        passer à l'achat.*
                      </strong>
                    </p>
                    <p className="mb-3">
                      Avec un pack Veritatrust adapté à votre entreprise, vous
                      pouvez instaurer la confiance, attirer et convertir plus
                      de clients et augmenter votre chiffre d'affaires grâce à
                      vos avis. Il vous suffit de sélectionner le pack qui vous
                      convient, définir votre mode de paiement et vous aurez
                      accès à tous les outils pour réussir.
                    </p>
                    <p className="mb-3">
                      Pas d'appels, pas d'attente, pas de stress.
                    </p>
                  </div>
                </div>
              </div>
              <PackItems
                ActualPack={SubscriptionData?.subscriptionPlan || "0"}
                SubscriptionId={SubscriptionData?.StripeSubscriptionId}
              ></PackItems>
            </div>
          </div>
          <div className="recent-reviews d-none d-md-block">
            <div className="reviews">
              <div class="container mb-3">
                <div class="row align-items-center">
                  <div class="col-lg order-lg-2 d-flex align-items-center my-3">
                    <img
                      class="w-100 img-fluid object-fit-none rounded-4"
                      src="https://businessapp.b2b.trustpilot.com/upgrade/b2a09a2aaadfaba08518.png"
                      alt="Veritatrust"
                    />
                  </div>
                  <div class="col-lg order-lg-1">
                    <p class="h1 mb-3">
                      Voici tout ce que vous obtenez en souscrivant à un pack
                      supérieur
                    </p>
                    <p class="mb-3">
                      Choisissez le pack qui convient à votre entreprise. Une
                      fois que vous avez fait votre choix, vous pouvez mettre à
                      jour vous-même votre abonnement et commencer à profiter de
                      vos nouvelles fonctionnalités immédiatement.
                    </p>
                  </div>
                </div>
              </div>
              <PackItems2
                ActualPack={SubscriptionData?.subscriptionPlan || "0"}
              ></PackItems2>
            </div>
          </div>
          <FaqUpgrade></FaqUpgrade>
        </main>
      </div>
    </>
  );
}

export default UpgradePage;
