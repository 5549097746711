import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/dashboard.css";
import logoBusinessVeritatrust from "../../assets/logo-veritatrust-business.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function StepperItem({ propsMessage, propsClass, propsStepValue }) {
  return (
    <>
      <div
        className={
          "col-11 col-lg-8 py-3 mx-auto position-relative mb-3 " + propsClass
        }
      >
        <ul className="step-reviews nav text-center ">
          <li className={"nav-item w-25 step-1 " + propsStepValue[0]}>
            <span className="badge rounded-circle">1</span>
            <p className="mb-0" aria-current="page">
              Verified Account
            </p>
          </li>
          <li className={"nav-item w-25 step-2 " + propsStepValue[1]}>
            <span className="badge rounded-circle">2</span>
            <p className="mb-0">{propsMessage}</p>
          </li>
          <li className={"nav-item w-25 step-3 " + propsStepValue[2]}>
            <span className="badge rounded-circle">3</span>
            <p className="mb-0">Verify with domaine name</p>
          </li>
          <li className={"nav-item w-25 step-4 " + propsStepValue[3]}>
            <span className="badge rounded-circle">4</span>
            <p className="mb-0">Account in pending to approbate</p>
          </li>
        </ul>
        <div id="line">
          <div id="line-progress"></div>
        </div>
      </div>
    </>
  );
}

export default StepperItem;
