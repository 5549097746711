import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import {
  MERCHAND_ID,
  MERCHAND_WEBSITE,
  TVA_fr,
  URL_LINK_COMPANY_DETAILS,
} from "../../config";
function ChekoutSelectSubscription() {
  const [isActive, setActive] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  function handleClick() {
    setActive(!isActive);
  }

  const [Monthly_price, setMonthly_price] = useState(0);
  const [Annual_price, setAnnual_price] = useState(0);
  const [Monthly_price_Advanced, setMonthly_price_Advanced] = useState(0);
  const [AnnualPrice_Advanced, setAnnualPrice_Advanced] = useState(0);
  const [AmountToPay, setAmountToPay] = useState(0);
  const packUpgrade = window.localStorage.getItem("PackUpgrade");

  const [ProfileDataID, setProfileDataID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [selectedOption, setSelectedOption] = useState("annual"); // Suppose 'annual' est sélectionné par défaut
  const [MerchantWebsite, setMerchantWebsite] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).website
      : MERCHAND_WEBSITE
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    console.log("Option:", event.target.value);
    console.log("Annual:", AnnualPrice_Advanced);
    console.log("Monthly:", Annual_price);

    let amount =
      event.target.value === "annual" ? AnnualPrice_Advanced : Monthly_price;

    setAmountToPay(amount);
    console.log("amount:", amount);
  };

  useEffect(() => {
    setMonthly_price(searchParams.get("month_price"));

    let value = parseFloat(searchParams.get("month_price")) * 12;
    setAnnual_price(value.toFixed(2));

    setMonthly_price_Advanced(value.toFixed(2));

    setAnnualPrice_Advanced(value.toFixed(2));

    setAmountToPay(value.toFixed(2));

    let pack = searchParams.get("pack");
    window.localStorage.setItem("PackUpgrade", pack);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Upgrade page"}
            handleClick={handleClick}
          ></NavBar>
          <div class="recent-reviews">
            <h2>Votre abonnement</h2>
            <div class="reviews">
              <div class="recent-reviews">
                <div class="col-11 col-lg-8 pb-3 mx-auto position-relative mb-3 collecting">
                  <ul class="step-reviews nav text-center ">
                    <li class="nav-item w-25 step-1 active">
                      <span class="badge rounded-circle">1</span>
                      <p class="mb-0" aria-current="page">
                        Select subscription
                      </p>
                    </li>
                    <li class="nav-item w-25 step-2 ">
                      <span class="badge rounded-circle">2</span>
                      <p class="mb-0" aria-current="page">
                        Add your contact details
                      </p>
                    </li>
                    <li class="nav-item w-25 step-3">
                      <span class="badge rounded-circle">3</span>
                      <p class="mb-0">Confirm your subscription</p>
                    </li>
                    <li class="nav-item w-25 step-4 ">
                      <span class="badge rounded-circle">4</span>
                      <p class="mb-0">Choose a payment method</p>
                    </li>
                  </ul>
                  <div id="line">
                    <div id="line-progress" style={{ width: "4%" }}></div>
                  </div>
                </div>
              </div>

              <div class="container mb-3 subscription-selector">
                <div class="row g-4">
                  <div class="col-lg-8">
                    <h5>Select subscription</h5>
                    <p class="">
                      How would you like to pay for your 12 month subscription?
                    </p>
                    <div class="row g-3 mt-3">
                      <div class="col-lg">
                        <input
                          type="radio"
                          class="btn-check"
                          name="options-base"
                          id="month"
                          value="monthly"
                          autocomplete="off"
                          checked={selectedOption === "monthly"}
                          onChange={handleOptionChange}
                        />
                        <label class="btn zone" for="month">
                          <h4 class="mb-3">Monthly payment</h4>
                          <p class="lead">
                            {Monthly_price.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            € / month (excl. tax)
                          </p>
                          <p>
                            Annual cost:{" "}
                            {Annual_price.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            €
                          </p>
                        </label>
                      </div>
                      <div class="col-lg">
                        <input
                          type="radio"
                          class="btn-check zone"
                          name="options-base"
                          id="annual"
                          value="annual"
                          autocomplete="off"
                          checked={selectedOption === "annual"}
                          onChange={handleOptionChange}
                        />
                        <label class="btn zone" for="annual">
                          <h4 class="mb-3">Advance payment</h4>
                          <p class="lead">
                            {Monthly_price_Advanced} € / year (excl. tax)
                          </p>
                          <p>Annual cost: {Monthly_price_Advanced} €</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card rounded-5">
                      <div class="card-body">
                        <div class="mb-3">
                          <h4 class=" mb-0">Subscription</h4>
                          <p class="">{MerchantWebsite}</p>
                        </div>
                        <div class="mb-3">
                          <p class="mb-0">
                            <strong>Plan</strong>
                          </p>
                          <p class="text-grey">{packUpgrade}</p>
                        </div>
                        <div class="mb-3">
                          <p class="mb-0">
                            <strong>Engagement</strong>
                          </p>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="text-grey me-3">
                              12 months | Pay{" "}
                              {selectedOption === "annual"
                                ? "upfront"
                                : "monthly"}
                            </p>
                            <p class="text-grey">{AmountToPay} €</p>
                          </div>
                        </div>
                        <hr />
                        <div class="mb-3">
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="text-grey me-3">
                              Total for the subscription period
                            </p>
                            <p class="text-grey">
                              {(AmountToPay * (1 + TVA_fr)).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="text-grey me-3">TVA (20%)</p>
                            <p class="text-grey">
                              {" "}
                              {(AmountToPay * TVA_fr).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-grey me-3">Due today</h4>
                            <h4 class="text-grey">
                              {(AmountToPay * (1 + TVA_fr)).toFixed(2)} €
                            </h4>
                          </div>
                        </div>
                        <div class="d-grid gap-2">
                          <Link
                            to={
                              URL_LINK_COMPANY_DETAILS +
                              "?month_price=" +
                              Monthly_price +
                              "&TotalAmount=" +
                              parseFloat(AmountToPay).toFixed(2) +
                              "&payment=" +
                              selectedOption
                            }
                            class="btn btn-success rounded-5 text-white p-3"
                          >
                            Next step
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default ChekoutSelectSubscription;
