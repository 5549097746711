import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import LogoVt from "../../assets/logo.png";
import "../../styles/owl.carousel.css";

function WidgetResumeComponentBlock(props) {
  const { merchantData } = props;

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);
  // Extract merchant from props
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("b2b.ShowcasePage.widget")} 1
          </Modal.Title>
        </Modal.Header>{" "}
        <Modal.Body>
          <section>
            <div class="global_link d-md-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center justify-content-center">
                <img
                  style={{ margin: "-7px 0 0 3px" }}
                  src={LogoVt}
                  alt="Veritatrust"
                  height="29"
                  width="150"
                />{" "}
                <p class="reviews_number m-0 me-2">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </p>
              </div>
              <a
                href="#"
                class="d-flex justify-content-center align-items-center"
                target="_blank"
              >
                <p class="reviews_number m-0 ms-2">
                  {t("b2b.ShowcasePage.read_our")}{" "}
                  <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                  {t("b2b.ShowcasePage.reviews")} <strong>›</strong>
                </p>
              </a>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("b2b.ShowcasePage.close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WidgetResumeComponentBlock;
