import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/hook/useAxios";

function CreateSelectSubCategoryInput({
  handleSelectSubCategory,
  startReviewButtonDisabled,
  category_parent_idSelected,
  resetInput,
}) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [categoryIdSelected, SetCategoryIdSelected] = useState();

  useEffect(() => {
    if (resetInput) {
      setSearchTerm("");
      // Réinitialise l'état de l'input en cas de resetInput
    }
  }, [resetInput]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);

    // Send a request to the server to search for categories matching the input value
    if (inputValue.length >= 1) {
      fetch(
        `https://api.veritatrust.com/v1/search-categories-item/${category_parent_idSelected}?q=${inputValue}&lang=en`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setSearchResults(data);
          setShow(true);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    } else {
      setSearchResults([]);
      setShow(false);
    }
  };

  const handleSelectCategoryInput = (suggestion) => {
    setSearchResults([]);
    setSearchTerm(suggestion[`category_name`] || suggestion.category_name);
    handleSelectSubCategory(suggestion);
    SetCategoryIdSelected(suggestion.google_category_id);
    setShow(false);
  };

  const axios = useAxios();

  return (
    <>
      <div className="mb-md-3">
        <label htmlFor="categoryProduct" className="col-form-label">
          {t("b2b.SettingPage.AddReviewForBetaTesterSubCat")}
          <sup>*</sup>
        </label>
        <div className="dropdown">
          <input
            type="text"
            className="form-control form-control-lg"
            id="categoryProduct"
            required
            name="categoryProduct"
            onChange={handleInputChange}
            placeholder={t(
              "b2b.SettingPage.AddReviewForBetaTesterPlaceHolderSubcat"
            )}
            value={searchTerm}
            autoComplete="off"
            disabled={startReviewButtonDisabled}
            onBlur={() => {
              setTimeout(() => setShow(false), 200);
            }}
            onFocus={() => {
              setShow(true);
            }}
          />
          {searchTerm.length >= 2 && (
            <ul className={show ? "dropdown-menu show" : "dropdown-menu"}>
              {searchResults.map((suggestion, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => handleSelectCategoryInput(suggestion)}
                  >
                    {suggestion[`category_name`] || suggestion.category_name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateSelectSubCategoryInput;
