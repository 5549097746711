import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

function MerchandStatus({ data }) {
  return (
    <>
      <div className="col-11 col-lg-8 py-3 mx-auto position-relative mb-3 collecting">
        <ul className="step-reviews update nav text-center ">
          <li
            className={
              data.status == "1"
                ? "nav-item w-33 step-1 active"
                : "nav-item w-33 step-1"
            }
          >
            <span className="badge rounded-circle">
              <i class="flaticon-check"></i>
            </span>
            <p className="mb-0" aria-current="page">
              Verified Company
            </p>
          </li>
          <li
            className={
              data.CollectingReviews == "1"
                ? "nav-item w-33 step-2 active"
                : "nav-item w-33 step-2"
            }
          >
            <span className="badge rounded-circle">
              <i class="flaticon-check"></i>
            </span>
            <p className="mb-0">Collecting reviews</p>
          </li>
          <li
            className={
              data.DisplayingReviews == "1"
                ? "nav-item w-33 step-3 active"
                : "nav-item w-33 step-3"
            }
          >
            <span className="badge rounded-circle">
              <i class="flaticon-check"></i>
            </span>
            <p className="mb-0">Displaying reviews</p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default MerchandStatus;
