import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ACCESS_TOKEN,
  MERCHAND_ID,
  MONTHLY_PRICE_PREMIUM,
  MONTHLY_PRICE_STARTER,
  UPGRADE_URL_CLIENT,
  URL_LINK_SELECT_PACK,
} from "../../config";

import { useAxios } from "../../utils/hook/useAxios";

function PackItems({ ActualPack, SubscriptionId }) {
  const axios = useAxios();

  const { lang } = useParams();

  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token
      ? window.localStorage.token || getCookie("jwtToken")
      : ACCESS_TOKEN
  );

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  function handleCancelSubscription() {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir effectuer cette action ?"
    );
    if (!isConfirmed) {
      // Mettre ici le code à exécuter si l'utilisateur confirme
      console.log("Subscription canceled");
    } else {
      // Mettre ici le code à exécuter si l'utilisateur annule
      axios
        .post("/cancel-subscription", {
          subscriptionId: SubscriptionId,
          PaymentIntentId: SubscriptionId,
        })

        .then((response) => {
          if (response.data.success === true) {
            axios
              .post(
                "/subscriptions",
                {
                  MonthlyReview: 200,
                  EmailInviteCustom: false,
                  CustomerSupport: "0",
                  InviteFromPastCustomers: "0",
                  dashboard: "0",
                  QandAonProductPage: false,
                  LocalReviews: false,
                  MultiUserDomain: "0",
                  NetPromoterScore: false,
                  GoogleShoppingSEOReviewWidget: false,
                  expirationDate: new Date("2099-01-01"),
                  status: "1",
                  merchandId: MerchantID,
                  subscriptionPlan: "0",
                  apiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ989JXDGGHGJHU",
                  StripeSubscriptionId: null,
                },
                AccessToken
              )
              .then((response) => {
                window.location.href = UPGRADE_URL_CLIENT;
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <>
      <div className="container mb-3 price-selector">
        <div className="row g-4">
          <div className="col-lg">
            <div className="card bg-primary rounded-4">
              <div className="card-header text-center">
                <p className="lead fw-bold">Enterprise</p>
                <p className="lead fw-bold me-2">Custom/Contact us</p>
                <p className="">Yearly payment / hors taxes</p>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Bénéficiez de tous les avantages du pack 2 et de bien d'autres
                  outils pour optimiser vos résultats.
                  <ul>
                    <li>
                      <strong>
                        Optimisez vos conversions avec plus d'avis que jamais
                      </strong>
                      <br />
                      22 widgets pour afficher vos meilleurs avis à chaque point
                      de contact du parcours client.
                    </li>
                    <li>
                      <strong>Entrez en contact avec chaque client</strong>
                      <br />
                      Envoyez des invitations à laisser un avis illimitées et
                      personnalisées à tous vos clients.
                    </li>
                    <li>
                      <strong>
                        Créez une entreprise centrée sur le client
                      </strong>
                      <br />
                      Affinez votre stratégie commerciale grâce à des données et
                      informations détaillées tirées de vos avis.{" "}
                    </li>
                  </ul>
                  <a href="#">En savoir plus sur le pack Scale</a>
                </p>
                {ActualPack === "3" ? (
                  <>
                    {/* <button
                      className="btn btn-dark rounded-5 w-100 mt-3 p-3"
                      disabled={true}
                    >
                      {ActualPack === "3" ? "Your pack" : "Choose enterprise"}
                    </button> */}
                    <button
                      className="btn btn-danger rounded-5 w-100 mt-3 p-3"
                      onClick={handleCancelSubscription}
                    >
                      Cancel subscription
                    </button>
                  </>
                ) : (
                  <Link
                    to={URL_LINK_SELECT_PACK}
                    className="btn btn-dark rounded-5 w-100 mt-3 p-3"
                    aria-disabled={true}
                  >
                    {ActualPack === "3" ? "Your pack" : "Choose enterprise"}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="card bg-success rounded-4">
              <div className="card-header text-center">
                <p className="lead fw-bold">Premium</p>
                <p className="lead fw-bold me-2">
                  From 199€<span>/month</span>
                </p>
                <p className="">Yearly payment / hors taxes</p>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Bénéficiez de tous les avantages du pack 2 et de bien d'autres
                  outils pour optimiser vos résultats.
                  <ul>
                    <li>
                      <strong>
                        Optimisez vos conversions avec plus d'avis que jamais
                      </strong>
                      <br />
                      22 widgets pour afficher vos meilleurs avis à chaque point
                      de contact du parcours client.
                    </li>
                    <li>
                      <strong>Entrez en contact avec chaque client</strong>
                      <br />
                      Envoyez des invitations à laisser un avis illimitées et
                      personnalisées à tous vos clients.
                    </li>
                    <li>
                      <strong>
                        Créez une entreprise centrée sur le client
                      </strong>
                      <br />
                      Affinez votre stratégie commerciale grâce à des données et
                      informations détaillées tirées de vos avis.{" "}
                    </li>
                  </ul>
                  <a href="#">En savoir plus sur le pack Scale</a>
                </p>
                {ActualPack === "2" ? (
                  <>
                    {/* <button
                      className="btn btn-dark rounded-5 w-100 mt-3 p-3"
                      disabled={true}
                    >
                      {ActualPack === "2" ? "Your pack" : "Choose premium"}
                    </button> */}
                    <button
                      className="btn btn-danger rounded-5 w-100 mt-3 p-3"
                      onClick={handleCancelSubscription}
                    >
                      Cancel subscription
                    </button>
                  </>
                ) : (
                  <Link
                    to={
                      URL_LINK_SELECT_PACK +
                      "?month_price=" +
                      MONTHLY_PRICE_PREMIUM +
                      "&pack=PREMIUM"
                    }
                    className="btn btn-dark rounded-5 w-100 mt-3 p-3"
                  >
                    {ActualPack === "2" ? "Your pack" : "Choose premium"}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="card bg-secondary rounded-4">
              <div className="card-header text-center">
                <p className="lead fw-bold">Starter</p>
                <p className="lead fw-bold me-2">
                  From 99€<span>/month</span>
                </p>
                <p className="">Yearly payment / hors taxes</p>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Bénéficiez de tous les avantages du pack 3 et de bien d'autres
                  outils pour optimiser vos résultats.
                  <ul>
                    <li>
                      <strong>
                        Optimisez vos conversions avec plus d'avis que jamais
                      </strong>
                      <br />
                      22 widgets pour afficher vos meilleurs avis à chaque point
                      de contact du parcours client.
                    </li>
                    <li>
                      <strong>Entrez en contact avec chaque client</strong>
                      <br />
                      Envoyez des invitations à laisser un avis illimitées et
                      personnalisées à tous vos clients.
                    </li>
                    <li>
                      <strong>
                        Créez une entreprise centrée sur le client
                      </strong>
                      <br />
                      Affinez votre stratégie commerciale grâce à des données et
                      informations détaillées tirées de vos avis.{" "}
                    </li>
                  </ul>
                  <a href="#">En savoir plus sur le pack Scale</a>
                </p>
                {ActualPack === "1" ? (
                  <button
                    className="btn btn-danger rounded-5 w-100 mt-3 p-3"
                    onClick={handleCancelSubscription}
                  >
                    Cancel subscription
                  </button>
                ) : (
                  <Link
                    to={
                      URL_LINK_SELECT_PACK +
                      "?month_price=" +
                      MONTHLY_PRICE_STARTER +
                      "&pack=starter"
                    }
                    className="btn btn-dark rounded-5 w-100 mt-3 p-3"
                  >
                    {ActualPack === "1" ? "Your pack" : "Choose starter"}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackItems;
