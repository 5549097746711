import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/flaticon_veritatrust.css";
import "../../styles/dashboard.css";

import "../../styles/owl.carousel.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import moment from "moment";

function ReviewItem({ data, propsIdItem }) {
  return (
    <>
      <tr
        className="row"
        data-bs-toggle="collapse"
        href={"#" + propsIdItem}
        role="button"
        aria-expanded="false"
        aria-controls={propsIdItem}
      >
        <td data-label="Date:" className="col-md-1">
          {moment(data.createdAt).format("MMM Do YY")}
        </td>
        <td data-label="Rate:" className="col-md-1">
          {data.rating}
        </td>
        <td data-label="Title:" className="col-md-9">
          {data.title}
        </td>
        <td data-label="Details:" className="col-md-1 text-primary">
          <a className="neutral" type="button">
            <span className="material-symbols-sharp">visibility</span>
          </a>
        </td>
      </tr>
      <tr className="collapse border-bottom" id={propsIdItem}>
        <td colSpan={4} className="">
          <div className="border border-success rounded-2 p-2 gap-3">
            <div className="d-md-flex justify-content-around mb-2">
              <p>
                Author :{" "}
                <a href="#" target="_blank">
                  {data.first_name}
                </a>
              </p>
              <p>
                Source :{" "}
                <span className="">
                  {data.order_id ? "Invitation" : "Organic"}
                </span>
              </p>
              <p>
                reference number :{" "}
                <a href="#" target="_blank">
                  {data.order_id}
                </a>
              </p>
            </div>
            <hr />
            <p>{data.content}</p>
          </div>
        </td>
      </tr>
    </>
  );
}

export default ReviewItem;
