import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ListingInvitationItem from "../../components/ListingInvitationItem";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
// import ReviewItem from "../../components/ReviewItem";

function CollectPage() {
  const axios = useAxios();

  const [isActive, setActive] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [merchantInvitationFilter, setMerchantInvitationFilter] =
    useState(true);
  const [productInvitationFilter, setProductInvitationFilter] = useState(true);
  const [merchantData, setMerchantData] = useState({});
  const [invitations, setInvitations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInvitations, setFilteredInvitations] = useState([]);

  const [deliveredFilter, setDeliveredFilter] = useState(true);
  const [notDeliveredFilter, setNotDevliveredFilter] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);

    // Assurez-vous que la date de fin est toujours supérieure ou égale à la date de début
    if (newStartDate > endDate) {
      setEndDate(newStartDate);
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);

    // Assurez-vous que la date de fin est toujours supérieure ou égale à la date de début
    if (newEndDate < startDate) {
      setStartDate(newEndDate);
    }
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Fonction de filtre
    const filterInvitations = () => {
      const filtered = invitations.filter(
        (invitation) =>
          invitation.customer_firstname
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          invitation.customer_lastname
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          invitation.Recipient.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredInvitations(filtered);
      if (filtered.length == 0) setFilteredInvitations(invitations);
    };
    setIsChanged(!isChanged);
    // Appeler la fonction de filtre lorsque le terme de recherche change
    filterInvitations();
    console.log("searchTerm", searchTerm);
  }, [isChanged, searchTerm]);

  const handleMerchantInvitationFilterChange = () => {
    setMerchantInvitationFilter(!merchantInvitationFilter);
  };

  const handleProductInvitationFilterChange = () => {
    setProductInvitationFilter(!productInvitationFilter);
  };

  const handleDeliveredFilterChange = () => {
    setDeliveredFilter(!deliveredFilter);
  };

  const handleNotDeliveredFilterChange = () => {
    setNotDevliveredFilter(!notDeliveredFilter);
  };

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          axios
            .get(`/merchant-profile/${response.data.website}`)
            .then((res) => {
              setMerchantData(res.data[0]);

              setAllTransactions(res.data[0]);
            })

            .catch((error) => {});
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  }, []);

  useEffect(() => {
    // Fonction pour effectuer la requête et mettre à jour le state
    const fetchData = async () => {
      try {
        // Utilisation de fetch pour récupérer les données depuis le serveur
        const response = await fetch(
          `https://api.veritatrust.com/v1/invitations/${merchantData.id}`
        );

        // Vérification de la réussite de la requête
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données");
        }

        // Conversion de la réponse en JSON
        const result = await response.json();

        // Mise à jour du state avec les données récupérées
        setInvitations(result);
        setIsChanged(!isChanged);
      } catch (error) {
        console.error("Erreur:", error.message);
      }
    };

    // Appel de la fonction pour récupérer les données au montage du composant
    fetchData();
  }, [merchantData]);

  const [allTransactions, setAllTransactions] = useState({
    id: 320,
    name: "Amazon fr",
    description:
      "Amazon.fr est la version française du célèbre site de commerce électronique Amazon. Lancé en 2000, Amazon.fr offre une vaste gamme de produits, allant des livres et produits électroniques aux vêtements, aux articles ménagers et bien plus encore. ",
    logo: "https://res.cloudinary.com/drbhco8al/image/upload/v1698847219/gbfebz6xngakioibfcbc.png",
    corporate_name: "Amazon fr",
    phone: "",
    website: "www.amazon.fr",
    email: "",
    merchant_user_id: 0,
    country_id: "FR",
    city: "",
    zip_code: 0,
    category_1: 111,
    category_2: 141,
    category_3: 436,
    last_session: null,
    invitation_delay: 5,
    invitation_delay_product_review: 0,
    ReviewsNumber: 3,
    ReviewMean: 4.3333,
    status: "1",
    PageViewsNb: 37,
    Language_review_collecting: 27,
    Data_to_collect: "1",
    InviteFrequency: "1",
    EmailToReplay: "replyu_@amazon.fr",
    SenderName: "amazon.fr",
    createdAt: null,
    updatedAt: "2023-12-22T08:54:00.000Z",
  });

  function handleClick() {
    setActive(!isActive);
  }
  const handleFilterClick = () => {
    // Filtrez les données en fonction des intervalles de dates
    const filtered = invitations.filter((item) => {
      const itemDate = new Date(item.Sent_at);
      const sDate = new Date(startDate);
      const edDate = new Date(endDate);

      if (sDate && endDate) {
        return itemDate >= sDate && itemDate <= edDate;
      } else if (sDate) {
        return itemDate >= sDate;
      } else if (edDate) {
        return itemDate <= edDate;
      } else {
        // Si aucune date n'est sélectionnée, affichez toutes les données
        return true;
      }
    });

    setInvitations(filtered);
  };
  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>

        <main>
          <NavBar
            proposTitle={"Collect reviews"}
            handleClick={handleClick}
          ></NavBar>
          <nav class="navbar navbar-expand-lg filter-reviews">
            <form class="d-flex me-2" role="search">
              <input
                type="text"
                class="form-control"
                placeholder="Search by name or email"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse gap-2"
              id="navbarTogglerDemo01"
            >
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {t("b2b.CollectPage.Status_details")}
                </button>
                <form class="dropdown-menu p-2">
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="product"
                      checked={deliveredFilter}
                      onChange={handleDeliveredFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="product">
                      {t("b2b.CollectPage.Delivered")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                      checked={notDeliveredFilter}
                      onChange={handleNotDeliveredFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.CollectPage.NotDelivered")}
                    </label>
                  </div>
                </form>
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {t("b2b.CollectPage.InvitationMethod")}
                </button>
                <form class="dropdown-menu p-2">
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="1star"
                      checked
                    />
                    <label class="form-check-label text-nowrap" for="1star">
                      {t("b2b.CollectPage.AutomaticInvitation")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />

                  <div class="d-flex gap-2">
                    <button type="submit" class="btn btn-success">
                      {t("b2b.CollectPage.Apply")}
                    </button>
                    <button type="submit" class="btn btn-danger">
                      {t("b2b.CollectPage.Cancel")}
                    </button>
                  </div>
                </form>
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {t("b2b.CollectPage.Invitation_type")}
                </button>
                <form class="dropdown-menu p-2">
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                      checked={merchantInvitationFilter}
                      onChange={handleMerchantInvitationFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.CollectPage.Merchant_review")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="product"
                      checked={productInvitationFilter}
                      onChange={handleProductInvitationFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="product">
                      {t("b2b.CollectPage.Product_review")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.CollectPage.Invitation_reminders")}
                    </label>
                  </div>
                </form>
              </div>
              <div className="d-flex justify-content-center">
                <input
                  class="form-control me-2"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <small>{t("b2b.CollectPage.From_date")}</small>
              </div>
              <div className="d-flex justify-content-center">
                <input
                  class="form-control me-2"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
                <small>{t("b2b.CollectPage.To_date")}</small>
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleFilterClick}
              >
                {t("b2b.CollectPage.Apply")}
              </button>
              <button type="button" class="btn btn-primary ms-auto export">
                {t("b2b.CollectPage.Export")}
              </button>
            </div>
          </nav>
          <div class="suggestions-reviews mt-5">
            <h2>{t("b2b.CollectPage.Reviews_send")}</h2>
            <div class="reviews  p-3 rounded-4">
              <h3 class="h5">
                {filteredInvitations.length}{" "}
                {t("b2b.CollectPage.Export", { number: 12 })}
              </h3>
              <table id="invitations" class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">{t("b2b.CollectPage.Referencenumber")}</th>
                    <th scope="col">{t("b2b.CollectPage.Destination")}</th>
                    <th scope="col">{t("b2b.CollectPage.Sending_status")}</th>
                    <th scope="col">{t("b2b.CollectPage.Creation_date")}</th>
                    <th scope="col">{t("b2b.CollectPage.Sending_date")}</th>
                    <th scope="col">{t("b2b.CollectPage.Invitation_type")}</th>
                  </tr>
                </thead>
                <tbody class="accordion">
                  {filteredInvitations &&
                    filteredInvitations
                      .filter(
                        (invitation) =>
                          (merchantInvitationFilter &&
                            invitation.invitation_type == "merchant_review") ||
                          (productInvitationFilter &&
                            invitation.invitation_type == "product_review")
                      )
                      .filter(
                        (invitation) =>
                          (deliveredFilter &&
                            (invitation.Delivery_status == "Delivered" ||
                              invitation.Delivery_status == "First_open" ||
                              invitation.Delivery_status == "Cliqued")) ||
                          (notDeliveredFilter &&
                            invitation.Delivery_status == "Not delivered")
                      )
                      .map((item) => (
                        <ListingInvitationItem
                          data={item}
                          MerchantData={allTransactions}
                        ></ListingInvitationItem>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default CollectPage;
