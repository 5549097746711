import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/flaticon_veritatrust.css";
import "../../styles/dashboard.css";

import "../../styles/owl.carousel.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import LogoVt from "../../assets/logo.png";

function WidgetResumeComponentBlockVeritaTrust(props) {
  const { merchantdata } = props;

  return (
    <div className="global_link d-md-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center">
        <img
          style={{ margin: "-7px 0 0 3px" }}
          src={LogoVt}
          alt="Veritatrust"
          height="29"
          width="150"
        />{" "}
        <p className="reviews_number m-0 me-2">
          <strong>{merchantdata?.ReviewMean?.toFixed(1)}</strong>/5
        </p>
      </div>
      <a
        href="#"
        className="d-flex justify-content-center align-items-center"
        target="_blank"
      >
        <p className="reviews_number m-0 ms-2">
          Read our <strong>{merchantdata?.ReviewsNumber}</strong>
          reviews <strong>›</strong>
        </p>
      </a>
    </div>
  );
}

export default WidgetResumeComponentBlockVeritaTrust;
