import React, { useEffect } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import logoVt from "../../assets/logo.png";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/owl.carousel.css";
import ReviewItemWidget from "../ReviewItemWidget";

function WidgetReviewComponent4(props) {
  const { reviews, merchantData } = props; // Extract merchant from props
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    variableWidth: false,
    centerMode: false,
    swipeToSlide: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const notes = ["Bad", "Passable", "Average", "Good", "Excellent"];

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("b2b.ShowcasePage.widget")} 4
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section class="">
            <div class="first global_link d-flex align-content-center justify-content-center flex-column w-auto align-items-center mx-auto mb-4">
              <img
                style={{ margin: "-7px 0 0 3px" }}
                src={logoVt}
                alt="Veritatrust"
                height="29"
                width="150"
              />

              <p class="mb-1">
                <span class="reviews_number">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </span>{" "}
                -{" "}
                <span class="reviews_number">
                  {" "}
                  {notes[Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1]}
                </span>
              </p>
              <a href="#" target="_blank">
                <span class="reviews_number">
                  {t("b2b.ShowcasePage.read_our")}{" "}
                  <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                  {t("b2b.ShowcasePage.reviews")}
                </span>
              </a>
              <span class="reviews_number small">
                {t("b2b.ShowcasePage.trusted_reviews_on_blockchain")}
              </span>
            </div>

            <div class="carrousel">
              <Slider {...settings}>
                {reviews.slice(0, 3).map((review) => (
                  <ReviewItemWidget
                    key={review.id}
                    review={review}
                  ></ReviewItemWidget>
                ))}
              </Slider>
            </div>
          </section>

          {/* <section className="d-md-flex">
            <div class="first global_link d-flex align-content-center justify-content-center flex-column w-auto align-items-center">
              <img
                style={{ margin: "-7px 0 0 3px" }}
                src={logoVt}
                alt="Veritatrust"
                height="29"
                width="150"
              />

              <p class="mb-1">
                <span class="reviews_number">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </span>{" "}
                - <span class="reviews_number">Very good</span>
              </p>
              <a href="#" target="_blank">
                <span class="reviews_number">
                  Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                  reviews
                </span>
              </a>
              <span class="reviews_number small">
                Trusted Reviews on the Blockchain
              </span>
            </div>
            <div class="carrousel w-75">
              <Slider {...settings}>
                {reviews.slice(0, 3).map((review) => (
                  <ReviewItemWidget
                    key={review.id}
                    review={review}
                  ></ReviewItemWidget>
                ))}
              </Slider>
            </div>
          </section> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("b2b.ShowcasePage.close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WidgetReviewComponent4;
