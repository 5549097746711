import React from "react";
import { useLocation } from "react-router-dom";
import logoPicto from "../../assets/logo-picto-veritatrust.png";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import { Link } from "react-router-dom";
import "../../styles/owl.carousel.css";
import { useAxios } from "../../utils/hook/useAxios";

function SideBar({ isActive, handleClick }) {
  const axios = useAxios();
  const location = useLocation();

  return (
    <>
      <aside
        className="sticky-top"
        style={{ display: isActive ? "none" : "block" }}
      >
        <div className="top">
          <div className="close" id="close-btn" onClick={handleClick}>
            <span className="material-symbols-sharp">close</span>
          </div>
          <div className="logo">
            <img src={logoPicto} alt="Veritatrust" />
            <h2>
              Verita<span>Trust</span>
            </h2>
          </div>
        </div>
        <div className="sidebar">
          <Link to="/" className={location.pathname === "/" ? "active" : ""}>
            <span className="material-symbols-sharp">Dashboard</span>
            <h3>Dashboard</h3>
          </Link>
          <Link
            to="/manage"
            className={location.pathname === "/manage" ? "active" : ""}
          >
            <span className="material-symbols-sharp">Reviews</span>
            <h3>Manage</h3>
          </Link>
          <Link
            to="/collect"
            className={location.pathname === "/collect" ? "active" : ""}
          >
            <span className="material-symbols-sharp">download</span>
            <h3>Collect</h3>
          </Link>
          <Link
            to="/showcase"
            className={location.pathname === "/showcase" ? "active" : ""}
          >
            <span className="material-symbols-sharp">apps</span>
            <h3>Showcase</h3>
          </Link>
          <Link
            to="/settings"
            className={location.pathname === "/settings" ? "active" : ""}
          >
            <span className="material-symbols-sharp">Settings</span>
            <h3>Settings</h3>
          </Link>
          <Link
            to="/upgrade"
            className={
              location.pathname.includes("upgrade") ||
              location.pathname.includes("checkout")
                ? "active"
                : ""
            }
          >
            <span className="material-symbols-sharp">inventory</span>
            <h3>Choose Pack</h3>
          </Link>
          <div>
            {" "}
            <a href="http://b2b.veritatrust.com/api/logout">
              <span className="material-symbols-sharp">Logout</span>
              <h3>Logout</h3>
            </a>
          </div>
        </div>
      </aside>
    </>
  );
}

export default SideBar;
