import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoBusinessVeritatrust from "../../assets/logo-veritatrust-business.png";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

const clientRedirect = {
  facebook: "https://api.b2b.veritatrust.com/v1/auth/facebook",
  google: "https://api.b2b.veritatrust.com/v1/auth/google",
  paypal: "http://localhost:3001/v1/auth/paypal",
};

function LoginPage() {
  const axios = useAxios();

  const [isActive, setActive] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  function handleClick() {
    setActive(!isActive);
  }

  const [darkMode, setDarkMode] = useState(false);

  const handleToggle = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("dark-theme-variables");
  };

  //comportement
  function handleChange(e) {
    const { name, value } = e.target;
    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/login", user)
      .then((res) => {
        if (res.data.token) {
          console.log(res.data);
          window.localStorage.setItem("token", res.data.token);
          window.location.href = "/";
        } else {
          console.log("Error to connect");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Invalid password or username");
      });
  };

  return (
    <>
      <ToastContainer />

      <header className="bg-primary login d-flex mb-3  w-100">
        <img
          className="my-2 mx-auto"
          src={logoBusinessVeritatrust}
          alt="veritatrust business"
          width="180"
        />
        {/* 
        <button className="btn btn-success text-light" onClick={handleToggle}>
          {darkMode ? "Mode Clair" : "Mode Sombre"}
        </button> */}
        <div className="right">
          <div className="theme-toggler text-light me-5 mt-3">
            <span
              className={
                darkMode
                  ? "material-symbols-sharp"
                  : "material-symbols-sharp active"
              }
              onClick={handleToggle}
            >
              light_mode
            </span>
            <span
              className={
                darkMode
                  ? "material-symbols-sharp active"
                  : "material-symbols-sharp"
              }
              onClick={handleToggle}
            >
              dark_mode
            </span>
          </div>
        </div>
      </header>
      <div className="container">
        <main className="login">
          <div className="row align-items-center">
            <div className="col-lg-6 mx-auto">
              <div className="reviews login-panel p-5">
                <h2 className="mb-3">
                  {t("b2b.SignPage.log_in_to_veritatrust_business")}
                </h2>
                <div className="d-grid gap-2">
                  <a
                    className="btn btn-primary btn-lg"
                    href={clientRedirect.facebook}
                  >
                    {t("b2b.SignPage.sign_in_with_facebook")}
                  </a>
                  <a
                    className="btn btn-primary btn-lg"
                    href={clientRedirect.google}
                  >
                    {t("b2b.SignPage.sign_in_with_google")}
                  </a>
                </div>
                <div className="orbymail text-center">
                  <div className="separator">{t("b2b.Signup.or")}</div>
                </div>
                <h4> {t("b2b.SignPage.connect_with_your_business_email")}</h4>
                <form id="login" onSubmit={handleSubmit}>
                  <div className="input-group has-validation">
                    <input
                      type="email"
                      className="form-control form-control-lg is-valid"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                      id="workemail"
                      aria-describedby="emailHelp"
                      placeholder="Professional email"
                    />
                    <span className="input-group-text bg-success text-white">
                      <i className="flaticon-user"></i>
                    </span>
                    {/* <div className="invalid-feedback">
                      Your email adresse is invalide.
                    </div> */}
                  </div>
                  <div className="form-floating mb-3">
                    <div id="emailHelp" className="form-text">
                      {t("b2b.SignPage.WeIllNeverMessage")}
                    </div>
                  </div>
                  <div className="input-group mb-2 has-validation">
                    <input
                      type="password"
                      className="form-control form-control-lg is-valid"
                      id="secretkey"
                      aria-label="emailHelp"
                      placeholder="Password"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text bg-success text-white toggle-password">
                      <i className="flaticon-seen"></i>
                    </span>
                  </div>
                  <div className="d-flex">
                    <p>
                      <a href="#">{t("b2b.SignPage.forget_your_password")}</a>
                    </p>
                  </div>
                  <div className="d-grid gap-2 my-3">
                    <button type="submit" className="btn btn-primary btn-lg">
                      {t("b2b.SignPage.login")}
                    </button>
                  </div>
                  <p>
                    {t("b2b.SignPage.dont_have_veritatrust_account")}{" "}
                    <Link to={`/${lang}/signup`}>
                      {t("b2b.SignPage.sign_up_for_free")}
                    </Link>
                    .
                  </p>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default LoginPage;
