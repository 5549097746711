import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EmailPreview from "../EmailPreview";

function ListingInvitationItem({ data, MerchantData }) {
  const { t } = useTranslation();
  const status_messages = {
    "Not delivered": "Not delivered",
    Delivered: "Delivered",
    First_open: "Delivered",
    Cliqued: "Delivered",
  };
  const [modalShow, setModalShow] = useState(false);

  function handleModalClick() {
    setModalShow(true);
  }

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <tr
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + data.Reference_number}
        aria-expanded="false"
        aria-controls={data.Reference_number}
      >
        <th scope="row">{data.Reference_number}</th>
        <td>
          <p>
            <strong>{data.Recipient}</strong>
          </p>
          <span class="text-muted">
            {data.customer_firstname} {data.customer_lastname}
          </span>
        </td>
        <td>
          <h5>
            <span
              class={
                data.Delivery_status == "Delivered" ||
                data.Delivery_status == "First_open" ||
                data.Delivery_status == "Cliqued"
                  ? "badge bg-success"
                  : data.has_sent == "1"
                  ? "badge bg-danger"
                  : "badge bg-warning"
              }
            >
              {data.has_sent == "1"
                ? status_messages[data.Delivery_status]
                : "In progress"}
            </span>
          </h5>
        </td>
        <td>{data.createdAt ? moment(data.createdAt).calendar() : ""}</td>
        <td>{data.Sent_at ? moment(data.Sent_at).calendar() : ""}</td>
        <td>
          {data.invitation_type == "merchant_review"
            ? t("b2b.CollectPage.AccountYourLastReviewsTableMerchantReview")
            : t("b2b.CollectPage.AccountYourLastReviewsTableProductReview")}
        </td>
      </tr>
      <tr
        id={data.Reference_number}
        class="accordion-collapse collapse"
        data-bs-parent="#invitations"
      >
        <td class="accordion-body" colspan="6">
          <div class="my-3">
            <div class="row">
              <div class="col-lg-4">
                <h6>{t("b2b.CollectPage.Status_details")}</h6>
                <p>
                  {data.Delivery_status}{" "}
                  <a href="#"> {t("b2b.CollectPage.Readmore")}</a>
                </p>
                <dl class="row">
                  <dt class="col-sm-3">{t("b2b.CollectPage.Create")}</dt>
                  <dd class="col-sm-9">
                    {data.createdAt
                      ? moment(data.createdAt).format("MMMM Do YYYY, h:mm:ss")
                      : ""}
                  </dd>
                  <dt class="col-sm-3">{t("b2b.CollectPage.Send")}</dt>
                  <dd class="col-sm-9">
                    {moment(data.Sent_at).format("MMMM Do YYYY, h:mm:ss")}
                  </dd>
                  <dt class="col-sm-3">{t("b2b.CollectPage.Type")}</dt>
                  <dd class="col-sm-9">
                    {data.invitation_type == "merchant_review"
                      ? t(
                          "b2b.CollectPage.AccountYourLastReviewsTableMerchantReview"
                        )
                      : t(
                          "b2b.CollectPage.AccountYourLastReviewsTableProductReview"
                        )}
                  </dd>
                </dl>
              </div>
              <div class="col-lg-4">
                <h6>{t("b2b.CollectPage.Sendingdetails")}</h6>
                <dl class="row">
                  <dt class="col-sm-5">{t("b2b.CollectPage.Sendername")}</dt>
                  <dd class="col-sm-7">{MerchantData.SenderName}</dd>
                  <dt class="col-sm-5">{t("b2b.CollectPage.Sendermail")}</dt>
                  <dd class="col-sm-7">contact@veritatrust.com</dd>
                  <dt class="col-sm-5">{t("b2b.CollectPage.ReplyTo")}</dt>
                  <dd class="col-sm-7">{MerchantData.EmailToReplay}</dd>
                  <dt class="col-sm-5">{t("b2b.CollectPage.Source")}</dt>
                  <dd class="col-sm-7">{data.source}</dd>
                </dl>
              </div>
              <div class="col-lg-4">
                <h6>{t("b2b.CollectPage.Recipientdetails")}</h6>
                <dl class="row">
                  <dt class="col-sm-5">
                    {t("b2b.CollectPage.Emailrecipient")}
                  </dt>
                  <dd class="col-sm-7">{data.Recipient}</dd>
                  <dt class="col-sm-5">
                    R{t("b2b.CollectPage.Recipientname")}
                  </dt>
                  <dd class="col-sm-7">
                    {data.customer_firstname} {data.customer_lastname}
                  </dd>
                  <dt class="col-sm-5">
                    {t("b2b.CollectPage.Referencenumber")}
                  </dt>
                  <dd class="col-sm-7">{data.Reference_number}</dd>
                </dl>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-outline-primary"
                onClick={handleModalClick}
              >
                {t("b2b.CollectPage.Seeinvitationemail")}
              </button>
              <EmailPreview
                show={modalShow}
                onHide={() => setModalShow(false)}
                firstname={data.customer_firstname}
                lastname={data.customer_lastname}
                domaine_name={data.domaine_name}
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default ListingInvitationItem;
