import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import MerchandStatus from "../../components/MerchandStatus";
import NavBar from "../../components/NavBar";
import ProcessInfosModal from "../../components/ProcessInfosModal";
import ReviewItem from "../../components/ReviewItem";
import SideBar from "../../components/SideBar";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function DashboardPage() {
  const axios = useAxios();

  const [globalScore, setGlobalScore] = useState(0);
  const [reviews, setReviews] = useState(0);
  const [reviewsList, setReviewsList] = useState(0);
  const [merchandData, setMerchandData] = useState({});

  const [reviewsWithoutAnswers, setReviewsWithoutAnswers] = useState(0);
  const [reviewsWithoutAnswersInfMean, setReviewsWithoutAnswersInfMean] =
    useState(0);

  const [ranking, setRanking] = useState(0);
  const [user, setUser] = useState({});

  const [isActive, setActive] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  function handleModalClick() {
    setModalShow(true);
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/auth/google/callback");
        const token = response.data.token;
        localStorage.setItem("jwtToken", token); // Stockage du JWT côté client
        // Faites d'autres actions nécessaires après la récupération du JWT
      } catch (error) {
        console.error("Error:", error.response.data.message);
      }
    };

    fetchData();

    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        const profileResponse = await axios.get(
          "/merchant-profile/" + response.data.website
        );

        if (profileResponse.data) {
          let profileData = profileResponse.data[0];
          window.localStorage.setItem(
            "profileData",
            JSON.stringify(profileData)
          );
          setMerchandData(profileData);
        }

        if (response.data) {
          setUser(response.data);
          const reviewdata = await axios.get(
            "/organic-merchant-review/" + response.data.website + "?stars="
          );
          setReviewsList(reviewdata.data);
          setReviews(reviewdata.data.length);
          setReviewsWithoutAnswers(
            reviewdata.data.filter((review) => review.isAnswered === "0").length
          );
          setReviewsWithoutAnswersInfMean(
            reviewdata.data.filter(
              (review) => review.isAnswered === "0" && review.rating <= 3
            ).length
          );

          if (reviewdata.data.length > 0) setGlobalScore(reviewdata.data[0].RM);
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  }, []);

  function handleClick() {
    setActive(!isActive);
  }

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Dashboard"}
            proposName={user.nickname || user.first_name}
            handleClick={handleClick}
          ></NavBar>
          <div className="insights">
            <div className="note text-center">
              <p className="display-1">
                <strong>{globalScore}</strong>
              </p>
              <p className="lead">Global score</p>
            </div>
            <div className="reviews text-center">
              <p className="display-1">
                <strong>{reviews}</strong>
              </p>
              <p className="lead">Reviews</p>
            </div>
            <div className="Ranking text-center">
              <p className="display-1">
                <strong>{ranking}</strong>
              </p>
              <p className="lead">Ranking</p>
            </div>
          </div>

          <div className="recent-reviews">
            <h2>Last reviews</h2>
            <table className="table align-middle table-sm">
              <thead className="">
                <tr className="row">
                  <th scope="col" className="col-md-1">
                    Date
                  </th>
                  <th scope="col" className="col-md-1">
                    Rating
                  </th>
                  <th scope="col" className="col-md-9">
                    Title
                  </th>
                  <th scope="col" className="col-md-1">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {reviewsList.length > 0 ? (
                  reviewsList
                    .slice(0, 9)
                    .map((review, index) => (
                      <ReviewItem
                        key={index}
                        data={review}
                        propsIdItem={index}
                      />
                    ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            <Link
              to="/manage"
              className="btn btn-success text-white"
              type="button"
            >
              All reviews
            </Link>
          </div>
          <div className="suggestions-reviews mt-5">
            <h2>Suggestions</h2>
            <ul className="list-group list-group-flush mb-3">
              <li className="list-group-item">
                {reviewsWithoutAnswers} reviews without answers
              </li>
              <li className="list-group-item">
                {reviewsWithoutAnswersInfMean} reviews rated between 1-3 without
                answers
              </li>
            </ul>
            <Link className="btn btn-success text-white" to="/manage">
              Answers reviews
            </Link>
          </div>
          <div className="recent-reviews">
            <h2>Your statuts</h2>
            <MerchandStatus data={merchandData}></MerchandStatus>
            <div className="mx-auto text-center">
              <p className="mb-3 lead text-dark">
                <strong>
                  Want to get the most out of VeritaTrust? Follow these steps.
                </strong>
              </p>
              <button
                className="btn btn-success text-white"
                type="button"
                onClick={handleModalClick}
              >
                Complete the process
              </button>
              <ProcessInfosModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default DashboardPage;
