import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
// import ReviewItem from "../../components/ReviewItem";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import ReviewComponentItem from "../../components/ReviewComponentItem";

function Items({
  currentItems,
  answeredFilter,
  unansweredFilter,
  merchantreviewFilter,
  productreviewFilter,
}) {
  return (
    <>
      {currentItems &&
        currentItems
          .filter(
            (response) =>
              (answeredFilter && response.review.isAnswered === "1") ||
              (unansweredFilter && response.review.isAnswered === "0")
          )
          .filter(
            (response) =>
              (merchantreviewFilter && response.review_type === "0") ||
              (productreviewFilter && response.review_type === "1")
          )
          .map(
            (response, index) =>
              response.review.status === "published" && (
                <div>
                  <ReviewComponentItem
                    key={index}
                    data={response.review}
                    PropsresponseData={response.answers}
                    propsReviewType={response.review_type}
                  />
                </div>
              )
          )}
    </>
  );
}

function PaginationComponent({
  itemsPerPage,
  items,
  answeredFilter,
  unansweredFilter,
  merchantreviewFilter,
  productreviewFilter,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );

    console.log(currentItems);
    setItemOffset(newOffset);
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("page", selectedPage + 1);
    const newUrl = window.location.pathname + "?" + queryParams.toString();
    window.history.pushState({}, "", newUrl);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        answeredFilter={answeredFilter}
        unansweredFilter={unansweredFilter}
        merchantreviewFilter={merchantreviewFilter}
        productreviewFilter={productreviewFilter}
      />
      <nav className="mt-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination pagination-lg justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          disabledLinkClassName="disabled"
          activeLinkClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
        />
      </nav>
    </>
  );
}

function ManagePage() {
  const axios = useAxios();

  const [isActive, setActive] = useState(false);
  const [reviewsList, setReviewsList] = useState([]);

  const [ratings, setRatings] = useState("");
  const [answeredFilter, setAnsweredFilter] = useState(true);
  const [unansweredFilter, setUnansweredFilter] = useState(true);

  const [productreviewFilter, setProductrevirewFilter] = useState(true);
  const [merchantreviewFilter, setMerchantReviewFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [merchantData, setMerchantData] = useState({});
  const [rating, setRating] = useState([false, false, false, false, false]);
  const [checkboxes, setCheckboxes] = useState({
    5: false,
    4: false,
    3: false,
    2: false,
    1: false,
  });
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  }

  useEffect(() => {
    handleRatingChange();
  }, [checkboxes]);

  // Fonction pour mettre à jour l'état en fonction de la case cochée
  const handleRatingChange = (index) => {
    const ratingFilters = Object.keys(checkboxes)
      .filter((key) => checkboxes[key])
      .join(",");
    const currentUrlParams = new URLSearchParams(window.location.search);

    currentUrlParams.set("stars", ratingFilters);

    if (ratingFilters.length > 0) {
      const params = { stars: ratingFilters };
      const newUrl = `${window.location.pathname}?${Object.entries(params)
        .map((e) => e.join("="))
        .join("&")}`;
      window.history.pushState({}, "", newUrl);
    } else {
      const newUrl = window.location.pathname;
      window.history.pushState({}, "", newUrl);
      currentUrlParams.delete("stars");
    }
    let fetchReviews = async () => {
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          const reviewdata = await fetch(
            "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
              merchantData.id
          );
          const reviewJson = await reviewdata.json();

          const reviewdata2 = await fetch(
            "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
              merchantData.id
          );
          const reviewJson2 = await reviewdata2.json();

          setReviewsList(
            reviewJson
              .concat(reviewJson2)
              .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
              .filter((r) => r.review.status === "published")
          );
          let filteredReviews = reviewJson
            .concat(reviewJson2)
            .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
            .filter((r) => r.review.status === "published");
          // Filtrer les avis en fonction des évaluations sélectionnées
          filteredReviews = filteredReviews.filter((review) => {
            return checkboxes[review.review.rating];
          });

          setReviewsList(filteredReviews);
        }
      } catch (error) {}
    };

    fetchReviews();
  };

  const handleAnsweredFilterChange = () => {
    setAnsweredFilter(!answeredFilter);
  };

  const handleUnsweredFilterChange = () => {
    setUnansweredFilter(!unansweredFilter);
  };

  const handleProductReviewFilterChange = () => {
    setProductrevirewFilter(!productreviewFilter);
  };

  const handleMerchantReviewFilterChange = () => {
    setMerchantReviewFilter(!merchantreviewFilter);
  };

  function handleClick() {
    setActive(!isActive);
  }

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");

        const responseMerchant = await axios.get(
          `/merchant-profile/${response.data.website}`
        );
        setMerchantData(responseMerchant.data[0]);

        const reviewdata = await fetch(
          "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
            responseMerchant.data[0].id
        );
        const reviewJson = await reviewdata.json();

        const reviewdata2 = await fetch(
          "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
            responseMerchant.data[0].id
        );
        const reviewJson2 = await reviewdata2.json();

        setReviewsList(
          reviewJson
            .concat(reviewJson2)
            .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
            .filter((r) => r.review.status === "published")
        );
        setFilteredReviews(
          reviewJson
            .concat(reviewJson2)
            .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
            .filter((r) => r.review.status === "published")
        );
      } catch (error) {}
    };

    fetchAuthStatus();
  }, []);

  const handleAddshowcase = async () => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          const reviewdata = await fetch(
            "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
              merchantData.id
          );
          const reviewJson = await reviewdata.json();

          const reviewdata2 = await fetch(
            "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
              merchantData.id
          );
          const reviewJson2 = await reviewdata2.json();

          setReviewsList(
            reviewJson
              .concat(reviewJson2)
              .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
              .filter((r) => r.review.status === "published")
              .filter((r) => r.review.addShowCase === "1")
          );
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  };

  const handleFavorite = async () => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          const reviewdata = await fetch(
            "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
              merchantData.id
          );
          const reviewJson = await reviewdata.json();

          const reviewdata2 = await fetch(
            "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
              merchantData.id
          );
          const reviewJson2 = await reviewdata2.json();

          setReviewsList(
            reviewJson
              .concat(reviewJson2)
              .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
              .filter((r) => r.review.status === "published")
              .filter((r) => r.review.favorite === "1")
          );
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  };

  const handleAllReviews = async () => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          const reviewdata = await fetch(
            "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
              merchantData.id
          );
          const reviewJson = await reviewdata.json();

          const reviewdata2 = await fetch(
            "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
              merchantData.id
          );
          const reviewJson2 = await reviewdata2.json();

          setReviewsList(
            reviewJson
              .concat(reviewJson2)
              .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
              .filter((r) => r.review.status === "published")
          );
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  };

  function exportToExcel() {
    // Créez un tableau contenant vos données
    return reviewsList.map((review) => ({
      "Review ID": review.review.id,
      Rating: review.review.rating,
      Title: review.review.title,
      "Experience Date": review.review.experience_date,
      Status: review.review.status,
      Source: review.review.source,
      "User First Name": review.review.userprofile.first_name,
      "User Last Name": review.review.userprofile.last_name,
      "Merchant Name": review.review.merchant_profile.name,
      "Merchant Logo": review.review.merchant_profile.logo,
      "Merchant Description": review.review.merchant_profile.description,
      "Merchant Website": review.review.merchant_profile.website,
      Answers: review.review.answers,
      // Ajoutez d'autres propriétés selon vos besoins
    }));
  }

  useEffect(() => {
    let fetchReviews = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ratings = urlParams.get("stars");
      //urlParams+= `?stars=${ratings}`;
      // setReviews(res.data);
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          const reviewdata = await fetch(
            "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
              merchantData.id
          );
          const reviewJson = await reviewdata.json();

          const reviewdata2 = await fetch(
            "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
              merchantData.id
          );
          const reviewJson2 = await reviewdata2.json();

          setReviewsList(
            reviewJson
              .concat(reviewJson2)
              .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
              .filter((r) => r.review.status === "published")
          );
          let filteredReviews = reviewJson
            .concat(reviewJson2)
            .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
            .filter((r) => r.review.status === "published");
          // Filtrer les avis en fonction des évaluations sélectionnées
          filteredReviews = filteredReviews.filter((review) => {
            // Vérifier si au moins une des évaluations sélectionnées correspond à l'évaluation de la revue
            return rating.some(
              (isChecked, index) =>
                isChecked && review.review.rating === index + 1
            );
          });
          setReviewsList(filteredReviews);
        }
      } catch (error) {}
    };

    fetchReviews();
  }, [ratings]);

  useEffect(() => {
    // Fonction de filtre
    setFilteredReviews(reviewsList);
    const filterReviews = () => {
      const filtered = reviewsList.filter(
        (a) =>
          a.review.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          a.review.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setReviewsList(filtered);
      if (filtered.length == 0) setReviewsList(reviewsList);
      if (searchTerm.length == 0) setReviewsList(filteredReviews);
    };
    filterReviews();
  }, [searchTerm]);

  const stars = [1, 2, 3, 4, 5]; // Les valeurs de 1 à 5

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>

        <main>
          <NavBar
            proposTitle={"Manage reviews"}
            handleClick={handleClick}
          ></NavBar>
          <nav class="navbar navbar-expand-lg filter-reviews">
            <form class="d-flex me-2" role="search">
              <input
                type="text"
                class="form-control"
                placeholder="Recherche"
                aria-label="recherche"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse gap-2"
              id="navbarTogglerDemo01"
            >
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {t("b2b.ManagePage.Reviews_type")}
                </button>
                <form class="dropdown-menu p-2">
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="product"
                      checked={productreviewFilter}
                      onChange={handleProductReviewFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="product">
                      {t("b2b.ManagePage.Product_reviews")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                      checked={merchantreviewFilter}
                      onChange={handleMerchantReviewFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.ManagePage.Merchant_reviews")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                      checked=""
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.ManagePage.Store_reviews")}
                    </label>
                  </div>
                </form>
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {t("b2b.ManagePage.Note_in_star")}
                </button>
                {/* <Filtering
                  onFilterChange={(ratings) => setRatings(ratings)}
                ></Filtering> */}
                <form class="dropdown-menu p-2">
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="1star"
                      name="1"
                      checked={checkboxes["1"]}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label text-nowrap" for="1star">
                      {t("b2b.ManagePage.Reviews_Star", { value: stars[0] })}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name="2"
                      id="2star"
                      checked={checkboxes["2"]}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label text-nowrap" for="2star">
                      {t("b2b.ManagePage.Reviews_Star", { value: stars[1] })}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name="3"
                      id="3star"
                      checked={checkboxes["3"]}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label text-nowrap" for="3star">
                      {t("b2b.ManagePage.Reviews_Star", { value: stars[2] })}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name="4"
                      id="4star"
                      checked={checkboxes["4"]}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label text-nowrap" for="4star">
                      {t("b2b.ManagePage.Reviews_Star", { value: stars[3] })}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name="5"
                      id="5star"
                      checked={checkboxes["5"]}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label text-nowrap" for="5star">
                      {t("b2b.ManagePage.Reviews_Star", { value: stars[4] })}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="d-flex gap-2">
                    <button type="submit" class="btn btn-success">
                      {t("b2b.ManagePage.Apply")}
                    </button>
                    <button type="submit" class="btn btn-danger">
                      {t("b2b.ManagePage.Cancel")}
                    </button>
                  </div>
                </form>
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {t("b2b.ManagePage.Answers")}
                </button>
                <form class="dropdown-menu p-2">
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                      checked={answeredFilter}
                      onChange={handleAnsweredFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.ManagePage.Answered")}
                    </label>
                  </div>
                  <hr class="dropdown-divider" />
                  <div class="form-check mb-2">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="merchant"
                      checked={unansweredFilter}
                      onChange={handleUnsweredFilterChange}
                    />
                    <label class="form-check-label text-nowrap" for="merchant">
                      {t("b2b.ManagePage.unanswered")}
                    </label>
                  </div>
                </form>
              </div>
              <div class="d-flex gap-2">
                <input
                  type="radio"
                  class="btn-check"
                  name="answer"
                  id="all"
                  autocomplete="off"
                  checked=""
                />
                <label
                  class="btn btn-outline-primary"
                  for="all"
                  onClick={handleAllReviews}
                >
                  {t("b2b.ManagePage.All_reviews")}
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="answer"
                  id="favorite"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-primary"
                  for="favorite"
                  onClick={handleFavorite}
                >
                  Favorite
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="answer"
                  id="showcase"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-primary"
                  onClick={handleAddshowcase}
                  for="showcase"
                >
                  Showcase
                </label>
              </div>

              <CSVLink
                data={exportToExcel()}
                filename={"data"}
                className="btn btn-primary  ms-auto export"
              >
                {t("b2b.ManagePage.Export")}
              </CSVLink>
            </div>
          </nav>
          <div className="suggestions-reviews mt-5"></div>
          <h2>{t("b2b.ManagePage.Reviews")}</h2>

          <PaginationComponent
            itemsPerPage={5}
            items={reviewsList}
            answeredFilter={answeredFilter}
            unansweredFilter={unansweredFilter}
            merchantreviewFilter={merchantreviewFilter}
            productreviewFilter={productreviewFilter}
          ></PaginationComponent>
        </main>
      </div>
    </>
  );
}

export default ManagePage;
