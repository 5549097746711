import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import {
  ACCESS_TOKEN,
  MERCHAND_ID,
  MERCHAND_WEBSITE,
  TVA_fr,
  URL_LINK_CONFIRMATION_DATA,
  URL_LINK_SELECT_PACK,
} from "../../config";
import { useAxios } from "../../utils/hook/useAxios";

function ChekoutCompanyDetails() {
  const [isActive, setActive] = useState(false);
  function handleClick() {
    setActive(!isActive);
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [IsSomeAddress, setIsSomeAddress] = useState(true);
  const packSubscription = window.localStorage.getItem("PackUpgrade");

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token
      ? window.localStorage.token || getCookie("jwtToken")
      : ACCESS_TOKEN
  );

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const [TotalAmount, setTotalAmount] = useState(0);
  const [MontlyPrice, setMontlyPrice] = useState(
    parseFloat(searchParams.get("month_price")).toFixed(2)
  );
  const axios = useAxios();

  const [Payment, setPayment] = useState(searchParams.get("payment"));
  useEffect(() => {
    setTotalAmount(parseFloat(searchParams.get("TotalAmount")).toFixed(2));
    axios
      .get("/subscriberaddress/" + MerchantID, AccessToken)

      .then((response) => {
        setBusinessName(response.data.business_name);
        setCountry(response.data.country);
        setLocalAddressRow1(response.data.local_address);
        setLocalAddressRow2(response.data.local_address);
        setCity(response.data.city);
        setZipCode(response.data.zip_code);
        setSiren(response.data.siren_company);
        setVatNumber(response.data.vat_number);
        setContactFirstName(response.data.contact_first_name);
        setContactLastName(response.data.contact_last_name);
        setContactEmail(response.data.contact_email);
        setContactPhoneNumber(response.data.contact_phone_number);
      })

      .catch((error) => console.log(error));

    axios
      .get("/subscriberbellingaddress/" + MerchantID, AccessToken)

      .then((response) => {
        setBillingBusinessName(response.data.business_name);
        setBillingCountry(response.data.country);
        setBillingLocalAddressRow1(response.data.local_address);
        setBillingLocalAddressRow2(response.data.local_address);
        setBillingCity(response.data.city);
        setBillingZipCode(response.data.zip_code);
        setBillingSiren(response.data.siren_company);
        setBillingVatNumber(response.data.vat_number);
        setBillingContactFirstName(response.data.contact_first_name);
        setBillingContactLastName(response.data.contact_last_name);
        setBillingContactEmail(response.data.contact_email);
        setBillingContactPhoneNumber(response.data.contact_phone_number);
      })

      .catch((error) => console.log(error));
  }, []);

  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );
  const [MerchantWebsite, setMerchantWebsite] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).website
      : MERCHAND_WEBSITE
  );

  const [ProfileDataID, setProfileDataID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );
  const [BusinessName, setBusinessName] = useState(null);
  const [Country, setCountry] = useState(null);
  const [LocalAddressRow1, setLocalAddressRow1] = useState(null);
  const [LocalAddressRow2, setLocalAddressRow2] = useState(null);
  const [City, setCity] = useState(null);
  const [ZipCode, setZipCode] = useState(null);
  const [Siren, setSiren] = useState(null);
  const [VatNumber, setVatNumber] = useState(null);
  const [ContactFirstName, setContactFirstName] = useState(null);
  const [ContactLastName, setContactLastName] = useState(null);
  const [ContactEmail, setContactEmail] = useState(null);
  const [ContactPhoneNumber, setContactPhoneNumber] = useState(null);

  const [BillingBusinessName, setBillingBusinessName] = useState();
  const [BillingCountry, setBillingCountry] = useState();
  const [BillingLocalAddressRow1, setBillingLocalAddressRow1] = useState();
  const [BillingLocalAddressRow2, setBillingLocalAddressRow2] = useState();
  const [BillingCity, setBillingCity] = useState();
  const [BillingZipCode, setBillingZipCode] = useState();
  const [BillingSiren, setBillingSiren] = useState();
  const [BillingVatNumber, setBillingVatNumber] = useState();
  const [BillingContactFirstName, setBillingContactFirstName] = useState();
  const [BillingContactLastName, setBillingContactLastName] = useState();
  const [BillingContactEmail, setBillingContactEmail] = useState();
  const [BillingContactPhoneNumber, setBillingContactPhoneNumber] = useState();

  function handleNextStep() {
    let dataCompany = {
      business_name: BusinessName,
      country: Country,
      local_address: LocalAddressRow1,
      city: City,
      zip_code: ZipCode,
      siren_company: Siren,
      vat_number: VatNumber,
      contact_first_name: ContactFirstName,
      contact_last_name: ContactLastName,
      contact_email: ContactEmail,
      contact_phone_number: ContactPhoneNumber,
      merchant_id: MerchantID,
      some_address_billing: IsSomeAddress,
    };

    let dataCompanySomeBilling = {
      business_name: BusinessName,
      country: Country,
      local_address: LocalAddressRow1,
      city: City,
      zip_code: ZipCode,
      siren_company: Siren,
      vat_number: VatNumber,
      contact_first_name: ContactFirstName,
      contact_last_name: ContactLastName,
      contact_email: ContactEmail,
      contact_phone_number: ContactPhoneNumber,
      merchant_id: MerchantID,
    };
    let dataCompanyBilling = {
      business_name: BillingBusinessName,
      country: BillingCountry,
      local_address: BillingLocalAddressRow1,
      city: BillingCity,
      zip_code: BillingZipCode,
      siren_company: BillingSiren,
      vat_number: BillingVatNumber,
      contact_first_name: BillingContactFirstName,
      contact_last_name: BillingContactLastName,
      contact_email: BillingContactEmail,
      contact_phone_number: BillingContactPhoneNumber,
      merchant_id: MerchantID,
    };

    if (IsSomeAddress) {
      axios
        .post("/subscriberaddress", dataCompany, AccessToken)
        .then((response) => console.log(response.data))
        .catch((error) => console.log(error));

      axios
        .post("/subscriberbellingaddress", dataCompanySomeBilling, AccessToken)
        .then((response) => console.log(response.data))
        .catch((error) => console.log(error));
    } else {
      axios
        .post("/subscriberaddress", dataCompany, AccessToken)
        .then((response) => console.log(response.data))
        .catch((error) => console.log(error));

      axios
        .post("/subscriberbellingaddress", dataCompanyBilling, AccessToken)
        .then((response) => console.log(response.data))
        .catch((error) => console.log(error));
    }
  }

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Upgrade page"}
            handleClick={handleClick}
          ></NavBar>
          <div className="recent-reviews">
            <h2>Votre abonnement</h2>
            <div className="reviews">
              <div className="recent-reviews">
                <div className="col-11 col-lg-8 pb-3 mx-auto position-relative mb-3 collecting">
                  <ul className="step-reviews nav text-center ">
                    <li className="nav-item w-25 step-1 done">
                      <span className="badge rounded-circle">1</span>
                      <p className="mb-0" aria-current="page">
                        Select subscription
                      </p>
                    </li>
                    <li className="nav-item w-25 step-2 active">
                      <span className="badge rounded-circle">2</span>
                      <p className="mb-0" aria-current="page">
                        Add your contact details
                      </p>
                    </li>
                    <li className="nav-item w-25 step-3">
                      <span className="badge rounded-circle">3</span>
                      <p className="mb-0">Confirm your subscription</p>
                    </li>
                    <li className="nav-item w-25 step-4 ">
                      <span className="badge rounded-circle">4</span>
                      <p className="mb-0">Choose a payment method</p>
                    </li>
                  </ul>
                  <div id="line">
                    <div id="line-progress" style={{ width: "35%" }}></div>
                  </div>
                </div>
              </div>
              <div className="container mb-3 subscription-selector">
                <div className="row g-4">
                  <div className="col-lg-8">
                    <h5>Subscriber contact details</h5>
                    <hr />
                    <p className="lead mb-3">Company address</p>
                    <div className="mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="delay"
                          value={BusinessName}
                          placeholder="7"
                          onChange={(e) => setBusinessName(e.target.value)}
                        />
                        <label for="delay">Name of your business</label>
                      </div>
                    </div>
                    <div className="form-floating mb-4">
                      <select
                        className="form-select"
                        id="country"
                        aria-label="country"
                        value={Country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value="en">United Kingdom</option>
                        <option value="fr">France</option>
                        <option value="it">Italia</option>
                        <option value="da">Denmark</option>
                      </select>
                      <label for="country">Country</label>
                    </div>
                    <div className="mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="delay"
                          value={LocalAddressRow1}
                          placeholder="7"
                          onChange={(e) => setLocalAddressRow1(e.target.value)}
                        />
                        <label for="delay">Address (row 1)</label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="delay"
                          value={LocalAddressRow2}
                          placeholder="7"
                          onChange={(e) => setLocalAddressRow2(e.target.value)}
                        />
                        <label for="delay">Address (row 2)</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={City}
                            placeholder="7"
                            onChange={(e) => setCity(e.target.value)}
                          />
                          <label for="delay">City</label>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={ZipCode}
                            placeholder="7"
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                          <label for="delay">Zip code</label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="delay"
                          value={Siren}
                          placeholder="7"
                          onChange={(e) => setSiren(e.target.value)}
                        />
                        <label for="delay">Company identification number</label>
                        <div className="form-text">
                          Your SIREN number is specific to your company. If you
                          are an individual, you can enter your tax
                          identification number (TIN).
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="delay"
                          value={VatNumber}
                          placeholder="7"
                          onChange={(e) => setVatNumber(e.target.value)}
                        />
                        <label for="delay">VAT number</label>
                        <div className="form-text">
                          If a valid VAT number is not provided, VAT will be
                          charged.
                        </div>
                      </div>
                    </div>
                    <hr />
                    <p className="lead mb-3">Main contact</p>
                    <div className="row">
                      <div className="col mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={ContactFirstName}
                            placeholder="7"
                            onChange={(e) =>
                              setContactFirstName(e.target.value)
                            }
                          />
                          <label for="delay">Contact first name</label>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={ContactLastName}
                            placeholder="7"
                            onChange={(e) => setContactLastName(e.target.value)}
                          />
                          <label for="delay">Contact last name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-4">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control"
                            id="delay"
                            value={ContactEmail}
                            placeholder="7"
                            onChange={(e) => setContactEmail(e.target.value)}
                          />
                          <label for="delay">Contact email</label>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="form-floating">
                          <input
                            type="tel"
                            className="form-control"
                            id="delay"
                            value={ContactPhoneNumber}
                            placeholder="7"
                            onChange={(e) =>
                              setContactPhoneNumber(e.target.value)
                            }
                          />
                          <label for="delay">Contact phone number</label>
                          <div className="form-text">
                            Your phone number will only be used by your Customer
                            Success Manager to contact you.
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h5>Subscriber billing details</h5>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={IsSomeAddress}
                        id="sameaddress"
                        checked={IsSomeAddress}
                        onChange={() => setIsSomeAddress(!IsSomeAddress)}
                      />
                      <label className="form-check-label" for="sameaddress">
                        The billing data is the same as that of the subscriber.
                      </label>
                    </div>
                    <div
                      id="fact_address"
                      className={IsSomeAddress ? "d-none" : ""}
                    >
                      <p className="lead mb-3">Company address</p>
                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={BillingBusinessName}
                            placeholder="7"
                            onChange={(e) =>
                              setBillingBusinessName(e.target.value)
                            }
                          />
                          <label for="delay">Name of your business</label>
                        </div>
                      </div>
                      <div className="form-floating mb-4">
                        <select
                          className="form-select"
                          id="country"
                          aria-label="country"
                          value={BillingCountry}
                          onChange={(e) => setBillingCountry(e.target.value)}
                        >
                          <option value="en">United Kingdom</option>
                          <option value="fr">France</option>
                          <option value="it">Italia</option>
                          <option value="da">Denmark</option>
                        </select>
                        <label for="country">Country</label>
                      </div>
                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={BillingLocalAddressRow1}
                            placeholder="7"
                            onChange={(e) =>
                              setBillingLocalAddressRow1(e.target.value)
                            }
                          />
                          <label for="delay">Address (row 1)</label>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="delay"
                            value={BillingLocalAddressRow2}
                            placeholder="7"
                            onChange={(e) =>
                              setBillingLocalAddressRow2(e.target.value)
                            }
                          />
                          <label for="delay">Address (row 2)</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="delay"
                              value={BillingCity}
                              placeholder="7"
                              onChange={(e) => setBillingCity(e.target.value)}
                            />
                            <label for="delay">City</label>
                          </div>
                        </div>
                        <div className="col mb-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="delay"
                              value={BillingZipCode}
                              placeholder="7"
                              onChange={(e) =>
                                setBillingZipCode(e.target.value)
                              }
                            />
                            <label for="delay">Zip code</label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <p className="lead mb-3">Main contact</p>
                      <div className="row">
                        <div className="col mb-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="delay"
                              value={BillingContactFirstName}
                              placeholder="7"
                              onChange={(e) =>
                                setBillingContactFirstName(e.target.value)
                              }
                            />
                            <label for="delay">Contact first name</label>
                          </div>
                        </div>
                        <div className="col mb-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="delay"
                              value={BillingContactLastName}
                              onChange={(e) =>
                                setBillingContactLastName(e.target.value)
                              }
                              placeholder="7"
                            />
                            <label for="delay">Contact last name</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-4">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control"
                              id="delay"
                              value={BillingContactEmail}
                              placeholder="7"
                              onChange={(e) =>
                                setBillingContactEmail(e.target.value)
                              }
                            />
                            <label for="delay">Contact email</label>
                          </div>
                        </div>
                        <div className="col mb-4">
                          <div className="form-floating">
                            <input
                              type="tel"
                              className="form-control"
                              id="delay"
                              value={BillingContactPhoneNumber}
                              placeholder="7"
                              onChange={(e) =>
                                setBillingContactPhoneNumber(e.target.value)
                              }
                            />
                            <label for="delay">Contact phone number</label>
                            <div className="form-text">
                              Your phone number will only be used by your
                              Customer Success Manager to contact you.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card rounded-5">
                      <div className="card-body">
                        <div className="mb-3">
                          <h4 className=" mb-0">Subscription</h4>
                          <p className="">{MerchantWebsite}</p>
                        </div>
                        <div className="mb-3">
                          <p className="mb-0">
                            <strong>Plan</strong>
                          </p>
                          <p className="text-grey">{packSubscription}</p>
                        </div>
                        <div className="mb-3">
                          <p className="mb-0">
                            <strong>Engagement</strong>
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-grey me-3">
                              12 months | Pay{" "}
                              {Payment === "annual" ? "upfront" : "monthly"}
                            </p>
                            <p className="text-grey">{TotalAmount} €</p>
                          </div>
                        </div>
                        <hr />
                        <div className="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-grey me-3">
                              Total for the subscription period
                            </p>
                            <p className="text-grey">
                              {(TotalAmount * (1 + TVA_fr)).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-grey me-3">TVA (20%)</p>
                            <p className="text-grey">
                              {" "}
                              {(TotalAmount * TVA_fr).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <h4 className="text-grey me-3">Due today</h4>
                            <h4 className="text-grey">
                              {" "}
                              {(TotalAmount * (1 + TVA_fr)).toFixed(2)} €
                            </h4>
                          </div>
                        </div>
                        <div className="d-grid gap-2">
                          <Link
                            to={
                              URL_LINK_CONFIRMATION_DATA +
                              "?month_price=" +
                              MontlyPrice +
                              "&TotalAmount=" +
                              TotalAmount +
                              "&payment=" +
                              Payment
                            }
                            className="btn btn-success rounded-5 text-white p-3"
                            onClick={handleNextStep}
                          >
                            Next step
                          </Link>
                          <Link
                            to={
                              URL_LINK_SELECT_PACK +
                              "?month_price=" +
                              MontlyPrice
                            }
                            className="btn btn-primary rounded-5 text-white p-3"
                          >
                            Back step
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default ChekoutCompanyDetails;
