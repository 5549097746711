/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatar from "../../assets/lorem-portrait.jpg";
import CategorySettingComponent from "../../components/CategorySettingComponent";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { ACCESS_TOKEN } from "../../config";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function SettingsPage() {
  const axios = useAxios();

  const [isActive, setActive] = useState(false);
  const [language, setLanguage] = useState(0);
  const [dataCollect, setDataCollect] = useState(0);
  const [InvitationDelay, setInvitationDelay] = useState(0);
  const [InviteFrequency, SetInviteFrequency] = useState(0);
  const [EmailReplayTo, setEmailReplayTo] = useState("");
  const [SenderName, setSenderName] = useState("");
  const [MerchantValues, setMerchantValues] = useState({
    name: "",
    website: "",
    email: "",
    phone: "",
    description: "",
    city: "",
    country_id: "",
  });
  const [productChecked, setProductChecked] = useState(false);
  const [merchantChecked, setMerchantChecked] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const [productImageUrl, setProductImageUrl] = useState(merchantData.logo);

  const [parentState, setParentState] = useState(true);
  const [subscriptions, setSubscriptions] = useState({});

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        console.log(response);
        if (response.data) {
          axios
            .get(`/merchant-profile/${response.data.website}`)
            .then((res) => {
              setMerchantData(res.data[0]);
              setDataCollect(res.data[0].Data_to_collect);
              setEmailReplayTo(res.data[0].EmailToReplay);
              setInvitationDelay(res.data[0].invitation_delay);
              SetInviteFrequency(res.data[0].InviteFrequency);
              setSenderName(res.data[0].SenderName);
              setProductImageUrl(res.data[0].logo);
              setLanguage(res.data[0].Language_review_collecting);
              setMerchantValues({
                logo: res.data[0].logo,
                name: res.data[0].name,
                website: res.data[0].website,
                email: res.data[0].email,
                phone: res.data[0].phone,
                description: res.data[0].description,
                city: res.data[0].city,
                country_id: res.data[0].country_id,
              });
              switch (res.data[0].Data_to_collect) {
                case "0":
                  // eslint-disable-next-line no-lone-blocks
                  {
                    setProductChecked(false);
                    setMerchantChecked(false);
                  }
                  break;
                case "1":
                  {
                    setProductChecked(true);
                    setMerchantChecked(false);
                  }
                  break;
                case "2":
                  {
                    setProductChecked(false);
                    setMerchantChecked(true);
                  }
                  break;
                case "3":
                  {
                    setProductChecked(true);
                    setMerchantChecked(true);
                  }
                  break;
                default:
              }
              setParentState(!parentState);
            })

            .catch((error) => {});
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  }, []);

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token ? window.localStorage.token : ACCESS_TOKEN
  );

  useEffect(() => {
    axios
      .get("/subscriptions/" + merchantData.id, AccessToken)

      .then((response) => {
        setSubscriptions(response.data);
      })

      .catch((error) => console.log(error));
  }, [merchantData]);

  function handleClick() {
    setActive(!isActive);
  }

  useEffect(() => {
    calculateValue();
  }, [productChecked, merchantChecked]);

  const calculateValue = () => {
    let value = 0;
    if (productChecked) value += 1;
    if (merchantChecked) value += 2;

    setDataCollect(value);
    return value;
  };

  const handleSelectLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSelectInviteFrequency = (event) => {
    SetInviteFrequency(event.target.value);
  };

  const handleSelectInviteDelay = (event) => {
    setInvitationDelay(event.target.value);
  };

  const handleEmailReplayChange = (event) => {
    setEmailReplayTo(event.target.value);
  };

  const handleSenderNameChange = (event) => {
    setSenderName(event.target.value);
  };

  // Gestionnaires d'événements pour les cases à cocher
  const handleProductChange = () => {
    setProductChecked(!productChecked);
  };

  const handleMerchantChange = () => {
    setMerchantChecked(!merchantChecked);
  };

  function handleSaveSetting() {
    const data = {
      Language_review_collecting: language,
      InviteFrequency: InviteFrequency,
      EmailToReplay: EmailReplayTo,
      SenderName: SenderName,
      invitation_delay: InvitationDelay,
      Data_to_collect: dataCollect.toString(),
    };
    console.log(data);

    axios
      .put(`/merchant-profile/${merchantData.website}`, data)
      .then((res) => {
        toast.success("Your datas have been updated successfully!");
      })

      .catch((error) => {
        toast.error(
          "there seems to have been an error creating an account. please try again later or contact support!"
        );
      });
  }

  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "VeritaTrust_x2023Upload_preset_name");

    fetch(`https://api.cloudinary.com/v1_1/drbhco8al/image/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error uploading image to Cloudinary");
        }
        return response.json();
      })
      .then((data) => {
        setProductImageUrl(data.secure_url);
      })
      .catch((error) => {
        console.error("Error uploading image to Cloudinary:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMerchantValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function handleSaveProfileSetting() {
    const data = {
      logo: productImageUrl,
      name: MerchantValues.name,
      website: MerchantValues.website
        .replace("https://", "")
        .replace("http://", ""),
      email: MerchantValues.email,
      phone: MerchantValues.phone,
      description: MerchantValues.description,
      city: MerchantValues.city,
      country_id: MerchantValues.country_id,
    };

    axios
      .put(`/merchant-profile/${merchantData.website}`, data)
      .then((res) => {
        toast.success("Your datas have been updated successfully!");
      })

      .catch((error) => {
        toast.error(
          "there seems to have been an error updating an account. please try again later or contact support!"
        );
      });
  }
  const copyToClipboard = () => {
    // Create a temporary textarea element

    const input = document.createElement("input");

    input.value = subscriptions.apiToken;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);

    // You can also provide feedback to the user that the content has been copied
    alert("Content copied to clipboard!");
  };

  return (
    <>
      <ToastContainer />

      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Settings page"}
            handleClick={handleClick}
          ></NavBar>
          <div class="accordion accordion-flush" id="setting">
            <section class="accordion-item">
              <h2 class="mb-0">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collect"
                  aria-expanded="true"
                  aria-controls="collect"
                >
                  <span className="material-symbols-sharp me-2">Reviews</span>
                  Collect reviews
                </button>
              </h2>
              <div
                id="collect"
                class="accordion-collapse collapse  p-3"
                data-bs-parent="#setting"
              >
                <h3 class="fs-5">{t("b2b.SettingPage.language")}</h3>
                <div class="form-floating mb-4">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    value={language}
                    onChange={handleSelectLanguageChange}
                  >
                    <option value="22">English</option>
                    <option value="27">French</option>
                    <option value="47">Italian</option>
                    <option value="20">Danish</option>
                    <option value="75">Portuguese</option>
                    <option value="89">Spanish</option>
                  </select>
                  <label for="floatingSelect">
                    {t("b2b.SettingPage.language_to_collect_reviews")}
                  </label>
                </div>
                <h3 class="fs-5"> {t("b2b.SettingPage.data_to_collect")}</h3>
                <div class="mb-4">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="product"
                      checked={productChecked}
                      onChange={handleProductChange}
                    />
                    <label class="form-check-label" for="product">
                      {t("b2b.SettingPage.products_reviews")}
                    </label>
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="mechant"
                      checked={merchantChecked}
                      onChange={handleMerchantChange}
                    />
                    <label class="form-check-label" for="mechant">
                      {t("b2b.SettingPage.merchant_reviews")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5"> {t("b2b.SettingPage.send_invitation")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      id="delay"
                      value={InvitationDelay}
                      onChange={handleSelectInviteDelay}
                      placeholder="7"
                    />
                    <label for="delay">
                      {t("b2b.SettingPage.delay_for_invite_days")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.invite_frequency")}</h3>
                <div class="form-floating mb-4">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    value={InviteFrequency}
                    onChange={handleSelectInviteFrequency}
                  >
                    <option value="0">{t("b2b.SettingPage.one_time")}</option>
                    <option value="1">{t("b2b.SettingPage.one_month")}</option>
                    <option value="2">{t("b2b.SettingPage.one_year")}</option>
                    <option value="3">{t("b2b.SettingPage.no_limit")}</option>
                  </select>
                  <label for="floatingSelect">
                    {t("b2b.SettingPage.language_to_collect_reviews")}
                  </label>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.reply_to")}</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-4">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control"
                          id="reply-mail"
                          placeholder="name@example.com"
                          value={EmailReplayTo}
                          onChange={handleEmailReplayChange}
                        />
                        <label for="reply-mail">
                          {t("b2b.SettingPage.reply_to_email_address")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control"
                          id="reply-mail"
                          placeholder="name@example.com"
                          value={SenderName}
                          onChange={handleSenderNameChange}
                        />
                        <label for="reply-mail">
                          {t("b2b.SettingPage.personalized_sender_name")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-success text-white"
                  onClick={handleSaveSetting}
                >
                  {t("b2b.SettingPage.save")}
                </button>
              </div>
            </section>
            <section className="accordion-item">
              <h2 class="mb-0">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#profile"
                  aria-expanded="false"
                  aria-controls="profile"
                >
                  <span class="material-symbols-sharp me-2">groups</span>
                  {t("b2b.SettingPage.public_profile")}
                </button>
              </h2>
              <div
                id="profile"
                class="accordion-collapse collapse p-3 personal-data"
                data-bs-parent="#setting"
              >
                <h3 className="fs-5">{t("b2b.SettingPage.company_logo")}</h3>
                <div className="userpicture mb-4">
                  <img
                    className="user-profil-avatar"
                    src={productImageUrl || merchantData.logo || avatar}
                    alt="user-avatar"
                  />
                  <label className="add-visual" id="userpicture">
                    <input
                      name="userpicture"
                      accept="image/jpeg, image/webp, image/png"
                      type="file"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.company_name")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      onChange={handleChange}
                      value={MerchantValues.name}
                      id="company_name"
                      placeholder="Ex: Amazon"
                    />
                    <label for="company_name">
                      {t("b2b.SettingPage.company_name")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.website_url")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="url"
                      name="website"
                      onChange={handleChange}
                      class="form-control"
                      value={MerchantValues.website}
                      id="website"
                      placeholder="Ex: httsp://www.amazon.com"
                    />
                    <label for="website">
                      {t("b2b.SettingPage.website_url")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">
                  {t("b2b.SettingPage.email_customer_service")}
                </h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      value={MerchantValues.email}
                      onChange={handleChange}
                      id="email"
                      placeholder="Ex: support@amazon.com"
                      disabled={subscriptions.subscriptionPlan == "0"}
                    />
                    <label for="email">
                      {t("b2b.SettingPage.email_customer_service")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.phone_support")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="tel"
                      class="form-control"
                      name="phone"
                      value={MerchantValues.phone}
                      id="phone"
                      placeholder="Ex: +33 (0)606060606"
                      onChange={handleChange}
                    />
                    <label for="phone">
                      {t("b2b.SettingPage.phone_support")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">
                  {" "}
                  {t("b2b.SettingPage.merchant_categories")}
                </h3>
                <div className="mb-4">
                  <CategorySettingComponent
                    webshopData={merchantData}
                    parentValue={parentState}
                  ></CategorySettingComponent>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.description")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <textarea
                      type="text"
                      class="form-control"
                      name="description"
                      value={MerchantValues.description}
                      onChange={handleChange}
                      id="description"
                      style={{ height: "138px" }}
                    />
                    <label for="description">
                      {t("b2b.SettingPage.description")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.local_address")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="adresses"
                      placeholder="Ex: 21 Rue De la fontaine"
                    />
                    <label for="adresses">
                      {t("b2b.SettingPage.local_address")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.city")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      name="city"
                      class="form-control"
                      value={MerchantValues.city}
                      id="city"
                      onChange={handleChange}
                      placeholder="Ex: Syndey"
                    />
                    <label for="city">{t("b2b.SettingPage.city")}</label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.country")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="country_id"
                      value={MerchantValues.country_id}
                      name="country_id"
                      onChange={handleChange}
                      placeholder="Ex: support@amazon.com"
                    />
                    <label for="country">{t("b2b.SettingPage.country")}</label>
                  </div>
                </div>
                <button
                  class="btn btn-success text-white btn-lg"
                  onClick={handleSaveProfileSetting}
                >
                  {t("b2b.SettingPage.save")}
                </button>
              </div>
            </section>
            <section class="accordion-item">
              <h2 class="mb-0">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#account"
                  aria-expanded="false"
                  aria-controls="account"
                >
                  <span class="material-symbols-sharp me-2">
                    manage_accounts
                  </span>
                  {t("b2b.SettingPage.account")}
                </button>
              </h2>
              <div
                id="account"
                class="accordion-collapse collapse p-3"
                data-bs-parent="#setting"
              >
                <h3 class="fs-5">{t("b2b.SettingPage.email_account")}</h3>
                <div class="mb-4">
                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control"
                      id="email_account"
                      placeholder="name@example.com"
                    />
                    <label for="email_account">
                      {t("b2b.SettingPage.email_account")}
                    </label>
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.your_password")}</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-4">
                      <div class="form-floating">
                        <input
                          type="password"
                          class="form-control"
                          id="password"
                          placeholder="name@example.com"
                        />
                        <label for="password">
                          {t("b2b.SettingPage.your_password")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <div class="form-floating">
                        <input
                          type="password"
                          class="form-control"
                          id="confirm_password"
                          placeholder="name@example.com"
                        />
                        <label for="confirm_password">
                          {t("b2b.SettingPage.confirm_your_password")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-check mb-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="privacylink"
                  />
                  <label class="form-check-label" for="privacylink">
                    {t("b2b.SettingPage.privacy_policy_link")}
                  </label>
                </div>

                <button class="btn btn-success text-white">
                  {t("b2b.SettingPage.save")}
                </button>
              </div>
            </section>
            <section class="accordion-item">
              <h2 class="mb-0">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#integration"
                  aria-expanded="false"
                  aria-controls="integration"
                >
                  <span class="material-symbols-sharp me-2">code</span>{" "}
                  {t("b2b.SettingPage.integration")}
                </button>
              </h2>
              <div
                id="integration"
                class="accordion-collapse p-3 collapse"
                data-bs-parent="#setting"
              >
                <h3 class="fs-5">{t("b2b.SettingPage.token_id")}</h3>
                <div class="mb-4">
                  <div class="input-group">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      id="button-copy"
                      onClick={() => copyToClipboard()}
                    >
                      Copy
                    </button>
                    <input
                      type="text"
                      class="form-control"
                      id="token_vt"
                      placeholder={t(
                        "b2b.SettingPage.insert_the_token_on_your_website"
                      )}
                      value={subscriptions.apiToken}
                      readonly=""
                    />
                  </div>
                </div>
                <h3 class="fs-5">{t("b2b.SettingPage.plugins")}</h3>
                <div class="d-lg-flex justify-content-around">
                  <div class="cms-link mx-auto mb-3 mx-lg-3">
                    <div class="card">
                      <img
                        src={
                          "https://dev.veritatrust.com/assets/img/cms/wordpress.png"
                        }
                        class="card-img-top p-3 img-fluid"
                        alt="Wordpress"
                      />
                      <div class="card-body">
                        <h5 class="card-title">
                          {t("b2b.SettingPage.word_press")}
                        </h5>
                        <p class="card-text mb-3">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a
                          href="https://b2b.veritatrust.com/api/download-woo-veritatrust-plugin"
                          class="btn btn-primary"
                        >
                          {t("b2b.SettingPage.download")}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="cms-link mx-auto mb-3 mx-lg-3">
                    <div class="card">
                      <img
                        src={
                          "https://dev.veritatrust.com/assets/img/cms/prestashop.png"
                        }
                        class="card-img-top p-3 img-fluid"
                        alt="Prestashop"
                        width={"10px"}
                      />
                      <div class="card-body">
                        <h5 class="card-title">
                          {t("b2b.SettingPage.prestashop")}
                        </h5>
                        <p class="card-text mb-3">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a
                          href="https://b2b.veritatrust.com/api/download-presta-veritatrust-plugin"
                          class="btn btn-primary"
                        >
                          {t("b2b.SettingPage.download")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="cms-link mx-auto mb-3 mx-lg-3">
                    <div class="card">
                      <img
                        src={
                          "https://dev.veritatrust.com/assets/img/cms/shopify.png"
                        }
                        class="card-img-top p-3 img-fluid"
                        alt="Shopify"
                      />
                      <div class="card-body">
                        <h5 class="card-title">
                          {t("b2b.SettingPage.shopify")}
                        </h5>
                        <p class="card-text mb-3">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        {/* <a href="#" class="btn btn-primary">
                          Download
                        </a> */}
                        <button
                          type="button"
                          className="btn btn-warning"
                          disabled
                        >
                          shortly
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="cms-link mx-auto mb-3 mx-lg-3">
                    <div class="card">
                      <img
                        src={
                          "https://dev.veritatrust.com/assets/img/cms/adobecommerce.png"
                        }
                        class="card-img-top p-3 img-fluid"
                        alt="Adobe commerce"
                      />
                      <div class="card-body">
                        <h5 class="card-title">
                          {t("b2b.SettingPage.adobe_commerce")}
                        </h5>
                        <p class="card-text mb-3">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        {/* <a href="#" class="btn btn-primary text-end">
                          Download
                        </a> */}
                        <button
                          type="button"
                          className="btn btn-warning"
                          disabled
                        >
                          shortly
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
}

export default SettingsPage;
