import React from "react";
import Modal from "react-modal";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import widget1 from "../../assets/Widget1.jpg";
import widget2 from "../../assets/Widget2.jpg";
import widget3 from "../../assets/Widget3.jpg";
import widget4 from "../../assets/Widget4.jpg";
import widget5 from "../../assets/Widget5.jpg";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/owl.carousel.css";

import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import WidgetResumeComponentBlock from "../../components/WidgetResumeComponentBlock";
import WidgetResumeComponentBlockStar from "../../components/WidgetResumeComponentBlockStar";
import WidgetReviewComponent from "../../components/WidgetReviewComponent";
import WidgetReviewComponent4 from "../../components/WidgetReviewComponent4";
import WidgetReviewComponent5 from "../../components/WidgetReviewComponent5";
import { useAxios } from "../../utils/hook/useAxios";

function ShowcasePublic() {
  const [isActive, setActive] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);
  const [templateid, setTemplateId] = useState(0);
  const [merchantData, setMerchantData] = useState({});
  const [reviews, setReviews] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [code, setCode] = useState("");

  const axios = useAxios();

  function handleClick() {
    setActive(!isActive);
  }
  function handleModalClick1() {
    setModalShow(true);
    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/www.amazon.fr`)
        .then((res) => {
          setMerchantData(res.data[0]);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick2() {
    setModalShow2(true);
    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/www.amazon.fr`)
        .then((res) => {
          setMerchantData(res.data[0]);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick3() {
    setModalShow3(true);
    axios.get("/").then((res) => {
      axios
        .get(`/organic-merchant-review/www.amazon.fr`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick4() {
    setModalShow4(true);
    axios.get("/").then((res) => {
      axios
        .get(`/organic-merchant-review/www.amazon.fr`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick5() {
    setModalShow5(true);
    axios.get("/").then((res) => {
      axios
        .get(`/organic-merchant-review/www.amazon.fr`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((error) => {});
    });
  }

  const getCodeAndCopy = (templateId) => {
    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/www.amazon.fr`)
        .then(function (response) {
          setCode(
            `<iframe class="widget-veritatrust" onload="resizeIframe(this)" title="Customer reviews powered by VeritaTrust" style="position: relative;  width: 100%; border-style: none; display: block; overflow: hidden;" src="https://widget.veritatrust.com/trustboxes/${templateId}?a=veritatrust&templateId=${templateId}&businessunitId=${response.data[0].id}"> </iframe>`
          );
          setTemplateId(templateId);
          setModalIsOpen(true);
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  };

  const copyToClipboard = (templateId) => {
    // Create a temporary textarea element

    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/www.amazon.fr`)
        .then(function (response) {
          setCode(
            `<iframe title="Customer reviews powered by VeritaTrust" onload="resizeIframe(this)" style="position: relative; width: 100%; border-style: none; display: block; overflow: hidden;" src="https://widget.veritatrust.com/trustboxes/${templateId}?a=veritatrust&templateId=${templateId}&businessunitId=${response.data[0].id}"> </iframe>`
          );

          const textarea = document.createElement("textarea");

          textarea.value = code;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);

          // You can also provide feedback to the user that the content has been copied
          alert("Content copied to clipboard!");
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  };

  const closeModal = () => {
    // Fermez le modal et réinitialisez le code
    setModalIsOpen(false);
  };
  const customStyles = {
    content: {
      width: "50%", // Ajustez la largeur selon vos besoins
      height: "50%", // Ajustez la hauteur selon vos besoins
      margin: "auto", // Centrez le modal horizontalement
    },
  };
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="container-fluid" ref={elementRef}>
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar proposTitle={"ShowCase"} handleClick={handleClick}></NavBar>
          <div className="reviews mb-4">
            <p className="lead mb-1">
              Showcasing your reviews will help reduce bounce rate, increase
              conversion rate and boost average cart.
            </p>
            <ol className="lead mb-0">
              <li>
                Select below the VeritaTrust widget that will automatically
                display reviews on your website.
              </li>
              <li>Copy/paste the generated code in your template.</li>
              <li>You're done!</li>
            </ol>
          </div>
          <section class="container mb-4">
            <h2 class="text-secondary">Freemium</h2>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget1} alt="" />
                  <h4 class="text-center mb-3">Widget 1</h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick1}
                    >
                      Show preview
                    </button>
                    <WidgetResumeComponentBlock
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      merchantData={merchantData}
                    />
                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(1)}
                    >
                      Get code
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget2} alt="" />
                  <h4 class="text-center mb-3">Widget 2</h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick2}
                    >
                      Show preview
                    </button>
                    <WidgetResumeComponentBlockStar
                      show={modalShow2}
                      onHide={() => setModalShow2(false)}
                      merchant={merchantData}
                    />
                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(2)}
                    >
                      Get code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="container mb-4">
            <h2 class="text-primary">Starter</h2>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget3} alt="" />
                  <h4 class="text-center mb-3">Widget 3</h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick3}
                    >
                      Show preview
                    </button>

                    <WidgetReviewComponent
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                      reviews={reviews}
                      merchantData={merchantData}
                    />

                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(3)}
                    >
                      Get code
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget4} alt="" />
                  <h4 class="text-center mb-3">Widget 4</h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick4}
                    >
                      Show preview
                    </button>
                    <WidgetReviewComponent4
                      show={modalShow4}
                      onHide={() => setModalShow4(false)}
                      reviews={reviews}
                      merchantData={merchantData}
                    />
                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(4)}
                    >
                      Get code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="container mb-4">
            <h2 class="text-success">Premium</h2>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget5} alt="" />
                  <h4 class="text-center mb-3">Widget 5</h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick5}
                    >
                      Show preview
                    </button>
                    <WidgetReviewComponent5
                      show={modalShow5}
                      onHide={() => setModalShow5(false)}
                      reviews={reviews}
                      merchantData={merchantData}
                    />
                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(5)}
                    >
                      Get code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Get code"
          style={customStyles}
        >
          <div>
            <h2>Your Code</h2>

            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control text-truncate"
                value={code}
                aria-describedby="button-copy"
                disabled
                readonly
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-copy"
                onClick={() => copyToClipboard(templateid)}
              >
                Copy
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ShowcasePublic;
