import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import {
  ACCESS_TOKEN,
  MERCHAND_ID,
  MERCHAND_WEBSITE,
  TVA_fr,
  URL_LINK_COMPANY_DETAILS,
  URL_LINK_PAYMENT_VALIDATION,
} from "../../config";
import { useAxios } from "../../utils/hook/useAxios";
function ChekoutConfirmData() {
  const [isActive, setActive] = useState(false);
  function handleClick() {
    setActive(!isActive);
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const axios = useAxios();

  const [TotalAmount, setTotalAmount] = useState(0);
  const [MontlyPrice, setMontlyPrice] = useState(
    parseFloat(searchParams.get("month_price")).toFixed(2)
  );
  const [Payment, setPayment] = useState(searchParams.get("payment"));
  const [ConcactCompany, setConcactCompany] = useState(null);
  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [MerchantWebsite, setMerchantWebsite] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).website
      : MERCHAND_WEBSITE
  );

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token
      ? window.localStorage.token || getCookie("jwtToken")
      : ACCESS_TOKEN
  );

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    setTotalAmount(parseFloat(searchParams.get("TotalAmount")).toFixed(2));

    (() => {
      axios
        .get("/subscriberaddress/" + MerchantID, AccessToken)

        .then((response) => {
          setConcactCompany(response.data);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const [CheckAccepted, setCheckAccepted] = useState(true);

  const packUpgrade = window.localStorage.getItem("PackUpgrade");

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Upgrade page"}
            handleClick={handleClick}
          ></NavBar>
          <div class="recent-reviews">
            <h2>Votre abonnement</h2>
            <div class="reviews">
              <div class="recent-reviews">
                <div class="col-11 col-lg-8 pb-3 mx-auto position-relative mb-3 collecting">
                  <ul class="step-reviews nav text-center ">
                    <li class="nav-item w-25 step-1 done">
                      <span class="badge rounded-circle">1</span>
                      <p class="mb-0" aria-current="page">
                        Select subscription
                      </p>
                    </li>
                    <li class="nav-item w-25 step-2 done">
                      <span class="badge rounded-circle">2</span>
                      <p class="mb-0" aria-current="page">
                        Add your contact details
                      </p>
                    </li>
                    <li class="nav-item w-25 step-3 active">
                      <span class="badge rounded-circle">3</span>
                      <p class="mb-0">Confirm your subscription</p>
                    </li>
                    <li class="nav-item w-25 step-4 ">
                      <span class="badge rounded-circle">4</span>
                      <p class="mb-0">Choose a payment method</p>
                    </li>
                  </ul>
                  <div id="line">
                    <div id="line-progress" style={{ width: "66%" }}></div>
                  </div>
                </div>
              </div>
              <div class="container mb-3 subscription-selector">
                <div class="row g-4">
                  <div class="col-lg-8">
                    <h5>Subscriber contact details</h5>
                    <hr />
                    <p class="lead mb-1">Your business</p>
                    <p>
                      <strong>{ConcactCompany?.business_name}</strong>
                    </p>
                    <p>
                      {ConcactCompany?.country}, {ConcactCompany?.local_address}{" "}
                      {ConcactCompany?.zip_code} {ConcactCompany?.city}
                    </p>
                    <p>Siren Number : {ConcactCompany?.siren_company}</p>
                    <p class="lead mb-1 mt-3">Subscriber contact details</p>
                    <p>
                      {ConcactCompany?.contact_first_name}{" "}
                      {ConcactCompany?.contact_last_name}
                    </p>
                    <p>{ConcactCompany?.contact_email}</p>
                    <p>{ConcactCompany?.contact_phone_number}</p>
                    <hr />
                    <p class="lead mb-1">Billing data</p>
                    <p>
                      The billing data is the same as that of the subscriber.
                    </p>
                    <hr />
                    <p class="lead mb-1">Terms and conditions</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkterms"
                        checked={CheckAccepted}
                        onChange={() => setCheckAccepted(!CheckAccepted)}
                      />
                      <label class="form-check-label" for="checkterms">
                        I accept The <a href="#">Terms of Use and Sale</a> for
                        Businesses
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card rounded-5">
                      <div class="card-body">
                        <div class="mb-3">
                          <h4 class=" mb-0">Subscription</h4>
                          <p class="">{MerchantWebsite}</p>
                        </div>
                        <div class="mb-3">
                          <p class="mb-0">
                            <strong>Plan</strong>
                          </p>
                          <p class="text-grey">{packUpgrade}</p>
                        </div>
                        <div class="mb-3">
                          <p class="mb-0">
                            <strong>Engagement</strong>
                          </p>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="text-grey me-3">
                              12 months | Pay{" "}
                              {Payment === "annual" ? "upfront" : "monthly"}
                            </p>
                            <p class="text-grey">
                              {parseFloat(TotalAmount).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div class="mb-3">
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="text-grey me-3">
                              Total for the subscription period
                            </p>
                            <p class="text-grey">
                              {(TotalAmount * (1 + TVA_fr)).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="text-grey me-3">TVA (20%)</p>
                            <p class="text-grey">
                              {(TotalAmount * TVA_fr).toFixed(2)} €
                            </p>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="d-flex justify-content-between align-items-center">
                            <h4 class="text-grey me-3">Due today</h4>
                            <h4 class="text-grey">
                              {(TotalAmount * (1 + TVA_fr)).toFixed(2)} €
                            </h4>
                          </div>
                        </div>
                        <div class="d-grid gap-2">
                          <Link
                            to={
                              URL_LINK_PAYMENT_VALIDATION +
                              "?month_price=" +
                              MontlyPrice +
                              "&TotalAmount=" +
                              TotalAmount +
                              "&payment=" +
                              Payment
                            }
                            class="btn btn-success rounded-5 text-white p-3"
                            disabled={true}
                          >
                            Next step
                          </Link>
                          <Link
                            to={
                              URL_LINK_COMPANY_DETAILS +
                              "?month_price=" +
                              MontlyPrice +
                              "&TotalAmount=" +
                              TotalAmount +
                              "&payment=" +
                              Payment
                            }
                            class="btn btn-primary rounded-5 text-white p-3"
                          >
                            Back step
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default ChekoutConfirmData;
