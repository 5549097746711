export const URL_LINK_SELECT_PACK =
  "https://b2b.veritatrust.com/checkout/select-subscription";

export const URL_LINK_COMPANY_DETAILS =
  "https://b2b.veritatrust.com/checkout/company-details";

export const URL_LINK_CONFIRMATION_DATA =
  "https://b2b.veritatrust.com/checkout/confirmation-data";

export const URL_LINK_PAYMENT_VALIDATION =
  "https://b2b.veritatrust.com/checkout/payment-validation";

export const URL_LINK_PAYMENT_CONFIRMATIOON =
  "https://b2b.veritatrust.com/checkout/payment-confirmation";

export const MONTHLY_PRICE_PREMIUM = 199;
export const MONTHLY_PRICE_STARTER = 99;

export const TVA_fr = 0.2;

export const ACCESS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmYXRhaG91YWhhbWFkaTg4QGdtYWlsLmNvbSIsImlhdCI6MTcxNzQxMjE0NX0.OdGxIXy0AqnrXel35n75k7vm_auh6S714vccOjk227U";

export const MERCHAND_ID = 1;

export const MERCHAND_WEBSITE = "www.amazon.fr";

export const SUBSCRIPTION_STRIPE_ID = "sub_1P0kpsEcnL0rILcwXAYJrnGC";

export const UPGRADE_URL_CLIENT = "https://b2b.veritatrust.com/upgrade";
export const PREMIUM_PRICE = "price_1P0hpMEcnL0rILcwVFMbAWbe";
export const STARTER_PRICE = "price_1P0hnbEcnL0rILcwFis4EaWz";
export const BASE_URL = "https://api.b2b.veritatrust.com";
