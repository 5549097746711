import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import moment from "moment";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import star1 from "../../assets/star-1.svg";
import star2 from "../../assets/star-2.svg";
import star3 from "../../assets/star-3.svg";
import star4 from "../../assets/star-4.svg";
import star5 from "../../assets/star-5.svg";
import "../../styles/owl.carousel.css";

function ReviewItemWidget({ review }) {
  const stars = [star1, star2, star3, star4, star5];
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <div className="swiper-slide p-4 d-flex flex-column">
        <header className="d-flex justify-content-between mb-3 ">
          <img
            src={stars[review?.rating - 1]}
            alt="Veritatrust"
            height="30"
            width="125"
          />
          <span className="published">
            {t("b2b.ShowcasePage.published_on")}
            <br /> {moment(review?.createdAt).format("MMM DD, YYYY")}{" "}
          </span>
        </header>
        <div className="content mb-2">
          <p className="text-primary">{review?.content}</p>
        </div>
        <div className="author text-primary">
          <p>
            {review?.nickName},{" "}
            {t("b2b.ShowcasePage.following_an_experience_on")}{" "}
            {moment(review?.experienceDate).format("MMM DD, YYYY")}{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default ReviewItemWidget;
