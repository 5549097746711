import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import logoPicto from "../../assets/logo-picto-veritatrust.png";

import "../../styles/flaticon_veritatrust.css";
import "../../styles/dashboard.css";

import "../../styles/owl.carousel.css";
import star1 from "../../assets/star-1.png";
import { useState } from "react";

function NavBar({ proposTitle, proposName, handleClick }) {
  const [darkMode, setDarkMode] = useState(false);

  const handleToggle = () => {
    setDarkMode(!darkMode);

    document.body.classList.toggle("dark-theme-variables");
  };

  return (
    <>
      <header className="d-flex mb-3">
        <div className="title">
          <h1>{proposTitle}</h1>
        </div>
        <div className="right ms-auto">
          <div className="top">
            <button id="menu-btn" onClick={handleClick}>
              <span className="material-symbols-sharp">menu</span>
            </button>
            <div className="logo">
              <img src={logoPicto} alt="Veritatrust" />
              <h2>
                Verita<span>Trust</span>
              </h2>
            </div>
            <div className="theme-toggler">
              <span
                className={
                  darkMode
                    ? "material-symbols-sharp"
                    : "material-symbols-sharp active"
                }
                onClick={handleToggle}
              >
                light_mode
              </span>
              <span
                className={
                  darkMode
                    ? "material-symbols-sharp active"
                    : "material-symbols-sharp"
                }
                onClick={handleToggle}
              >
                dark_mode
              </span>
            </div>

            <div className="profile">
              <div className="profile">
                <div className="info">
                  <p>
                    Bonjour, <strong>{proposName}</strong>
                  </p>
                  <small className="text-muted">Admin</small>
                </div>
                <div className="profile-photo">
                  <img src={star1} alt={proposName} width="40" height="40" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default NavBar;
