// import "../../styles/widgets.css";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import LogoVt from "../../assets/logo.png";
import star1 from "../../assets/star-1.svg";
import star2 from "../../assets/star-2.svg";
import star3 from "../../assets/star-3.svg";
import star4 from "../../assets/star-4.svg";
import star5 from "../../assets/star-5.svg";
import ReviewItemWidget from "../../components/ReviewItemWidget";
import "../../styles/owl.carousel.css";
import { useAxios } from "../../utils/hook/useAxios";

function WidgetsPage() {
  const [templateId, setTemplateId] = useState(null);
  const [businessunitId, setBusinessunitId] = useState(null);
  const [merchantData, setMerchantData] = useState({});
  const [reviews, setReviews] = useState([]);
  const axios = useAxios();
  const notes = ["Bad", "Passable", "Average", "Good", "Excellent"];
  const stars = [star1, star2, star3, star4, star5];
  const [code, setCode] = useState("");

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    variableWidth: false,
    centerMode: false,
    swipeToSlide: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);

    setTemplateId(urlParams.get("templateId"));

    // Move this inside the then block
    fetch(
      "https://api.veritatrust.com/v1/merchant_profiles/findmerchant/" +
        urlParams.get("businessunitId")
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("businessunitId", urlParams.get("businessunitId"));
        console.log("templateId", urlParams.get("templateId"));

        setBusinessunitId(urlParams.get("businessunitId"));
        setTemplateId(urlParams.get("templateId"));
        setMerchantData(data);
        setCode(`
         				<iframe title="Customer reviews powered by VeritaTrust" style="position: relative; height: 120px; width: 30%; border-style: none; display: block; overflow: hidden;" src="http://localhost:3000/trustboxes/${templateId}?a=veritatrust&templateId=${templateId}&businessunitId=${data.id}"> </iframe>`);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/organic-merchant-review/${merchantData.website}`)
      .then((res) => {
        setReviews(res.data);
      })
      .catch((error) => {});
  }, [, merchantData.id]);

  return (
    <>
      {/* Display widget 1 */}
      {templateId == 1 && (
        <div class="global_link d-flex align-items-center flex-column">
          <img src={LogoVt} alt="Veritatrust" height="39" width="200" />
          <img
            src={stars[Math.floor(merchantData?.ReviewMean)]}
            alt="reviews"
            height="50"
            width="190"
          />
          <div class="d-flex justify-content-center">
            <span class="reviews_number">
              <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
            </span>{" "}
            –{" "}
            <a href="#" target="_blank">
              <span class="reviews_number">
                <strong>{merchantData?.ReviewsNumber}</strong> reviews
              </span>
            </a>
          </div>
        </div>
      )}
      {/* Display widget 2 */}
      {templateId == 2 && (
        <div className="global_link d-md-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center">
            <img
              style={{ margin: "-7px 0 0 3px" }}
              src={LogoVt}
              alt="Veritatrust"
              height="29"
              width="150"
            />{" "}
            <p className="reviews_number m-0 me-2">
              <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
            </p>
          </div>
          <a
            href="#"
            className="d-flex justify-content-center align-items-center"
            target="_blank"
          >
            <p className="reviews_number m-0 ms-2">
              Read our <strong>{merchantData?.ReviewsNumber}</strong>
              reviews <strong>›</strong>
            </p>
          </a>
        </div>
      )}
      {/* Display widget 3 */}
      {templateId == 3 && (
        <div class="col-md-6 col-lg-4 mb-4">
          <div class="reviews position-relative">
            <section className="d-md-flex">
              <div class="first global_link d-flex align-content-center justify-content-center flex-column w-auto align-items-center">
                <img
                  style={{ margin: "-7px 0 0 3px" }}
                  src={LogoVt}
                  alt="Veritatrust"
                  height="29"
                  width="150"
                />

                <p class="mb-1">
                  <span class="reviews_number">
                    <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                  </span>{" "}
                  -{" "}
                  <span class="reviews_number">
                    {
                      notes[
                        Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1
                      ]
                    }
                  </span>
                </p>
                <a href="#" target="_blank">
                  <span class="reviews_number">
                    Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                    reviews
                  </span>
                </a>
                <span class="reviews_number small">
                  Trusted Reviews on the Blockchain
                </span>
              </div>
              <div class="carrousel w-75">
                <Slider {...settings}>
                  {reviews.map((review) => (
                    <ReviewItemWidget
                      key={review.id}
                      review={review}
                    ></ReviewItemWidget>
                  ))}
                </Slider>
              </div>
            </section>
          </div>
        </div>
      )}
      {/* Widget 4 display */}
      {templateId == 4 && (
        <div class="col-md-6 col-lg-4 mb-4">
          <div class="reviews position-relative">
            <section class="">
              <div class="first global_link d-flex align-content-center justify-content-center flex-column w-auto align-items-center mx-auto mb-4">
                <img
                  style={{ margin: "-7px 0 0 3px" }}
                  src={LogoVt}
                  alt="Veritatrust"
                  height="29"
                  width="150"
                />

                <p class="mb-1">
                  <span class="reviews_number">
                    <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                  </span>{" "}
                  -{" "}
                  <span class="reviews_number">
                    {" "}
                    {
                      notes[
                        Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1
                      ]
                    }
                  </span>
                </p>
                <a href="#" target="_blank">
                  <span class="reviews_number">
                    Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                    reviews
                  </span>
                </a>
                <span class="reviews_number small">
                  Trusted Reviews on the Blockchain
                </span>
              </div>

              <div class="carrousel">
                <Slider {...settings}>
                  {reviews.slice(0, 15).map((review) => (
                    <ReviewItemWidget
                      key={review.id}
                      review={review}
                    ></ReviewItemWidget>
                  ))}
                </Slider>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default WidgetsPage;
