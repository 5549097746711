import React from "react";
import { Link } from "react-router-dom";
import {
  MONTHLY_PRICE_PREMIUM,
  MONTHLY_PRICE_STARTER,
  URL_LINK_SELECT_PACK,
} from "../../config";

function PackItems2({ ActualPack }) {
  return (
    <>
      <div className="container mb-3 price-comparator">
        <div className="row g-0">
          <div className="row justify-content-center align-items-top g-3 mb-4">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="border p-3 rounded-4 h-100 pricing-table">
                <h3 className="h2">Enterprise</h3>
                <p>
                  Our most advanced 100% customizable review solution to plug to
                  every brand or business.
                </p>
                <p>
                  <span className="lead">Custom/Contact Us</span>
                  <br />
                  <span className="text-muted">Yearly payment</span>
                </p>
                {ActualPack === "3" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "3" ? "Your pack" : "Choose Enterprise"}
                  </button>
                ) : (
                  <Link
                    to={URL_LINK_SELECT_PACK}
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "3" ? "Your pack" : "Choose Enterprise"}
                  </Link>
                )}
                <ul className="list-unstyled pricing mt-3 ms-0 d-none d-lg-block">
                  <li>
                    Monthly review<span className="d-block lead">3.000+</span>
                  </li>
                  <li>
                    Email invite custom<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Customer support
                    <span className="d-block lead">
                      Email, Phone, Chat & Callback
                    </span>
                  </li>
                  <li>
                    Invite from past customers
                    <span className="d-block lead">180 days</span>
                  </li>
                  <li>
                    Dashboard
                    <span className="d-block lead">Deeper data + API</span>
                  </li>
                  <li>
                    Q&A on product page<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Local reviews<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Multi-user & domain<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Net Promoter Score<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Google Shopping & SEO Review Widget
                    <span className="d-block lead">Yes</span>
                  </li>
                </ul>
                {ActualPack === "3" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "3" ? "Your pack" : "Choose Enterprise"}
                  </button>
                ) : (
                  <Link
                    to={URL_LINK_SELECT_PACK}
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "3" ? "Your pack" : "Choose Enterprise"}
                  </Link>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="border p-3 rounded-4 h-100 pricing-table recommended">
                <span className="badge bg-success">Recommended</span>
                <h3 className="h2">Premium</h3>
                <p>
                  Build a strong 360° UGC & customer review strategy and get
                  useful insights.
                </p>
                <p>
                  <span className="lead">From 199€</span>/month
                  <br />
                  <span className="text-muted">Yearly payment</span>
                </p>
                {ActualPack === "2" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "2" ? "Your pack" : "Choose Premium"}
                  </button>
                ) : (
                  <Link
                    to={
                      URL_LINK_SELECT_PACK +
                      "?month_price=" +
                      MONTHLY_PRICE_PREMIUM +
                      "&pack=PREMIUM"
                    }
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "2" ? "Your pack" : "Choose Premium"}
                  </Link>
                )}
                <ul className="list-unstyled pricing mt-3 ms-0 d-none d-lg-block">
                  <li>
                    Monthly review<span className="d-block lead">1.000</span>
                  </li>
                  <li>
                    Email invite custom<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Customer support
                    <span className="d-block lead">
                      Email, Phone, Chat & Callback
                    </span>
                  </li>
                  <li>
                    Invite from past customers
                    <span className="d-block lead">180 days</span>
                  </li>
                  <li>
                    Dashboard<span className="d-block lead">Deeper data</span>
                  </li>
                  <li>
                    Q&A on product page<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Local reviews<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Multi-user & domain
                    <span className="d-block lead">Yes (2 max.)</span>
                  </li>
                  <li>
                    Net Promoter Score<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Google Shopping & SEO Review Widget
                    <span className="d-block lead">Yes</span>
                  </li>
                </ul>
                {ActualPack === "2" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "2" ? "Your pack" : "Choose Premium"}
                  </button>
                ) : (
                  <Link
                    to={
                      URL_LINK_SELECT_PACK +
                      "?month_price=" +
                      MONTHLY_PRICE_PREMIUM +
                      "&pack=PREMIUM"
                    }
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "2" ? "Your pack" : "Choose Premium"}
                  </Link>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="border p-3 rounded-4 h-100 pricing-table">
                <h3 className="h2">Starter</h3>
                <p>
                  Customize emails & display enhanced review widget to boost
                  your conversion & sales.
                </p>
                <p>
                  <span className="lead">From 99€</span>/month
                  <br />
                  <span className="text-muted">Yearly payment</span>
                </p>
                {ActualPack === "1" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "1" ? "Your pack" : "Choose Starter"}
                  </button>
                ) : (
                  <Link
                    to={
                      URL_LINK_SELECT_PACK +
                      "?month_price=" +
                      MONTHLY_PRICE_STARTER +
                      "&pack=STARTER"
                    }
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "1" ? "Your pack" : "Choose Starter"}
                  </Link>
                )}
                <ul className="list-unstyled pricing mt-3 ms-0 d-none d-lg-block">
                  <li>
                    Monthly review<span className="d-block lead">500</span>
                  </li>
                  <li>
                    Email invite custom<span className="d-block lead">Yes</span>
                  </li>
                  <li>
                    Customer support
                    <span className="d-block lead">Email & Phone</span>
                  </li>
                  <li>
                    Invite from past customers
                    <span className="d-block lead">120 days</span>
                  </li>
                  <li>
                    Dashboard<span className="d-block lead">Basic data</span>
                  </li>
                  <li>
                    Q&A on product page<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Local reviews<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Multi-user & domain<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Net Promoter Score<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Google Shopping & SEO Review Widget
                    <span className="d-block lead">Yes</span>
                  </li>
                </ul>
                {ActualPack === "1" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "1" ? "Your pack" : "Choose Starter"}
                  </button>
                ) : (
                  <Link
                    to={
                      URL_LINK_SELECT_PACK +
                      "?month_price=" +
                      MONTHLY_PRICE_STARTER +
                      "&pack=STARTER"
                    }
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "1" ? "Your pack" : "Choose Starter"}
                  </Link>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="border p-3 rounded-4 h-100 pricing-table">
                <h3 className="h2">Free</h3>
                <p>
                  Easily collect reviews and increase brand awareness and trust.
                </p>
                <p>
                  <span className="lead">0€</span>/month
                  <br />
                  <span className="text-muted">Totally free</span>
                </p>
                {ActualPack === "0" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "0" ? "Your pack" : "Choose Freemium"}
                  </button>
                ) : (
                  <Link
                    to={URL_LINK_SELECT_PACK}
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "0" ? "Your pack" : "Choose Freemium"}
                  </Link>
                )}
                <ul className="list-unstyled pricing mt-3 ms-0 d-none d-lg-block">
                  <li>
                    Monthly review<span className="d-block lead">200</span>
                  </li>
                  <li>
                    Email invite custom<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Customer support
                    <span className="d-block lead">Email only</span>
                  </li>
                  <li>
                    Invite from past customers
                    <span className="d-block lead">90 days</span>
                  </li>
                  <li>
                    Dashboard<span className="d-block lead">Basic data</span>
                  </li>
                  <li>
                    Q&A on product page<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Local reviews<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Multi-user & domain<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Net Promoter Score<span className="d-block lead">No</span>
                  </li>
                  <li>
                    Google Shopping & SEO Review Widget
                    <span className="d-block lead">No</span>
                  </li>
                </ul>
                {ActualPack === "0" ? (
                  <button
                    className="btn btn-success btn-lg w-100 text-white"
                    disabled={true}
                  >
                    {ActualPack === "0" ? "Your pack" : "Choose Freemium"}
                  </button>
                ) : (
                  <Link
                    to={URL_LINK_SELECT_PACK}
                    className="btn btn-success btn-lg w-100 text-white"
                  >
                    {ActualPack === "0" ? "Your pack" : "Choose Freemium"}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackItems2;
