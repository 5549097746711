import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import logoVt from "../../assets/logo.png";
import "../../styles/owl.carousel.css";

import ReviewItemWidget2 from "../ReviewItemWidget2";

function WidgetReviewComponent5(props) {
  const { reviews, merchantData } = props; // Extract merchant from props

  const notes = ["Bad", "Passable", "Average", "Good", "Excellent"];

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("b2b.ShowcasePage.widget")} 5
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section class="container">
            <div class="row mb-3">
              <div class="first d-flex align-content-center justify-content-center flex-column mx-auto">
                <img src={logoVt} alt="Veritatrust" height="29" width="150" />
                <p class="mb-1">
                  <span class="reviews_number">
                    <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                  </span>{" "}
                  -{" "}
                  <span class="reviews_number">
                    {" "}
                    {
                      notes[
                        Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1
                      ]
                    }
                  </span>
                </p>
                <a href="#" target="_blank">
                  <span class="reviews_number">
                    {t("b2b.ShowcasePage.read_our")}{" "}
                    <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                    {t("b2b.ShowcasePage.reviews")}
                  </span>
                </a>
                <span class="reviews_number small">
                  {t("b2b.ShowcasePage.trusted_reviews_on_blockchain")}
                </span>
              </div>
            </div>
            <p class="text-end text-muted">
              {t("b2b.ShowcasePage.Our_45_star_reviews")}
            </p>
            <div class="row mansory" data-masonry='{"percentPosition": true }'>
              {reviews.slice(0, 15).map((review) => (
                <ReviewItemWidget2
                  key={review.id}
                  review={review}
                ></ReviewItemWidget2>
              ))}
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("b2b.ShowcasePage.close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WidgetReviewComponent5;
