import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import LogoVt from "../../assets/logo.png";
import star1 from "../../assets/star-1.svg";
import star2 from "../../assets/star-2.svg";
import star3 from "../../assets/star-3.svg";
import star4 from "../../assets/star-4.svg";
import star5 from "../../assets/star-5.svg";
import "../../styles/owl.carousel.css";

function WidgetResumeComponentBlockStar(props) {
  const { merchant } = props; // Extract merchant from props
  const stars = [star1, star2, star3, star4, star5];

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("b2b.ShowcasePage.widget")} 2
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="global_link d-flex align-items-center flex-column">
            <img src={LogoVt} alt="Veritatrust" height="39" width="200" />
            <img
              src={stars[Math.floor(merchant?.ReviewMean)]}
              alt="reviews"
              height="50"
              width="190"
            />
            <div class="d-flex justify-content-center">
              <span class="reviews_number">
                <strong>{merchant?.ReviewMean?.toFixed(1)}</strong>/5
              </span>{" "}
              –{" "}
              <a href="#" target="_blank">
                <span class="reviews_number">
                  <strong>{merchant?.ReviewsNumber}</strong>{" "}
                  {t("b2b.ShowcasePage.reviews")}
                </span>
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("b2b.ShowcasePage.close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WidgetResumeComponentBlockStar;
