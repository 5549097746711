export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_NB_REVIEWS = "SET_NB_REVIEWS";
export const SET_RM = "SET_RM";
export const SET_SHARED_VALUE = "SET_SHARED_VALUE";
export const SET_MERCHANT_PROFILE_VALUE = "SET_MERCHANT_PROFILE_VALUE";
export const SET_TOTAL_REWARDS = "SET_TOTAL_REWARDS";

export const setNbReviews = (nbReviews) => ({
  type: SET_NB_REVIEWS,
  nbReviews,
});

export const setRM = (rm) => ({
  type: SET_RM,
  rm,
});

export const setSharedValue = (value) => ({
  type: SET_SHARED_VALUE,
  value,
});

export const setMerchantProfileValue = (merchantProfile) => ({
  type: SET_MERCHANT_PROFILE_VALUE,
  merchantProfile,
});

export const setTotalRewards = (totalReward) => ({
  type: SET_TOTAL_REWARDS,
  totalReward,
});
