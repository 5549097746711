import axios from "axios";
import React, { createContext, useContext } from "react";

const axiosContext = createContext();
const baseUrl = "https://api.b2b.veritatrust.com/v1";

export function ProvideAxios({ children }) {
  const axios = useProvideAxios();
  return (
    <axiosContext.Provider value={axios}>{children}</axiosContext.Provider>
  );
}

export const useAxios = () => {
  return useContext(axiosContext);
};

function useProvideAxios() {
  const post = async (url, data, token) => {
    //  setHeader();

    try {
      return await axios.post(baseUrl + url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true, // Important pour envoyer les cookies
      });
    } catch (error) {
      console.log(error);
    }
  };

  const get = async (url, token) => {
    //    setHeader();
    try {
      return await axios.get(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true, // Important pour envoyer les cookies
      });
    } catch (error) {
      console.log(error);
    }
  };

  const put = async (url, data, token) => {
    //    setHeader();
    try {
      return await axios.put(baseUrl + url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true, // Important pour envoyer les cookies
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    post,
    get,
    put,
  };
}
