import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import {
  ACCESS_TOKEN,
  BASE_URL,
  MERCHAND_ID,
  PREMIUM_PRICE,
  STARTER_PRICE,
  URL_LINK_PAYMENT_CONFIRMATIOON,
} from "../../config";
import { useAxios } from "../../utils/hook/useAxios";

function SubscriptionForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const axios = useAxios();

  const packSubscription = window.localStorage.getItem("PackUpgrade");
  const [SubscriptionPlan, setSubscriptionPlan] = useState(0);
  const [TransactionID, setTransactionID] = useState(null);
  const [Created, setCreated] = useState(null);
  const [SubscriptionData, setSubscriptionData] = useState(null);
  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token ? window.localStorage.token : ACCESS_TOKEN
  );

  useEffect(() => {
    axios
      .get("/subscriptions/" + MERCHAND_ID, AccessToken)

      .then((response) => {
        setSubscriptionData(response.data);
      })

      .catch((error) => console.log(error));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const responsePayment = await fetch(
        `${BASE_URL}/v1/create-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            email: "louisfatah23@gmail.com",
            SubscriptionForfait:
              packSubscription === "PREMIUM" ? PREMIUM_PRICE : STARTER_PRICE,
            subscriptionId: SubscriptionData?.StripeSubscriptionId,
          }),
        }
      );
      if (responsePayment.ok) {
        const subscriptionData = await responsePayment.json();
        setTransactionID(subscriptionData.id);
        setCreated(subscriptionData.created);
        // console.log("Données d'abonnement :", subscriptionData.id);
        // console.log("Données d'abonnement :", subscriptionData.created);

        // Gérer la réussite de la souscription
        if (packSubscription === "starter") {
          let monthlyReview = 500;
          let EmailInviteCustom = true;
          let CustomSupport = "1";
          let InviteFromPastCustomers = "1";
          let dashboard = "0";
          let QandAProductPage = false;
          let LocalReviews = false;
          let MultiUserDomain = "0";
          let NetPromoterScore = false;
          let GoogleShoppingSEOReviewWidget = true;
          let expirationDate = new Date(
            CaclulateExpirationDate().toDateString()
          );

          axios
            .post(
              "/subscriptions",
              {
                MonthlyReview: monthlyReview,
                EmailInviteCustom: EmailInviteCustom,
                CustomerSupport: CustomSupport,
                InviteFromPastCustomers: InviteFromPastCustomers,
                dashboard: dashboard,
                QandAonProductPage: QandAProductPage,
                LocalReviews: LocalReviews,
                MultiUserDomain: MultiUserDomain,
                NetPromoterScore: NetPromoterScore,
                GoogleShoppingSEOReviewWidget: GoogleShoppingSEOReviewWidget,
                expirationDate: expirationDate,
                status: "1",
                merchandId: MerchantID,
                subscriptionPlan: "1",
                apiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ989JXDGGHGJHU",
                StripeSubscriptionId: subscriptionData.id,
              },
              AccessToken
            )
            .then((response) => {
              window.location.href =
                URL_LINK_PAYMENT_CONFIRMATIOON +
                window.location.search +
                "&transactionID=" +
                subscriptionData.id +
                "&PaymentDate=" +
                subscriptionData.created;
            })
            .catch((error) => console.log(error));
        } else if (packSubscription === "PREMIUM") {
          let monthlyReview = 1000;
          let EmailInviteCustom = true;
          let CustomSupport = "2";
          let InviteFromPastCustomers = "2";
          let dashboard = "1";
          let QandAProductPage = true;
          let LocalReviews = true;
          let MultiUserDomain = "1";
          let NetPromoterScore = true;
          let GoogleShoppingSEOReviewWidget = true;
          let expirationDate = new Date(
            CaclulateExpirationDate().toDateString()
          );

          axios
            .post(
              "/subscriptions",
              {
                MonthlyReview: monthlyReview,
                EmailInviteCustom: EmailInviteCustom,
                CustomerSupport: CustomSupport,
                InviteFromPastCustomers: InviteFromPastCustomers,
                dashboard: dashboard,
                QandAonProductPage: QandAProductPage,
                LocalReviews: LocalReviews,
                MultiUserDomain: MultiUserDomain,
                NetPromoterScore: NetPromoterScore,
                GoogleShoppingSEOReviewWidget: GoogleShoppingSEOReviewWidget,
                expirationDate: expirationDate,
                status: "1",
                merchandId: MerchantID,
                subscriptionPlan: "2",
                apiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ989JXDGGHGJHU",
                StripeSubscriptionId: subscriptionData.id,
              },
              AccessToken
            )
            .then((response) => {
              window.location.href =
                URL_LINK_PAYMENT_CONFIRMATIOON +
                window.location.search +
                "&transactionID=" +
                subscriptionData.id +
                "&PaymentDate=" +
                subscriptionData.created;
            })
            .catch((error) => console.log(error));
        }
      } else {
        // Gérer les erreurs de souscription
      }
    }

    setLoading(false);
  };

  function CaclulateExpirationDate() {
    const urlParams = new URLSearchParams(window.location.search);

    const payment = urlParams.get("payment");

    if (SubscriptionData?.subscriptionPlan !== "0" && SubscriptionData) {
      if (payment === "annual") {
        const nextExpirationDate = SubscriptionData
          ? new Date(SubscriptionData.expirationDate)
          : new Date();

        nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 12);
        return nextExpirationDate;
      } else {
        const nextExpirationDate = SubscriptionData
          ? new Date(SubscriptionData.expirationDate)
          : new Date();
        nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 1);
        return nextExpirationDate;
      }
    } else {
      if (payment === "annual") {
        const nextExpirationDate = new Date();

        nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 12);
        return nextExpirationDate;
      } else {
        const nextExpirationDate = new Date();

        nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 1);
        return nextExpirationDate;
      }
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <hr />
      <CardElement />
      <hr />
      <button
        className="btn btn-primary text-white mt-3 col-md-3 w-100"
        type="submit"
        disabled={!stripe || loading}
      >
        {loading ? "Loading..." : "Subscribe"}
      </button>
    </form>
  );
}

export default SubscriptionForm;
