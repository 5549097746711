import { combineReducers } from "redux";
import {
  SET_CURRENT_USER,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  SET_SHARED_VALUE,
  SET_RM,
  SET_NB_REVIEWS,
  SET_MERCHANT_PROFILE_VALUE,
  SET_TOTAL_REWARDS,
} from "./actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
  sharedValue: 0,
  propsNbReviews: 0,
  propsRM: "",
  merchantProfileValue: {},
  totalRewards: 4,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: null,
      };

    case LOGIN_SUCCESS:
      return { ...state, user: action.user, isAuthenticated: true };
    // other cases...

    case SET_SHARED_VALUE:
      return {
        ...state,
        sharedValue: action.value,
      };
    case SET_NB_REVIEWS:
      return {
        ...state,
        propsNbReviews: action.nbReviews,
      };
    case SET_RM:
      return {
        ...state,
        propsRM: action.rm,
      };

    case SET_MERCHANT_PROFILE_VALUE:
      return {
        ...state,
        merchantProfileValue: action.merchantProfile,
      };

    case SET_TOTAL_REWARDS:
      return {
        ...state,
        totalRewards: action.totalReward,
      };

    default:
      return state;
  }
};

export default combineReducers({
  auth: authReducer,
});
