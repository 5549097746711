import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../styles/owl.carousel.css";

function TermsConditions(props) {
  // Extract merchant from props
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>{" "}
        <Modal.Body>
          {" "}
          <h2>Terms and conditions for business</h2> <h3>Introduction</h3>
          <p>
            VeritaTrust’s platform enables businesses to connect with customers,
            gather feedback, and gain valuable insights – providing tools to
            enhance consumer relationships. We aim to be a beacon of credibility
            in the digital realm, with our platform grounded in transparency and
            cooperation among businesses and consumers. Prior to utilizing our
            services, kindly familiarize yourself with these terms of use and
            sale for businesses (hereinafter referred to as the “terms”) to
            understand our mutual expectations.
          </p>
          <p>
            Whether you opt for VeritaTrust&rsquo;s complimentary offerings or
            our premium services, you must accept these terms. This ensures
            clarity about your legal rights and responsibilities, as well as
            ours. You&rsquo;re granted access to and use of our services only if
            you accept and adhere to these terms. Hence, if you disagree or are
            unable to abide by any stipulation, please refrain from accessing or
            using our services. For clarity, any engagement with our platform,
            including through free trials, is subject to these terms.
          </p>
          <p>
            Your agreement to these terms can be signified by any of the
            following actions: (a) marking a checkbox or pressing a button
            indicating your acceptance (or a similar affirmation); (b) signing
            or acknowledging a quotation, order form, or related document
            referencing these terms; (c) remitting or acknowledging a bill for a
            subscription to our premium offerings; or (d) claiming a business
            profile or availing any of our services.
          </p>
          <p>
            For queries about VeritaTrust, consider exploring our Support Hub.
            If your questions remain unanswered, feel free to contact us at
            support@veritatrust.com.
          </p>
          <p>
            Please note, terms delineated within “quotation marks” hold
            consistent meanings throughout this document.
          </p>
          <p>
            Accessing and Engaging with VeritaTrust Here&rsquo;s how you can
            access our services and sign up for premium offerings:
          </p>
          <ol>
            <li aria-level="1">
              <b>Defining Parties</b>: Whenever “you” or “your” is mentioned, it
              refers to the business entity you represent. “VeritaTrust”, “we”,
              “our”, or “us” pertains to the VeritaTrust entity you engage with,
              as specified in the Our contracting entities and governing law
              section. The term “affiliate” denotes any entity or firm in
              relation to a party, which has control, is controlled, or shares
              common control, either directly or indirectly, with said party.
            </li>
            <li aria-level="1">
              <b>Our Platform</b>: By “platform”, we refer to the feedback
              platform hosted at _________, including its sub-domains, related
              websites, or applications maintained by us.
            </li>
            <li aria-level="1">
              <b>Our Offerings</b>: Our “services” encompass the VeritaTrust
              business account, feedback solutions, and other services presently
              available or introduced later. This is further outlined in
              documents like quotes, order forms, bills, or other commercial
              communications provided to you or associated partners or displayed
              on our platform (“commercial materials”). Pertinent considerations
              for our services include:
              <ul>
                <li aria-level="2">
                  Compatibility of our services and platform with your browser,
                  network, or computer infrastructure isn&rsquo;t assured.
                </li>
                <li aria-level="2">
                  Our offerings do not incorporate any third-party products.
                </li>
              </ul>
            </li>
            <li aria-level="1">
              <b>Business Profile Page</b>: To access our services, either you
              or a representative must claim a business profile page on our
              platform (occasionally referred to as a company profile page).
            </li>
            <li aria-level="1">
              <b>Free Plan</b>: By securing a business profile page, you gain
              access to a business account, which enables the use of all
              services encompassed in our complimentary plan. You can utilize
              these services until such a time as your business account is
              either removed by you or access is revoked by us.
            </li>
            <li aria-level="1">
              <b>Subscriptions</b>: If you opt for any services not part of our
              complimentary plan (termed a “subscription”), you&rsquo;ll have
              access to these via your business account. The subsequent
              conditions apply:
              <ul>
                <li aria-level="2">
                  <b>Subscription Duration</b>: While most subscriptions are
                  valid for 12 months, the specific tenure of your chosen
                  subscription (“subscription period”) is detailed in the
                  commercial materials provided or shown to you during your
                  subscription purchase.
                </li>
                <li aria-level="2">
                  <b>Subscription Renewals</b>: Upon each subscription
                  period&rsquo;s conclusion, your subscription is automatically
                  renewed for another equivalent period unless a different
                  arrangement is mutually agreed upon, or either party opts for
                  termination — details are in the Termination and suspension
                  section. Absent subscription renewal, you retain access to our
                  free plan services.
                </li>
                <li aria-level="2">
                  <b>Pricing for Renewals</b>: We commit to notifying you a
                  minimum of 45 days in advance of any changes to the
                  undiscounted price of your subscription. This will be
                  effective from the subsequent renewal of your subscription
                  period. Do note that any promotional discounts may conclude
                  upon subscription renewal.
                </li>
              </ul>
            </li>
            <li aria-level="1">
              <b>Partners</b>: It&rsquo;s possible to access our services and
              purchase a subscription via a VeritaTrust partner. The term
              “partner” denotes an independent entity granted authorization by
              VeritaTrust to redistribute or resell our services through diverse
              partner programs. Please be aware that these partners function
              entirely autonomously and lack the authority to make contractual
              commitments or guarantees on our behalf. Even if you&rsquo;re
              availing our services via a partner, these terms remain binding.
              However, any agreement specific to you and your partner (like
              specialized services rendered by the partner or third-party
              products procured from them) won&rsquo;t be incorporated into
              these terms. Partner-handled subscription renewals may require you
              to liaise directly with the partner for any queries. Should there
              be any payment discrepancies from you or the partner regarding the
              subscription, we reserve the right to curtail or terminate your
              services access — further details in the Termination and
              suspension section.
            </li>
            <li aria-level="1">
              <b>Domains</b>: You assure that either you or an affiliate
              possesses or retains exclusive rights to the domain(s) for which
              our systems and services are used. If a domain utilized with our
              systems and services is controlled by your affiliate, it&rsquo;s
              understood that you have the requisite authority to endorse these
              terms concerning said domain.
            </li>
            <li aria-level="1">
              <b>Sending Invitations via VeritaTrust Platform</b>: If you
              utilize our review invitation function, you&rsquo;re perceived as
              the initiator of every invitation, not VeritaTrust. Thus, ensuring
              the compliance of all dispatched invitations (whether dispatched
              directly by you or on your behalf by VeritaTrust) with all
              pertinent legal, regulatory prerequisites, and guidelines is
              solely your responsibility.
            </li>
          </ol>
          <p>
            You guarantee that every invitation’s content adheres to all
            applicable laws and regulations. You&rsquo;ll possess all necessary
            permissions, rights, and consents to dispatch your invitations,
            abiding by the applicable privacy laws (as detailed in the Privacy
            laws section of these terms). For instance, in several regions,
            review-invite emails may be considered unsolicited marketing
            requiring prior customer consent. Depending on regional laws, you
            may need to acquire such consent. Therefore, you agree not to
            utilize our invitation service without acquiring necessary
            permissions.
          </p>
          <p>
            10.<b> Inviting Consumers Outside VeritaTrust</b>: If you present or
            forward review invitations outside our platform, like independently
            crafting and dispatching review links or directing customers to post
            reviews on your business profile, you acknowledge the use of our
            services. Ensuring these invitations satisfy all applicable legal
            and regulatory prerequisites and guidelines is entirely your
            responsibility.
          </p>
          <p>
            <b>11. Business Account Management</b>: You bear sole responsibility
            for overseeing account access and administration, along with the use
            of our services. Specifically:
          </p>
          <ul>
            <li aria-level="1">
              You regulate access, determining which users can access the
              services via your account and the type of access they hold. This
              can be adjusted or revoked as needed.
            </li>
            <li aria-level="1">
              You’re accountable for the actions of all authorized users, their
              service use, and adherence to VeritaTrust&rsquo;s terms and
              guidelines.
            </li>
            <li aria-level="1">
              You commit to maintaining updated information, including a current
              email.
            </li>
            <li aria-level="1">
              Ensuring provided information&rsquo;s accuracy and completeness is
              your responsibility.
            </li>
            <li aria-level="1">
              You&rsquo;re responsible for safeguarding your credentials against
              theft or misuse.
            </li>
            <li aria-level="1">
              While you may permit affiliates to use our services under these
              terms, ensuring their compliance is your responsibility, and
              you&rsquo;re liable for their actions as if they were your own.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <b>12. User Roles &amp; Access</b>: Ensure clarity about permissions
            granted to authorized users. Each added user is governed by these
            terms and guidelines. Their actions are your liability. It&rsquo;s
            imperative that they’re familiarized with these terms and our
            guidelines. For more about user roles and access levels, visit our
            Support Center.
          </p>
          <p>
            <b>13. Your Core Responsibilities</b>: Use our services exclusively
            for domains with business profiles claimed by or for you and in
            congruence with your subscription, if applicable. Commit to using
            our services for legal business intentions, aligning with these
            terms and our guidelines.
          </p>
          <p>
            <b>14. Guidelines</b>: Engaging with our services implies your
            commitment to adhere to our guidelines, policies, and codes
            available on __________ designated for businesses and general users
            (including any other references in these terms). Familiarize
            yourself with these to understand acceptable usage. A lot is rooted
            in common sense, aiming to uphold our platform&rsquo;s integrity.
            Periodic guideline updates might occur, and such revisions apply to
            you immediately without further formal acceptance.
          </p>
          <p>
            <b>15. Ownership Distinctions</b>: Everything incorporated into our
            services, barring content owned by third parties such as reviews, is
            our property. These reviews belong to the reviewers who penned them,
            and any data you supply for inviting consumers is yours. Our
            ownership extends to our services&rsquo; design, appearance, and
            copyrighted materials, along with trademarks (both registered and
            unregistered), designs, code, and other intellectual assets.
            You&rsquo;re committed to refraining from replicating,
            redistributing, altering, or producing derivatives from our content,
            or utilizing our intellectual properties unless explicitly
            sanctioned by us. Hence, our brand marks, like our logos and any
            content on VeritaTrust are off-limits unless specifically
            authorized.
          </p>
          <p>
            Your trademarks, logos, brand name, and other intellectual assets («
            customer IP ») belong to you. We&rsquo;re granted permission to use
            this customer IP for administering our services, platform, and
            related systems, and also to execute our commitments under these
            terms.
          </p>
          <p>
            <b>16. User-Generated Content</b>: Any review, response, image, or
            any content derived from you, any consumer, or our platform&rsquo;s
            user is classified as « user-generated content ». If you&rsquo;re
            the source of such content, you grant us perpetual, unrestricted
            access to utilize it without any due compensation to you. Plus, you
            confirm your right to grant us this permission. Except when
            user-generated content is deleted due to a breach of our guidelines
            or by its creator, it remains visible on our platform and
            third-party services, even post subscription termination or account
            deletion.
          </p>
          <p>
            <b>17. Feedback</b>: We highly value your insights on our services
            and reserve the right to use them indefinitely without any
            obligations or remuneration to you. Explicitly, any content or
            feedback you provide can be utilized, quoted, or referenced by
            VeritaTrust as we see fit.
          </p>
          <p>
            <b>18. VeritaTrust Beta Services</b>: Occasionally, we might grant
            access to preliminary services or features, such as beta services,
            which are yet to be formally introduced. Given their nature, if you
            opt to engage with them, you do so entirely at your own discretion
            and risk.
          </p>
          <p>
            <b>19. Support and Resolutions</b>: Facing an issue? Our Support
            Center is equipped with articles that should assist you in most
            scenarios. If the Support Center doesn&rsquo;t suffice, more details
            on online support can be accessed by contacting our support team at
            ________. Depending on your subscribed services, our Customer
            Success team might also be available for further guidance.
          </p>
          <p>
            <b>20. Impartiality Principle</b>: We&rsquo;re thrilled to serve as
            a bridge between businesses and their consumers. However, your
            utilization of VeritaTrust and our brand marks does not indicate our
            endorsement or approval of your products/services. Hence, it&rsquo;s
            imperative that you refrain from marketing or publicly asserting
            otherwise. Nothing in these terms indicates a partnership,
            employment, joint venture, or agency association between you and
            VeritaTrust.
          </p>
          <p>
            <b>21. Display of Names, Logos, and Reviews</b>:
          </p>
          <p>
            Your visibility on VeritaTrust is crucial for us. Thus, likened to a
            directory, you authorize us to showcase your business name and logo
            on our platform to represent the services or products you offer. We
            grant you autonomy over your business profile page, empowering you
            to alter your name and logo as needed.
          </p>
          <p>
            Moreover, upon account creation and service utilization, you grant
            us permission to employ your name, logo, and demonstrations of your
            VeritaTrust usage in the public sphere (e.g., on your website or
            ads) within routine business promotional and marketing endeavors.
          </p>
          <p>
            In the course of our services, you may exhibit reviews and our brand
            marks on your claimed domains, contingent upon adhering to our
            guidelines, inclusive of the guidance available on our Support
            Center. This is on the premise that you utilize designs, widgets,
            images, and functionalities available in your business account.
          </p>
          <p>
            Any other appropriation or display of our brand marks or content is
            permissible solely according to our guidelines. We hold the
            authority to retract your brand mark usage rights should it breach
            our guidelines.
          </p>
          <p>
            <b>22. Prohibitions</b>: It&rsquo;s challenging to cover every
            possible scenario, but here are some pivotal actions you&rsquo;re
            strictly forbidden from undertaking:
          </p>
          <ul>
            <li aria-level="1">
              Jeopardize our platform&rsquo;s security or integrity.
            </li>
            <li aria-level="1">
              Utilize our platform in ways that deter its normal functioning or
              impede others&rsquo; user experience.
            </li>
            <li aria-level="1">
              Unauthorized access to our platform or services.
            </li>
            <li aria-level="1">
              Introduce or upload malicious codes or viruses to our services.
            </li>
            <li aria-level="1">
              Craft, submit, or commission fabricated reviews.
            </li>
            <li aria-level="1">
              Engage in deceitful, unlawful, offensive acts, or acts that
              violate others&rsquo; rights or breach our guidelines.
            </li>
            <li aria-level="1">
              Manipulate, duplicate, adapt, dismantle, reverse engineer, or try
              to deduce our platform&rsquo;s source code.
            </li>
            <li aria-level="1">
              Resell, transfer, license, or provide our services not as
              specified in our guidelines.
            </li>
            <li aria-level="1">
              Repurpose, resell, or sublicense data accessed through
              VeritaTrust.
            </li>
            <li aria-level="1">
              Commit illicit acts or fraud via our platform.
            </li>
            <li aria-level="1">
              Interact disrespectfully or abusively with any VeritaTrust
              representative, partner, user, or client. We have zero tolerance
              towards mistreatment of our team, inclusive of our support staff.
            </li>
            <li aria-level="1">
              Erase any VeritaTrust content, proprietary notifications, or
              labels.
            </li>
            <li aria-level="1">
              Employ our services or materials for any competitive offerings
              against VeritaTrust.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            <b>23. Third-party Integrations</b> To enhance your VeritaTrust
            experience, we incorporate connections to products and solutions
            provided by external entities, such as e-commerce platforms.
          </p>
          <p>
            <b>24. External Integrations</b>: Recognizing that you might wish to
            link our platform with third-party products, we, or your associated
            partner, facilitate integrations to such external solutions. These
            may include e-commerce platforms or software vendors. Usage of these
            third-party products is governed by their respective terms and not
            ours. Bear in mind that these third-party providers might charge you
            separately from what you pay to VeritaTrust. Also, we&rsquo;re not
            committed to ongoing support for these third-party products and
            can&rsquo;t assure continuous compatibility between them and our
            platform.
          </p>
          <p>
            <b>25. Third-party Terms and Descriptions</b>: Products from third
            parties are bound by their individual terms, conditions, and privacy
            policies. It&rsquo;s your duty to familiarize yourself with these.
            The data you share with these providers will be governed by their
            terms. While we aim to present accurate descriptions and links given
            by third-party providers, they are solely accountable for their
            accuracy. We neither endorse nor are we liable for any third-party
            products, regardless of how they&rsquo;re labelled (e.g., «
            certified », « recommended »). Your interaction with these products
            is at your discretion and risk. Any disputes related to these
            third-party products should be resolved directly with the respective
            provider and not VeritaTrust.
          </p>
          <p>
            <b>26. Subscription and Payment Models</b> Access to VeritaTrust
            services might necessitate payment, except for our complimentary
            plan or trial period. Specific costs and terms will be detailed at
            the time of purchase.
          </p>
          <p>
            <b>27.Evaluation Period</b>: We might grant you temporary access to
            certain VeritaTrust services. These trials may be terminated or
            altered at our discretion without prior notification or liability.
            Post-trial, should you wish to continue using our services, a
            subscription will be needed.
          </p>
          <p>
            <b>28. VeritaTrust Plans</b>: We offer a free plan with a broad
            spectrum of features. However, premium features require one of our
            varied subscription plans. Typically, payments are made directly to
            VeritaTrust, but if acquired through a partner, they would handle
            your subscription billing. Prices can differ based on location. Any
            alterations to your subscription, like adding domains or services,
            might result in updated terms and potential price adjustments.
          </p>
          <p>
            <b>29. Promotions</b>: Promotional prices or discounts are valid
            only for the mentioned subscription duration and are not guaranteed
            for subsequent renewals.
          </p>
          <p>
            <b>30. Refund Policy</b>: Except explicitly stated in our terms, we
            don&rsquo;t offer refunds or credits upon subscription termination.
          </p>
          <p>
            <b>31. Taxation</b>: All VeritaTrust prices are exclusive of taxes.
            You&rsquo;re accountable for all related taxes or fees stemming from
            your use of our services, including VAT, if applicable in the
            Netherlands.
          </p>
          <p>
            <b>32. Payment Timelines</b>: It&rsquo;s crucial to honour the
            payment schedule associated with your chosen subscription. Invoices
            should be settled within the stipulated timeframe. Ensure your
            payment details are updated with us or with the partner. Delays or
            non-payment might lead to service suspension. In case of overdue
            payments, we reserve the right to impose interest on the outstanding
            sum. Collection fees and legal expenses, if incurred, will be borne
            by you.
          </p>
          <p>
            <b>33. Contract Length</b>: You can cancel your subscription package
            with VeritaTrust at any time subject to the following provisions by
            giving 30 days’ written notice of cancellation. We will continue to
            bill you for the ongoing service until we receive written
            confirmation and this can be done by sending an email to _________.
            Billing on the account begins once the account is active.
          </p>
          <p>
            VeritaTrust reserve the right to continue to commercially exploit
            display reviews written on VeritaTrust notwithstanding a client’s
            cancelled subscription.
          </p>
          <p>
            <b>34. Indemnity: </b>Clients shall indemnify VeritaTrust against
            any loss or damage suffered or incurred by VeritaTrust as a result
            of any third party claim (including any claim or allegation by any
            governmental authority) that:
          </p>
          <ul>
            <li aria-level="1">
              the use of any content provided by Client infringes the
              intellectual property rights of a third party and/or violates
              applicable law or the Guidelines;
            </li>
            <li aria-level="1">
              VeritaTrust use of Client data in accordance with this Agreement
              is in breach of the Data Protection Requirements or any other
              applicable laws related to data privacy; or
            </li>
            <li aria-level="1">
              any email message sent or caused to be sent by VeritaTrust on
              behalf Client violates any applicable law, rule or regulation.
            </li>
            <li aria-level="1">
              Client shall not bring any claim against VeritaTrust arising from
              or related to any User Content, including without limitation, any
              claim that the User Content is defamatory, offensive or otherwise
              harmful. Client shall indemnify VeritaTrust against any loss or
              damage suffered or incurred by VeritaTrust as a result of any such
              claim, whether such claim is brought by the Client, any of
              Client&rsquo;s affiliates, or any of its or their officers,
              directors, employees, contractors, agents, shareholders, or other
              associated third parties.
            </li>
          </ul>
          <p>
            <b>35. Limitation Of Liability: </b>Neither party excludes any Loss
            in respect of personal injury or death, fraudulent misrepresentation
            or any other Loss that may not be lawfully excluded or limited under
            English law.
          </p>
          <p>
            Neither party shall be liable for any loss of profits or revenues,
            loss of business opportunity, loss of goodwill or reputation, loss
            of data or any indirect, consequential loss or special damages.
          </p>
          <p>
            You agree that without prejudice to the preceding provisions the
            aggregate liability of all of the corporate entities trading as
            VeritaTrust their officers and employees shall not exceed the sum of
            £100 (one hundred pounds sterling) or the amount of your annual
            subscription whichever is greater.
          </p>
          <p>
            Nothing in this clause 32 shall operate to limit liability for death
            or personal injury or for fraud.
          </p>
          <ul>
            <li aria-level="1">
              <b>Safeguarding Your Information: </b>To protect your information,
              our credit card processing vendor uses the latest 128-bit Secure
              Socket Layer (SSL) technology for secure transactions. Our vendor
              is certified as compliant with card association security
              initiatives, like the Visa Cardholder Information Security and
              Compliance (CISP), MasterCard (SDP).
            </li>
          </ul>
          <p>
            VeritaTrust accounts require a username and password to log in. You
            must keep your username and password secure, and never disclose it
            to a third party. Because the information in your Distribution Lists
            is so sensitive, account passwords are encrypted, which means we
            can&rsquo;t see your passwords. We can&rsquo;t resend forgotten
            passwords either. We&rsquo;ll only reset them.
          </p>
          <ul>
            <li aria-level="1">
              <b>Personal Information: </b>In connection with the use of the
              services, we collect user data when users register with
              VeritaTrust. This data is stored in the Netherland and_____ will
              not be passed to a 3rd party without prior consent.
            </li>
          </ul>
          <p>
            To ensure that users get the best possible experience from using the
            website, we process information about search behaviour and other
            user behaviour on the website, including the total number of visits
            to the website. The information will, however, not be processed in a
            way that can trace the behaviour to specific users.
          </p>
          <p>
            Cookies are used on the website to monitor what parts of the website
            the user&rsquo;s browser has previously visited and enables us to
            adjust the website&rsquo;s content accordingly. However, we do not
            process information, gathered by cookies in this manner, that can
            connect the user behaviour with specific users.
          </p>
          <p>
            Our processing of personal data is carried out in compliance with
            laws relating to the collection and processing of personal data in
            the jurisdictions in which we operate. You warrant that you have
            read and agree to be bound by the terms of our Data Processing
            Agreement which can be found at _______.<b> </b>Registered users can
            contact us if they request information about what data that are
            processed about them or if they wish to have the information deleted
            or rectified. Furthermore, registered users can at any time withdraw
            their consent, by giving notice to _______. Withdrawal of consent
            will be considered as a request to be deleted as a registered user.
          </p>
          <p>
            We have initiated a number of technical and organisational
            arrangements to ensure that personal information that is being
            processed is not deleted, and does not disappear, deteriorate or
            become misappropriated by unauthorised parties.
          </p>
          <p>
            We may use account registration information and other personal
            information of Registered Users collected in connection with their
            use of our services as described in our Privacy Policy, as updated
            from time to time which shall apply and form part of this
            Agreement.VeritaTrust is not responsible for and assumes no
            liability for Registered Users&rsquo; conduct on or use of the
            VeritaTrust service, including the content published by the
            reviewers (« User Content »). VeritaTrust does not and cannot
            control or monitor the User Content and VeritaTrust does not endorse
            any User Content, nor does the opinions expressed in the User
            Content represent the opinions of VeritaTrust, its affiliates, or
            any of its or their officers, directors, employees, contractors or
            shareholders.
          </p>
          <p>
            The Agreement shall not be regarded as VeritaTrust&rsquo;s approval,
            endorsement or recommendation of any Registered User’s products or
            services. No Registered User may market itself or make any public
            representations to the contrary.
          </p>
          <p>
            Registered Users’ use of the VeritaTrust Service must at all times
            comply with all applicable laws, rules and regulations. You warrant
            to VeritaTrust that your use of the VeritaTrust Service will in no
            way cause VeritaTrust to violate any applicable laws, rules or
            regulations or to violate the rights of any third party.
          </p>
          <p>
            When a Registered User signs up for an account, a password to the
            account is created. This password may only be used by and for that
            Registered User . You as Registered User agree that you are
            personally responsible for all contributions to the VeritaTrust
            service occurring using your account ID.
          </p>
          <p>
            You should be aware that when writing a review your name and/or
            email address will still be visible to the company being reviewed
            via the client dashboard even if you have elected to publish
            anonymously (if that company uses VeritaTrust Business Services).
          </p>
          <p>
            If you have verified your review by adding social media information
            this will also be visible in the client&rsquo;s dashboard. We may
            store &amp; display publicly visible social media profile
            information including follower numbers and social media handles.
          </p>
          <ul>
            <li aria-level="1">
              <b>DISCLAIMER: </b>You agree that VeritaTrust does not produce or
              publish contributions on the website and that VeritaTrust cannot
              be held accountable for the reviews and comments made on the
              website. If you would like to remove a review or discuss an issue
              with the website, please email ______
            </li>
          </ul>
          <p>
            You agree that VeritaTrust has no responsibility for the accuracy or
            completeness of contributions published by registered users on the
            website.
          </p>
          <p>
            VeritaTrust does not read through or edit contribution added to the
            website of registered users, and can in no way be held responsible
            for the content of these contributions. Should the contributions
            contain links to third parties, VeritaTrust will accept no
            responsibility for the contents of the website to which you are
            directed by the link in question.
          </p>
          <p>
            VeritaTrust recommendations and reference is guidance only and you
            agree that VeritaTrust can in no way be held responsible if the
            recommendation turns out to be unsatisfactory.
          </p>
          <p>
            VeritaTrust makes no warranty or representation express or implied
            about website availability and/or business interruption and you
            agree that it can in no circumstances be held responsible for any
            technical failure, market interruptions or downtime in connection
            with any loss of revenue, loss of goodwill or similar losses.
          </p>
          <p>
            VeritaTrust reserves the right to update and revise these terms at
            any time, as well as the right to close the website for any reason
            without warning.
          </p>
          <p>
            We expressly reserve the right to delete reviews which we suspect
            rightly or wrongly in our complete discretion are shill reviews or
            have been planted or solicited externally.Reviews solicited outside
            of the VeritaTrust ecosystem may be flagged and moderated.
          </p>
          <ul>
            <li aria-level="1">
              <b>CONFIDENTIALITY: </b>During the term of this Agreement, either
              party (as the “Disclosing Party“) may disclose or make available
              to the other party (as the “Receiving Party“) information about
              its business affairs, products/services, confidential intellectual
              property, trade secrets, third-party confidential information and
              other sensitive or proprietary information, whether orally or in
              written, electronic, or other form or media, and whether or not
              marked, designated, or otherwise identified as “confidential”
              (collectively, “Confidential Information“). Confidential
              Information shall not include information that, at the time of
              disclosure: (i) is or becomes generally available to and known by
              the public other than as a result of, directly or indirectly, any
              breach of this Section 17 by the Receiving Party or any of its
              Representatives, (ii) is or becomes available to the Receiving
              Party on a non-confidential basis from a third-party source,
              provided that such third party is not and was not prohibited from
              disclosing such Confidential Information, (iii) was known by or in
              the possession of the Receiving Party or its representatives
              before being disclosed by or on behalf of the Disclosing Party,
              (iv) was or is independently developed by the Receiving Party
              without reference to or use, in whole or in part, of any of the
              Disclosing Party’s Confidential Information; or (v) is required to
              be disclosed under applicable federal, state or local law,
              regulation, or a valid order issued by a court or governmental
              agency of competent jurisdiction.
            </li>
          </ul>
          <p>
            The Receiving Party shall: (A) protect and safeguard the
            confidentiality of the Disclosing Party’s Confidential Information
            with at least the same degree of care as the Receiving Party would
            protect its own Confidential Information, but in no event with less
            than a commercially reasonable degree of care, (B) not use the
            Disclosing Party’s Confidential Information, or permit it to be
            accessed or used, for any purpose other than to exercise its rights
            or perform its obligations under this Agreement; and (C) not
            disclose any such Confidential Information to any person or entity,
            except to the Receiving Party’s Representatives who need to know the
            Confidential Information to assist the Receiving Party, or act on
            its behalf, to exercise its rights or perform its obligations under
            the Agreement.
          </p>
          <p>
            The provisions of this Section 36 shall survive the natural
            expiration or termination of this Agreement for any reason for a
            period of three (3) years.
          </p>
          <ul>
            <li aria-level="1">
              <b>GOVERNING LAW AND DISPUTES: </b>This agreement is governed by
              and shall be construed in accordance with the laws of the
              Netherlands. If you reside outside the Netherlands, you agree to
              submit to the exclusive jurisdiction of the courts in the
              Netherlands. If you reside in the Netherlands or your business is
              located in the Netherlands, you agree to arbitrate any claim,
              cause of action, or dispute between you and us that arises out of
              or relates to the use of our services. The arbitration will be
              governed by the rules of arbitration of the Netherlands
              Arbitration Institute (« NAI »), as modified by these Terms, and
              will be administered by the NAI. If the NAI is unavailable, the
              parties will agree to another arbitration provider or request that
              the NAI nominate a substitute arbitrator. Each party will be
              responsible for paying any NAI filing, administrative, and
              arbitrator fees in accordance with the NAI rules. If you do not
              wish to be bound by this provision, you must notify us within 30
              days of the first acceptance date of any version of these terms
              containing an arbitration provision. Your notice to us must be
              submitted to the registered office of VeritaTrust in the
              Netherland, which is ____________
            </li>
            <li aria-level="1">
              <b>GENERAL</b>
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              If any provision of this Agreement is held to be unenforceable,
              such provision shall be reformed only to the extent necessary to
              make it enforceable and if such provision is determined to be
              invalid or unenforceable nonetheless, the provision shall be
              deemed to be severable from the remainder of the Agreement and
              shall not cause the invalidity or unenforceability of the
              remainder of the Agreement.
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              Client acknowledges and agrees that VeritaTrust has the right, at
              any time and for any reason, to redesign or modify the VeritaTrust
              Materials and other elements of the Platform or any part thereof.
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              This Agreement constitutes the entire agreement between Client and
              VeritaTrust respect to the subject matter herein and supersedes
              and replaces any prior or contemporaneous understandings and
              agreements, whether written or oral, including previous
              non-disclosure agreements between the parties. VeritaTrust may
              update this Agreement from time to time. We will provide prior
              written notice of any material updates at least thirty (30) days
              in advance of the effective date; provided however, VeritaTrust
              may not be able to provide at least thirty (30) days prior written
              notice of material updates to this Agreement that result from
              changes in law, regulation, or requirements, directly or
              indirectly from telecommunications providers.Except as otherwise
              specified by VeritaTrust, updates will be effective upon the
              effective date indicated. The updated version of this Agreement
              will supersede all prior versions. Following such notice, Client’s
              continued use of the Services and Platform on or after the
              effective date of the updated Agreement constitutes Client’s
              acceptance of such updated Agreement. If you do not agree to the
              updated version of this Agreement , you must stop using the
              Services and Platform immediately.
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              No failure, delay or default in performance of any obligation of a
              party (other than a payment obligation) shall constitute an event
              of default or breach of this Agreement to the extent that such
              failure to perform, delay or default arises out of a cause,
              existing or future, that is beyond the control of such party,
              including action or inaction of governmental, civil or military
              authority; fire; strike, lockout or other labor dispute; flood,
              terrorist act; war; riot; theft; epidemic, pandemic or quarantine;
              earthquake, tornado or other natural disaster; failure or
              diminishment of power or telecommunications or data networks or
              services; distributed denial of service (DDoS) or other
              cyberattacks; or refusal of a license by a government agency. The
              party affected by such cause shall take all reasonable actions to
              minimize the consequences of any such cause.
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              Neither party may assign this Agreement without the prior written
              consent of the other party, not to be unreasonably withheld,
              provided that either party may assign or transfer this Agreement,
              without the prior written consent of the other party, to an
              affiliate or in connection with a sale or merger of all or
              substantially all of the assigning party’s business or assets. Any
              unauthorized assignment will be void and of no force or effect.
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              Except as expressly stated herein, nothing in this Agreement shall
              be considered as granting any rights to third parties. The failure
              of either party to enforce any rights granted hereunder or to take
              action against the other party in the event of any breach
              hereunder shall not be deemed a waiver by that party as to
              subsequent enforcement of rights or subsequent actions in the
              event of future breaches.
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              All waivers must be in writing. Any waiver or failure to enforce
              any provision of this Agreement on one occasion will not be deemed
              a waiver of any other provision or of such provision on any other
              occasion.
            </li>
          </ul>
          <p>&nbsp;</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsConditions;
