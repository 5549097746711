import * as formik from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import "react-notifications/lib/notifications.css";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import logoBusinessVeritatrust from "../../assets/logo-veritatrust-business.png";
import CreateWebshopInput from "../../components/CreateWebshopInput";
import TermsConditions from "../../components/TermsConditions";

import { useTranslation } from "react-i18next";

import { useAxios } from "../../utils/hook/useAxios";

const clientRedirect = {
  facebook: "https://b2b.veritatrust.com/api/auth/facebook",
  google: "https://b2b.veritatrust.com/api/auth/google",
};

function LogupPage() {
  const axios = useAxios();

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [countryName, setCountryName] = useState("usa");

  const [darkMode, setDarkMode] = useState(false);
  const [somePassword, setSomePassword] = useState(true);

  const [alerts, setAlerts] = useState([]);
  const [webshop, setWebshop] = useState(null);
  const [merchantID, setmerchantID] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  function handleModalClick() {
    setModalShow(true);
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);
  const [newMessage, setNewMessage] = useState({
    success:
      "Your account has been successfully created, an email will be sent to confirm your account!",
    warning:
      "It seems that this email is already used for another account. Please log in!",
    danger:
      "there seems to have been an error creating an account. please try again later or contact support!",
  });

  const handleWebshopSelection = (webshop) => {
    setWebshop(webshop.website);
    setmerchantID(webshop.id);
  };

  const handleToggle = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("dark-theme-variables");
  };

  const { Formik } = formik;

  const schema = yup.object().shape({
    enterprise: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    job: yup.string().required(),
    state: yup.string(),
    password: yup.string().min(8, "Must be 8 characters or more").required(),
    confirmpassword: yup
      .string()
      .required()
      .test("passwords-match", "Passwords do not match", function (value) {
        return this.parent.password === value;
      }),
    email: yup.string().required().email(),
    phone: yup.string().required(),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  const handleChangeCountry = async (event) => {
    // Make the fetch request
    setCountryName(event.target.value);
    try {
      const response = await fetch(
        `https://api.veritatrust.com/v1/data/countries?q=${event.target.value}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCountryCode(data[0].phone_code);
      console.log("country code: ", data[0].phone_code);
      console.log(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm) {
      axios
        .get(`/data/countries?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
          setCountryCode(response.data[0].phone_code);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const onSubmit = (data) => {
    console.log(data); // Display form data in the console
    const merchantuser = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      corporate_name: data.enterprise,
      website: webshop,
      merchant_id: merchantID,
      country: countryName,
      phoneNumber: countryCode + data.phone,
      jobTitle: data.job,
    };

    const fetchAuthStatus = async () => {
      try {
        const response = await axios.post("/data/user-data", merchantuser);
        console.log(response);

        if (response.status === 200) {
          window.location.href = "https://b2b.veritatrust.com/";

          toast.success(
            "Your account has been successfully created, an email will be sent to confirm your account!"
          );
        } else if (response.status === 400) {
          toast.warning(
            "It seems that this email is already used for another account. Please log in!"
          );
        }
      } catch (error) {
        toast.error(
          "there seems to have been an error creating an account. please try again later or contact support!"
        );
      }
    };

    fetchAuthStatus();
  };

  const onDismissed = useCallback((alert) => {
    setAlerts((alerts) => {
      const idx = alerts.indexOf(alert);
      if (idx < 0) return alerts;
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)];
    });
  }, []);

  return (
    <>
      <ToastContainer />

      <header className="bg-primary login d-flex mb-3  w-100">
        <img
          className="my-2 mx-auto"
          src={logoBusinessVeritatrust}
          alt="veritaTrust business"
          width="180"
        />

        {/* <button className="btn btn-success text-light" onClick={handleToggle}>
          {darkMode ? "Mode Clair" : "Mode Sombre"}
        </button> */}
        <div className="right">
          <div className="theme-toggler text-light me-5 mt-3">
            <span
              className={
                darkMode
                  ? "material-symbols-sharp"
                  : "material-symbols-sharp active"
              }
              onClick={handleToggle}
            >
              light_mode
            </span>
            <span
              className={
                darkMode
                  ? "material-symbols-sharp active"
                  : "material-symbols-sharp"
              }
              onClick={handleToggle}
            >
              dark_mode
            </span>
          </div>
        </div>
      </header>
      <div className="container">
        <main className="login">
          <div className="row align-items-center">
            <div className="col-lg-8 mx-auto">
              <div className="reviews login-panel p-5">
                <h2 className="mb-3 text-center">
                  {t("b2b.Signup.collect_free_reviews_register_now")}
                </h2>
                <div className="d-grid gap-2">
                  <a
                    className="btn btn-primary btn-lg"
                    href={clientRedirect.facebook}
                  >
                    {t("b2b.Signup.sign_up_with_facebook")}
                  </a>
                  <a
                    className="btn btn-primary btn-lg"
                    href={clientRedirect.google}
                  >
                    {t("b2b.Signup.sign_up_with_google")}
                  </a>
                </div>
                <div className="orbymail text-center">
                  <div className="separator"> {t("b2b.Signup.or")}</div>
                </div>
                <h4> {t("b2b.Signup.sign_up_with_email")}</h4>

                <Formik
                  validationSchema={schema}
                  onSubmit={onSubmit}
                  initialValues={{
                    enterprise: "",
                    firstName: "",
                    lastName: "",
                    job: "",
                    state: "",
                    password: "",
                    confirmpassword: "",
                    terms: false,
                    email: "",
                    phone: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} className="">
                      <CreateWebshopInput
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleSelectWebshop={handleWebshopSelection}
                      />

                      <Form.Group
                        controlId="validationFormikEnterprise"
                        className="mt-3"
                      >
                        {/* <Form.Label>Enterprise </Form.Label> */}
                        <FloatingLabel
                          controlId="floatingEnterpriseInput"
                          label={t("b2b.Signup.business_name")}
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="enterprise"
                            value={values.enterprise}
                            onChange={handleChange}
                            placeholder={t("b2b.Signup.business_name")}
                            isValid={touched.enterprise && !errors.enterprise}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        controlId="validationFormik03"
                        className="mt-3"
                      >
                        <FloatingLabel
                          controlId="floatingFisrtnameInput"
                          label={t("b2b.Signup.first_name")}
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            placeholder={t("b2b.Signup.first_name")}
                            isValid={touched.firstName && !errors.firstName}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="validationFormik03"
                        className="mt-3"
                      >
                        <FloatingLabel
                          controlId="floatingLastnameInput"
                          label={t("b2b.Signup.last_name")}
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            placeholder={t("b2b.Signup.last_name")}
                            isValid={touched.lastName && !errors.lastName}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="validationFormik03"
                        className="mt-3"
                      >
                        <FloatingLabel
                          controlId="floatingJobtitleInput"
                          label={t("b2b.Signup.job_title")}
                          className="mb-1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="job title"
                            name="job"
                            value={values.job}
                            onChange={handleChange}
                            isInvalid={!!errors.job}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.job}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="validationFormik03"
                        className="mt-3"
                      >
                        <InputGroup hasValidation>
                          <FloatingLabel
                            controlId="floatingInput"
                            label={t("b2b.Signup.email_address")}
                            className=""
                          >
                            <Form.Control
                              type="text"
                              placeholder={t("b2b.Signup.email_address")}
                              aria-describedby="inputGroupPrepend"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              isInvalid={!!errors.email}
                            />
                          </FloatingLabel>
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group
                        controlId="validationFormik05"
                        className="mt-3"
                      >
                        <FloatingLabel
                          controlId="floatingPasswordInput"
                          label={t("b2b.Signup.password")}
                          className="mb-1"
                        >
                          <Form.Control
                            type="password"
                            placeholder={t("b2b.Signup.password")}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        controlId="validationFormik051"
                        className="mt-3"
                      >
                        <FloatingLabel
                          controlId="floatingPasswordInput"
                          label={t("b2b.Signup.confirm_password")}
                          className="mb-1"
                        >
                          <Form.Control
                            type="password"
                            placeholder={t("b2b.Signup.confirm_password")}
                            name="confirmpassword"
                            value={values.confirmpassword}
                            onChange={handleChange}
                            isInvalid={!!errors.confirmpassword}
                            feedback={errors.confirmpassword}
                            feedbackType="invalid"
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmpassword}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Row>
                        <Form.Group
                          controlId="validationFormik04"
                          as={Col}
                          md="5"
                          className="mt-3"
                        >
                          <FloatingLabel
                            controlId="floatingCountryInput"
                            label={t("b2b.Signup.country")}
                            className="mb-3"
                          >
                            <Form.Select
                              name="state" // Set the name for Formik to track
                              value={countryName}
                              onChange={handleChangeCountry}
                              isInvalid={!!errors.state}
                            >
                              <option value="" disabled>
                                {t("b2b.Signup.select_country")}
                              </option>
                              <option value="usa">United States</option>
                              <option value="canada">Canada</option>
                              <option value="uk">United Kingdom</option>
                              <option value="australia">Australia</option>
                              <option value="germany">Germany</option>
                              <option value="france">France</option>
                              <option value="italia">Italy</option>
                              <option value="spanish">Spain</option>
                            </Form.Select>
                          </FloatingLabel>
                          <Form.Control.Feedback type="invalid">
                            {errors.country}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="7"
                          controlId="validationCustomUsername"
                          className="mt-3"
                        >
                          <InputGroup hasValidation className="country">
                            <InputGroup.Text id="inputGroupPrepend">
                              {countryCode}
                            </InputGroup.Text>
                            <FloatingLabel
                              controlId="floatingPhoneInput"
                              label={t("b2b.Signup.phone")}
                            >
                              <Form.Control
                                type="text"
                                placeholder="phone"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                                aria-describedby="inputGroupPrepend"
                                required
                              />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Form.Group className="mb-3 d-flex content-justify-start">
                        <Form.Check
                          required
                          name="terms"
                          onChange={handleChange}
                          isInvalid={!!errors.terms}
                          feedback={errors.terms}
                          feedbackType="invalid"
                          id="validationFormik0"
                        />
                        <span className="ms-2" onClick={handleModalClick}>
                          {" "}
                          {t("b2b.Signup.agree_to_terms_and_conditions")}
                        </span>
                      </Form.Group>
                      <TermsConditions
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      <div className="d-grid gap-2 my-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          {t("b2b.Signup.create_a_free_account")}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p>
                  {t("b2b.Signup.already_have_account")}{" "}
                  <a href={`/${lang}`}>{t("b2b.Signup.sign_in_here")}</a>
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default LogupPage;
