import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { useAxios } from "../../utils/hook/useAxios";

function CheckoutPaymentConfirmation() {
  const [isActive, setActive] = useState(false);
  const axiosInstance = useAxios();
  function handleClick() {
    setActive(!isActive);
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const transactionID = searchParams.get("transactionID");
  const Payment = searchParams.get("payment");
  const TotalAmount = searchParams.get("TotalAmount");
  const paymentDate = searchParams.get("PaymentDate");

  var date = new Date(parseInt(paymentDate) * 1000);
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  var formattedDate = date.toLocaleDateString("en-US", options);

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar
            proposTitle={"Upgrade page"}
            handleClick={handleClick}
          ></NavBar>
          <div className="recent-reviews">
            <h2>Votre abonnement</h2>
            <div className="reviews">
              <div className="container mb-3 subscription-selector">
                <div className="row g-4">
                  <div className="col-lg-8 mx-auto">
                    <h4 className="text-center text-success">
                      Payement Successfull
                    </h4>
                    <div className="container">
                      <div className="row mt-3">
                        <div className="col-md-6 mb-3">
                          <div className="card p-3">
                            <p className="lead">
                              <strong>Order Total</strong>
                            </p>
                            <dl className="row mb-0">
                              <dt className="col-sm-5">Cart Total</dt>
                              <dd className="col-sm-7">{TotalAmount} EUR</dd>
                            </dl>
                            <dl className="row mb-0">
                              <dt className="col-sm-5">recurring</dt>
                              <dd className="col-sm-7">{Payment}</dd>
                            </dl>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="card p-3">
                            <p className="lead">
                              <strong>Payement Summary</strong>
                            </p>
                            <dl className="row mb-0">
                              <dt className="col-sm-5">Transaction ID</dt>
                              <dd className="col-sm-7">{transactionID}</dd>
                            </dl>
                            <dl className="row mb-0">
                              <dt className="col-sm-5">Payment date</dt>
                              <dd className="col-sm-7">{formattedDate}</dd>
                            </dl>
                            <dl className="row mb-0">
                              <dt className="col-sm-5">Amount Paid</dt>
                              <dd className="col-sm-7">{TotalAmount} EUR</dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default CheckoutPaymentConfirmation;
