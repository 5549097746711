import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import widget1 from "../../assets/Widget1.jpg";
import widget2 from "../../assets/Widget2.jpg";
import widget3 from "../../assets/Widget3.jpg";
import widget4 from "../../assets/Widget4.jpg";
import widget5 from "../../assets/Widget5.jpg";
import { ACCESS_TOKEN, MERCHAND_ID } from "../../config";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/owl.carousel.css";

import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import WidgetResumeComponentBlock from "../../components/WidgetResumeComponentBlock";
import WidgetResumeComponentBlockStar from "../../components/WidgetResumeComponentBlockStar";
import WidgetReviewComponent from "../../components/WidgetReviewComponent";
import WidgetReviewComponent4 from "../../components/WidgetReviewComponent4";
import WidgetReviewComponent5 from "../../components/WidgetReviewComponent5";
import { useAxios } from "../../utils/hook/useAxios";

function ShowcasePage() {
  const [isActive, setActive] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);

  const [templateid, setTemplateId] = useState(0);
  const [heightIFrame, setHeightIframe] = useState(0);
  const [merchantData, setMerchantData] = useState({});
  const [reviews, setReviews] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [code, setCode] = useState("");

  const axios = useAxios();

  function handleClick() {
    setActive(!isActive);
  }
  function handleModalClick1() {
    setModalShow(true);
    setHeightIframe(150);
    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/${res.data.website}`)
        .then((res) => {
          setMerchantData(res.data[0]);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick2() {
    setModalShow2(true);
    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/${res.data.website}`)
        .then((res) => {
          setMerchantData(res.data[0]);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick3() {
    setHeightIframe(310);
    setModalShow3(true);
    axios.get("/").then((res) => {
      axios
        .get(`/organic-merchant-review/${res.data.website}`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick4() {
    setHeightIframe(310);

    setModalShow4(true);
    axios.get("/").then((res) => {
      axios
        .get(`/organic-merchant-review/${res.data.website}`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((error) => {});
    });
  }

  function handleModalClick5() {
    setHeightIframe(610);

    setModalShow5(true);
    axios.get("/").then((res) => {
      axios
        .get(`/organic-merchant-review/${res.data.website}`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((error) => {});
    });
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token ? window.localStorage.token : ACCESS_TOKEN
  );
  const [SubscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(lang);
    axios
      .get("/subscriptions/" + MerchantID, AccessToken)

      .then((response) => {
        setSubscriptionData(response.data);
      })

      .catch((error) => console.log(error));
  }, []);

  const getCodeAndCopy = (templateId) => {
    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/${res.data.website}`)
        .then(function (response) {
          setCode(
            `<iframe class="widget-veritatrust" scrolling="no" data-template-id=${templateId} src="https://widget.veritatrust.com/trustboxes/${templateId}?a=veritatrust&templateId=${templateId}&businessunitId=${response.data[0].id}"> </iframe>`
          );
          setTemplateId(templateId);

          switch (templateId) {
            case 1:
              setHeightIframe(100);
              break;
            case 2:
              setHeightIframe(100);
              break;
            case 3:
              setHeightIframe(310);
              break;
            case 4:
              setHeightIframe(310);
              break;
            case 5:
              setHeightIframe(610);
              break;
            default:
          }

          setModalIsOpen(true);
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  };

  const copyToClipboard = (templateId) => {
    // Create a temporary textarea element

    axios.get("/").then((res) => {
      axios
        .get(`/merchant-profile/${res.data.website}`)
        .then(function (response) {
          setCode(
            `<iframe class="widget-veritatrust" onload="resizeIframe(this)" title="Customer reviews powered by VeritaTrust" style="position: relative; width: 100%; border-style: none; display: block; overflow: hidden;" src="https://widget.veritatrust.com/trustboxes/${templateId}?a=veritatrust&templateId=${templateId}&businessunitId=${response.data[0].id}"> </iframe>`
          );

          const textarea = document.createElement("textarea");

          textarea.value = code;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);
          switch (templateId) {
            case 1:
              setHeightIframe(100);
              break;
            case 2:
              setHeightIframe(100);
              break;
            case 3:
              setHeightIframe(310);
              break;
            case 4:
              setHeightIframe(310);
              break;
            case 5:
              setHeightIframe(610);
              break;
            default:
          }

          // You can also provide feedback to the user that the content has been copied
          alert("Content copied to clipboard!");
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  };

  const closeModal = () => {
    // Fermez le modal et réinitialisez le code
    setModalIsOpen(false);
  };
  const customStyles = {
    content: {
      width: "50%", // Ajustez la largeur selon vos besoins
      height: "50%", // Ajustez la hauteur selon vos besoins
      margin: "auto", // Centrez le modal horizontalement
      zIndex: "9999", // Set the z-index to a high value
    },
  };

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    //setHeight(elementRef.current.offsetHeight);
    console.log("Off Height");
  }, []);

  return (
    <>
      <div className="container-fluid" ref={elementRef}>
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>
        <main>
          <NavBar proposTitle={"ShowCase"} handleClick={handleClick}></NavBar>
          <div className="reviews mb-4">
            <p className="lead mb-1">
              {t("b2b.ShowcasePage.showcasing_reviews")}
            </p>
            <ol className="lead mb-0">
              <li>{t("b2b.ShowcasePage.select_veritatrust_widget")}</li>
              <li>{t("b2b.ShowcasePage.copy_paste_generated_code")}</li>
              <li>{t("b2b.ShowcasePage.youre_done")}</li>
            </ol>
          </div>
          <section class="container mb-4">
            <h2 class="text-secondary">{t("b2b.ShowcasePage.freemium")}</h2>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget1} alt="" />
                  <h4 class="text-center mb-3">
                    {t("b2b.ShowcasePage.widget")} 1
                  </h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick1}
                    >
                      {t("b2b.ShowcasePage.show_preview")}
                    </button>
                    <WidgetResumeComponentBlock
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      merchantData={merchantData}
                    />
                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(1)}
                    >
                      {t("b2b.ShowcasePage.get_code")}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget2} alt="" />
                  <h4 class="text-center mb-3">
                    {t("b2b.ShowcasePage.widget")} 2
                  </h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick2}
                    >
                      {t("b2b.ShowcasePage.show_preview")}
                    </button>
                    <WidgetResumeComponentBlockStar
                      show={modalShow2}
                      onHide={() => setModalShow2(false)}
                      merchant={merchantData}
                    />
                    <button
                      class="btn btn-success text-white getcode"
                      type="button"
                      onClick={() => getCodeAndCopy(2)}
                    >
                      {t("b2b.ShowcasePage.get_code")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="container mb-4">
            <h2 class="text-primary">{t("b2b.ShowcasePage.starter")}</h2>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget3} alt="" />
                  <h4 class="text-center mb-3">
                    {t("b2b.ShowcasePage.widget")} 3
                  </h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick3}
                    >
                      {t("b2b.ShowcasePage.show_preview")}
                    </button>

                    <WidgetReviewComponent
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                      reviews={reviews}
                      merchantData={merchantData}
                    />

                    {SubscriptionData?.subscriptionPlan === "1" ||
                    SubscriptionData?.subscriptionPlan === "2" ||
                    SubscriptionData?.subscriptionPlan === "3" ? (
                      <button
                        class="btn btn-success text-white getcode"
                        type="button"
                        onClick={() => getCodeAndCopy(3)}
                      >
                        {t("b2b.ShowcasePage.get_code")}
                      </button>
                    ) : (
                      <Link
                        class="btn btn-outline-success text-success"
                        to={"/upgrade"}
                      >
                        {t("b2b.ShowcasePage.upgrade")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget4} alt="" />
                  <h4 class="text-center mb-3">
                    {t("b2b.ShowcasePage.widget")} 4
                  </h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick4}
                    >
                      {t("b2b.ShowcasePage.show_preview")}
                    </button>
                    <WidgetReviewComponent4
                      show={modalShow4}
                      onHide={() => setModalShow4(false)}
                      reviews={reviews}
                      merchantData={merchantData}
                    />
                    {SubscriptionData?.subscriptionPlan === "1" ||
                    SubscriptionData?.subscriptionPlan === "2" ||
                    SubscriptionData?.subscriptionPlan === "3" ? (
                      <button
                        class="btn btn-success text-white getcode"
                        type="button"
                        onClick={() => getCodeAndCopy(4)}
                      >
                        {t("b2b.ShowcasePage.get_code")}
                      </button>
                    ) : (
                      <Link
                        class="btn btn-outline-success text-success"
                        to={"/upgrade"}
                      >
                        {t("b2b.ShowcasePage.upgrade")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="container mb-4">
            <h2 class="text-success">{t("b2b.ShowcasePage.premium")}</h2>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="reviews position-relative">
                  <img class="rounded-4 border mb-3" src={widget5} alt="" />
                  <h4 class="text-center mb-3">
                    {t("b2b.ShowcasePage.widget")} 5
                  </h4>
                  <div class="d-flex justify-content-around">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick5}
                    >
                      {t("b2b.ShowcasePage.show_preview")}
                    </button>
                    <WidgetReviewComponent5
                      show={modalShow5}
                      onHide={() => setModalShow5(false)}
                      reviews={reviews}
                      merchantData={merchantData}
                    />
                    {SubscriptionData?.subscriptionPlan === "2" ||
                    SubscriptionData?.subscriptionPlan === "3" ? (
                      <button
                        class="btn btn-success text-white getcode"
                        type="button"
                        onClick={() => getCodeAndCopy(5)}
                      >
                        {t("b2b.ShowcasePage.get_code")}
                      </button>
                    ) : (
                      <Link
                        class="btn btn-outline-success text-success"
                        to={"/upgrade"}
                      >
                        {t("b2b.ShowcasePage.upgrade")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Get code"
          style={customStyles}
        >
          <div>
            <h2>{t("b2b.ShowcasePage.your_code")}</h2>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control text-truncate"
                value={code}
                aria-describedby="button-copy"
                disabled
                readonly
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-copy"
                onClick={() => copyToClipboard(templateid)}
              >
                {t("b2b.ShowcasePage.copy")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ShowcasePage;
