import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../styles/owl.carousel.css";

function EmailPreview(props) {
  const { firstname, lastname, domaine_name, invitation_url } = props;

  const { i18n } = useTranslation();
  const { lang } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  // Extract merchant from props
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>{" "}
        <Modal.Body>
          {" "}
          <div
            style={{
              width: "100%",
              margin: "0",
              padding: "0 !important",
              msoLineHeightRule: "exactly",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{ maxWidth: "700px", margin: "0 auto" }}
                className="email-container"
              >
                {/* BEGIN BODY */}
                <table
                  role="presentation"
                  cellSpacing="0"
                  cellPadding="0"
                  width="100%"
                  style={{ margin: "auto" }}
                >
                  <tr>
                    <td
                      valign="top"
                      className="bg_white"
                      style={{ padding: ".5em 2.5em 1em 2.5em" }}
                    >
                      <table
                        role="presentation"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        collSpan="2"
                      >
                        <tr>
                          <td className="logo" style={{ textAlign: "left" }}>
                            <img
                              className="veritatrust"
                              src="http://dev.veritatrust.com/assets/img/logo-veritatrust-w.png"
                              alt="veritatrust"
                              width="300"
                              height="58"
                            />
                          </td>
                          <td
                            className="logo"
                            style={{ textAlign: "right" }}
                          ></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table
                  role="presentation"
                  cellSpacing="0"
                  cellPadding="0"
                  width="100%"
                  style={{ margin: "auto" }}
                >
                  <tr>
                    <td
                      className="bg_white"
                      style={{
                        textAlign: "left",
                        padding: "20px 20px 0 20px",
                      }}
                    >
                      <p>
                        Dear {firstname} {lastname},<br /> You are receiving
                        this email because you recently purchased on{" "}
                        {domaine_name}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="bg_white"
                      style={{
                        textAlign: "left",
                        padding: "0px 20px 0 20px",
                      }}
                    >
                      <button
                        className="btn btn-success"
                        style={{ marginBottom: "0" }}
                        disabled="true"
                      >
                        Share your experience
                      </button>
                    </td>
                  </tr>
                </table>
                <table
                  role="presentation"
                  cellSpacing="0"
                  cellPadding="0"
                  width="100%"
                  style={{ margin: "auto", fontSize: "15px" }}
                >
                  <tr>
                    <td
                      className="bg_white"
                      style={{ textAlign: "left", padding: "20px" }}
                    >
                      <p>
                        All reviews, both positive and negative, will be
                        published instantly.
                      </p>
                      <p>Thank you in advance for your help.</p>
                      <p>
                        Best regards,
                        <br /> {domaine_name} team
                      </p>
                    </td>
                  </tr>
                </table>
                <hr
                  style={{
                    marginBlockStart: "0em",
                    marginBlockEnd: "0em",
                    color: "#ddd",
                  }}
                />
                <table
                  role="presentation"
                  cellSpacing="0"
                  cellPadding="0"
                  width="100%"
                  style={{ margin: "auto", fontSize: "15px" }}
                >
                  <tr>
                    <td
                      className="bg_white"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      <p>
                        This email is sent automatically, it is possible that
                        you have not yet received your product or service. If
                        this is the case, please wait until you receive your
                        order to give your opinion.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="bg_white"
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      <p>
                        You no longer wish to receive these e-mails? <br />
                        You can{" "}
                        <a href="#" style={{ color: "rgba(0, 45, 107)" }}>
                          unsubscribe here.
                        </a>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("b2b.CollectPage.close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmailPreview;
