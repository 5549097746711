import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import {
  ACCESS_TOKEN,
  BASE_URL,
  MERCHAND_ID,
  URL_LINK_PAYMENT_CONFIRMATIOON,
} from "../../config";
import { useAxios } from "../../utils/hook/useAxios";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const axios = useAxios();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const packSubscription = window.localStorage.getItem("PackUpgrade");
  const [SubscriptionPlan, setSubscriptionPlan] = useState(0);
  const [SubscriptionData, setSubscriptionData] = useState(null);
  const [MerchantID, setMerchantID] = useState(
    window.localStorage.profileData
      ? JSON.parse(window.localStorage.profileData).id
      : MERCHAND_ID
  );

  const [AccessToken, setAccessToken] = useState(
    window.localStorage.token
      ? window.localStorage.token || getCookie("jwtToken")
      : ACCESS_TOKEN
  );

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    axios
      .get("/subscriptions/" + MerchantID, AccessToken)

      .then((response) => {
        setSubscriptionData(response.data);
      })

      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });

    if (packSubscription === "STARTER") {
      setSubscriptionPlan("1");
    } else if (packSubscription === "PREMIUM") {
      setSubscriptionPlan("2");
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${BASE_URL}/checkout`,
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else {
      console.log("Paiement confirmé avec succès:", paymentIntent);
      if (paymentIntent.status === "succeeded") {
        // Changement de subscriptions
        console.log("Upgrade Succeeded", packSubscription);
        if (packSubscription === "starter") {
          let monthlyReview = 500;
          let EmailInviteCustom = true;
          let CustomSupport = "1";
          let InviteFromPastCustomers = "1";
          let dashboard = "0";
          let QandAProductPage = false;
          let LocalReviews = false;
          let MultiUserDomain = "0";
          let NetPromoterScore = false;
          let GoogleShoppingSEOReviewWidget = true;
          let expirationDate = new Date(
            CaclulateExpirationDate().toDateString()
          );

          axios
            .post(
              "/subscriptions",
              {
                MonthlyReview: monthlyReview,
                EmailInviteCustom: EmailInviteCustom,
                CustomerSupport: CustomSupport,
                InviteFromPastCustomers: InviteFromPastCustomers,
                dashboard: dashboard,
                QandAonProductPage: QandAProductPage,
                LocalReviews: LocalReviews,
                MultiUserDomain: MultiUserDomain,
                NetPromoterScore: NetPromoterScore,
                GoogleShoppingSEOReviewWidget: GoogleShoppingSEOReviewWidget,
                expirationDate: expirationDate,
                status: "1",
                merchandId: MerchantID,
                subscriptionPlan: "1",
                apiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ989JXDGGHGJHU",
                StripeSubscriptionId: paymentIntent.id,
              },
              AccessToken
            )
            .then((response) => {
              window.location.href =
                URL_LINK_PAYMENT_CONFIRMATIOON +
                window.location.search +
                "&transactionID=" +
                paymentIntent.id +
                "&PaymentDate=" +
                paymentIntent.created;
            })
            .catch((error) => console.log(error));
        } else if (packSubscription === "PREMIUM") {
          let monthlyReview = 1000;
          let EmailInviteCustom = true;
          let CustomSupport = "2";
          let InviteFromPastCustomers = "2";
          let dashboard = "1";
          let QandAProductPage = true;
          let LocalReviews = true;
          let MultiUserDomain = "1";
          let NetPromoterScore = true;
          let GoogleShoppingSEOReviewWidget = true;
          let expirationDate = new Date(
            CaclulateExpirationDate().toDateString()
          );

          axios
            .post(
              "/subscriptions",
              {
                MonthlyReview: monthlyReview,
                EmailInviteCustom: EmailInviteCustom,
                CustomerSupport: CustomSupport,
                InviteFromPastCustomers: InviteFromPastCustomers,
                dashboard: dashboard,
                QandAonProductPage: QandAProductPage,
                LocalReviews: LocalReviews,
                MultiUserDomain: MultiUserDomain,
                NetPromoterScore: NetPromoterScore,
                GoogleShoppingSEOReviewWidget: GoogleShoppingSEOReviewWidget,
                expirationDate: expirationDate,
                status: "1",
                merchandId: MerchantID,
                subscriptionPlan: "2",
                apiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ989JXDGGHGJHU",
                StripeSubscriptionId: paymentIntent.id,
              },
              AccessToken
            )
            .then((response) => {
              window.location.href =
                URL_LINK_PAYMENT_CONFIRMATIOON +
                window.location.search +
                "&transactionID=" +
                paymentIntent.id +
                "&PaymentDate=" +
                paymentIntent.created;
            })
            .catch((error) => console.log(error));
        }
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  function CaclulateExpirationDate() {
    const urlParams = new URLSearchParams(window.location.search);

    const payment = urlParams.get("payment");

    if (SubscriptionData) {
      if (SubscriptionData.subscriptionPlan !== "0") {
        if (payment === "annual") {
          const nextExpirationDate = SubscriptionData
            ? new Date(SubscriptionData.expirationDate)
            : new Date();

          nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 12);
          return nextExpirationDate;
        } else {
          const nextExpirationDate = SubscriptionData
            ? new Date(SubscriptionData.expirationDate)
            : new Date();
          nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 1);
          return nextExpirationDate;
        }
      } else {
        if (payment === "annual") {
          const nextExpirationDate = new Date();

          nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 12);
          return nextExpirationDate;
        } else {
          const nextExpirationDate = new Date();

          nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 1);
          return nextExpirationDate;
        }
      }
    } else {
      if (payment === "annual") {
        const nextExpirationDate = new Date();

        nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 12);
        return nextExpirationDate;
      } else {
        const nextExpirationDate = new Date();

        nextExpirationDate.setMonth(nextExpirationDate.getMonth() + 1);
        return nextExpirationDate;
      }
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="btn btn-success text-white mt-3 col-md-3 w-100"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Confirm Payment"
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
}
